import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { WaitScreen } from '../components/ui/WaitScreen'
import { __authValidToken } from '../redux/actions/authActions'

import {
    PrivateRoutes
} from './PrivateRoutes'
import { PublicRoutes } from './PublicRoutes'

export const RouterApp = () => {

    const { logged, checked } = useSelector(state => state.auth)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch( __authValidToken() )
    }, [])
  
    if(checked) {
        return <WaitScreen />
    }
  

  return (
    <BrowserRouter>
          <div className="App">
            {
              logged
                ? <PrivateRoutes/>
                : <PublicRoutes/>
            }
          </div>
    </BrowserRouter>

  )
}
