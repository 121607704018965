import React, {useEffect, useState} from "react";
import './ruleta.css';

//importacion de componentes
import WaitingGame from "./game";

function Ruleta(){

  const [nombres, setNombres] = useState("");
  const [apellidos, setapellidos] = useState("");


  //Ejecutar automaticamente la funcion getUser
  useEffect(() => {
    getUser();
  }, [])

  //obtener informacion del usuario
  function getUser() {
    
    fetch(`${ process.env.REACT_APP_API_URL  }/entereza/usuarios_list?code=`+sessionStorage.getItem("userEmail"), {
        method: 'GET',
        headers: {
          'Authorization': "Bearer "+sessionStorage.getItem("currentUser"),
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
    }).then((result) => {
      result.json().then((resp) => {
        setNombres(resp.lista_usuarios[0].usuarioBean.nombres)
        setapellidos(resp.lista_usuarios[0].usuarioBean.apellidos)
      })
    })
  }

    return(
      <div className="bodyLogoutPage mb-5">
        <h2 className="mb-3 text-center">¡Bienvenido {nombres} {apellidos}!</h2>

        <WaitingGame />
        
        <div className="RulesGame">
          <div className="ContainerRules">
            <div className="TituloRules text-center">
              <h3 className="text-light py-1 fw-bold">Reglas del Juego</h3>
            </div>
            
            <div className="Rules">
                <p className="RulesText">
                  <bolder>1°</bolder> Tienes 5 intentos por día.<br/>
                  <br/>
                  <bolder>2°</bolder> La categoría Entereza no te sumará puntos, ni te quitará vidas.<br/>
                  <br/>
                  
                  <bolder>3°</bolder> Por cada 1000 puntos acumulados recibirás cupones de descuento en nuestras empresas afiliadas.<br/><br/>

                  <bolder>4°</bolder> Disfruta del juego y recomienda a todos tus amigos a que se creen una cuenta.
                </p><br/>

                <h5 className="FraseRules fw-bold text-center">
                  “Somos el equilibrio entre <b>SOLO SE VIVE UNA VEZ</b> y <b>QUIERO AHORRAR</b>”
                </h5>
              </div>          
          </div>
        </div>
      </div>
    );
}

export default Ruleta;