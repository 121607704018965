import React from 'react'
import Skeleton from '@mui/material/Skeleton';

export const EmpresasAfiliadasCardSkeleton = () => {
  return (
    <div
            className='business-item position-relative col-3 col-sm-3 col-md-3 py-1 col-lg-2 d-flex flex-column justify-content-center align-items-center m-2 my-3 px-1 cursor-pointer'
            style={{
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                borderRadius: '13px',
                width: 150
            }}
        >
            <div
                className='col-12 px-0 businness-item__bg'
                style={{
                    borderRadius: '13px'
                }}
            >
                <Skeleton 
                    variant="rectangular"
                    className='businness-item__bg-img img-fluid'
                    width="100%"
                    height={'7rem'}
                />
             
                <Skeleton 
                    variant="text"
                    className="col-10 mx-auto py-1"
                />
            </div>
    </div>
  )
}
