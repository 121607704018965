import './navbar.css';

import React from 'react';

import logo from '../../assets/img/logo-blanco.png';

import { Link, useNavigate } from "react-router-dom";

import { useSelector } from 'react-redux';
import { _authLogout, __authLogout } from '../../redux/actions/authActions';

// mui components 
import Avatar from '@mui/material/Avatar';
import { stringAvatar } from '../../helpers/stringColors';

import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import WidgetsIcon from '@mui/icons-material/Widgets';
import SpeedDialAction from '@mui/material/SpeedDialAction';

//import icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faWallet, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { style } from '@mui/system';


const PrivateNavBar = () => {

    const { user } = useSelector(state => state.auth)

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [a, seta] = React.useState(true);



    let navigate = useNavigate();

    function Empresas() {
        setOpen(false);
        navigate("/empresas-afiliadas")
    }
    function Wallet() {
        setOpen(false);
        navigate("/ahorros")
    }
    function User() {
        setOpen(false);
        navigate("/perfil")
    }

    return (
        <div className='NavbarPrivate'>
            <nav className="navbar navbar-expand-lg navbar-dark  fixed-top" id="navbarPrivate">
                <div className="container-fluid">
                    <Link to='/ahorros'>
                        <img src={logo} alt="Entereza" width="53" height="50" className="Logo-Navbar" />
                    </Link>
                    <Link id="EnterezaNavBar" className="navbar-brand mb-0 h1 fs-2 text-light" to="/ahorros">ENTEREZA</Link>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link text-light mx-2 nav_div-div-ul-li-a" aria-current="page" to="/ahorros">
                                    Billetera
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link className="nav-link text-light mx-2 nav_div-div-ul-li-a" aria-current="page" to="/empresas-afiliadas">
                                    Empresas Afiliadas
                                </Link>
                            </li>

                            {/*<li className="nav-item">
                                <Link className="nav-link text-light mx-2 nav_div-div-ul-li-a" aria-current="page" to="/proximamente">
                                    Próximamente
                                </Link>
                            </li>*/}

                            {/*<li className="nav-item">
                                <Link className="nav-link text-light mx-2 nav_div-div-ul-li-a" aria-current="page" to="/ruleta">
                                    Ruleta
                                </Link>
                            </li>*/}

                            {/*<li className="nav-item">
                                <Link className="nav-link text-light mx-2 nav_div-div-ul-li-a" aria-current="page" to="/blog">
                                    Blog
                                </Link>
                            </li>*/}

                            {/*<li className="nav-item">
                                <Link className="nav-link text-light mx-2 nav_div-div-ul-li-a" aria-current="page" to="/soporte">
                                    Soporte
                                </Link>
                            </li>*/}
                        </ul>
                    </div>
                    <div>
                        <div className="btn-group boton-desplegablePrivateNavbar">
                            <Link to="/perfil" style={{ textDecoration: "none" }}>
                                <button
                                    id="PerfilButton"
                                    className="btn btn-dark botonPerfilUsuarioPrivateNavbar mx-4 d-flex flex-row flex-nowrap align-items-center justify-content-center"
                                    type="button"
                                    aria-expanded="false"
                                >
                                    {
                                        !!user && !!user.img
                                            ? (
                                                <Avatar alt={user.info.nombres} src={user.img} />
                                            ) : (
                                                <Avatar
                                                    sx={{ xs: { width: 24, height: 24 }, sm: { width: 36, height: 36 } }}
                                                    {...stringAvatar(user.info.nombres)}
                                                />
                                            )
                                    }
                                </button>
                            </Link>
                        </div>
                    </div>

                    <div id='ButtonMenu' className='fixed-bottom'>
                        <Backdrop open={open} />
                        <SpeedDial
                            ariaLabel="SpeedDial tooltip example"
                            sx={{ position: 'absolute', bottom: 16, right: 14 }}
                            icon={<WidgetsIcon sx={{fontSize: 25}}/>}
                            onClose={handleClose}
                            onOpen={handleOpen}
                            open={open}
                            FabProps={{
                                sx: {
                                  bgcolor: '#5457a5',
                                    color: 'white',
                                    '&:hover': {
                                        bgcolor: '#5457a5',
                                      },
                                      width: 60,
                                      height: 60
                                }
                              }}
                        >
                            <SpeedDialAction
                                icon={<FontAwesomeIcon
                                    icon={faWallet}
                                    style={{
                                        fontSize: 18,
                                    }} />}
                                tooltipTitle={"Billetera"}
                                tooltipOpen
                                onClick={Wallet}
                                FabProps={{
                                    sx: {
                                          width: 45,
                                          height: 45
                                    }
                                  }}
                            />
                            <SpeedDialAction
                                icon={<FontAwesomeIcon
                                    icon={faBuilding}
                                    style={{
                                        fontSize: 18,
                                    }}
                                />}
                                tooltipTitle={"Empresas"}
                                tooltipOpen
                                onClick={Empresas}
                                FabProps={{
                                    sx: {
                                          width: 45,
                                          height: 45
                                    }
                                  }}
                            />
                            <SpeedDialAction
                                icon={<FontAwesomeIcon
                                    icon={faUser}
                                    style={{
                                        fontSize: 18,
                                    }} />}
                                tooltipTitle={"Perfil"}
                                tooltipOpen
                                onClick={User}
                                FabProps={{
                                    sx: {
                                          width: 45,
                                          height: 45
                                    }
                                  }}
                            />
                        </SpeedDial>
                    </div>
                </div>
            </nav>
        </div>
    );
}


export default PrivateNavBar;