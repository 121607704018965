import { useEffect } from "react";
import './landingPage.css';

//importacion de imagenes ->->->
//imagenes seccion registro usuarios
import Celulares from '../../assets/img/dosCelulares.png';
import AppStore from '../../assets/img/app-store.svg';
import PlayStore from '../../assets/img/play-store.svg';

//imagenes seccion informacion servicio entereza
import FirstImage from '../../assets/img/acercade-1.png';
import SecondImage from '../../assets/img/acercade-2.png';
import ThirdImage from '../../assets/img/acercade-3.png';
import Cell from '../../assets/img/cell-icon.png';
import Ahorro from '../../assets/img/ahorro-icon.png';
import Money from '../../assets/img/money-icon.png';

//imagenes seccion empresas
import dots from "../../assets/img/dots.png";
import greekgyros from "../../assets/img/greekgyros.png";
import healthpanas from "../../assets/img/healthpanas.png";
import helen from "../../assets/img/helen.png";
import bricks from "../../assets/img/bricks.png";
import pasta from "../../assets/img/pasta.png";

//imagenes seccion educacion financiera
import podcast from '../../assets/img/cel-podcast.png'
import echado from '../../assets/img/finslider-echado.png'
import libro from '../../assets/img/libro-edfin.png'

//imagenes seccion info sobre nosostros
import Logo from '../../assets/img/LogoBoxUs.png';
import gaston from '../../assets/img/gaston.png';
import puggi from '../../assets/img/puggi.png';
import tamflikk from '../../assets/img/tamflikk.png';
import intisau from '../../assets/img/intisau.png';
import rochex from '../../assets/img/Rochex.png';

// importacion de librerías para efectos
import Aos from 'aos';
import "aos/dist/aos.css";

const LandingPage = () => {
    useEffect(() => {
        Aos.init({
            duration: 2000,
            once: true
        });
    }, []);

    return (
        <div className="bodyCompleteLandingPage">
            {/* Seccion Descarga la App */}
            <div className="hero">
                <section className="hero__main cont">
                    <div className="hero__texts">
                        <h1 className="hero__title text-white">¿Te imaginas ahorrar mientras gastas?</h1>
                        <p className="hero__subtitle text-white">
                            Paga el mismo precio de siempre, obtén descuentos en nuestras empresas afiliadas y conviértelos en ahorros para tu futuro
                        </p>
                        <a href="https://apps.apple.com/bo/app/entereza/id6443708697?l=en" target={"_blank"}><input type="image" src={AppStore} alt="" width="160px" height="42px"/></a>
                        <a href="https://play.google.com/store/apps/details?id=com.entereza.client" target={"_blank"}><input type="image" src={PlayStore} alt="" width="160px" height="42px"/></a>
                    </div>
                    <figure className="hero__picture">
                        <img src={Celulares} alt="dos celulares" class="hero__img"/>
                    </figure>
                </section>
            </div>

            {/*Seccion de informacion sobre el servicio de entereza*/}
            <div>
                <div id="carouselExampleInterval" data-aos="fade-up" className="carousel carousel-dark slide" data-bs-ride="carousel">
                    <br/>
                    <h2 id="titulo">¿Cómo funciona Entereza?</h2>
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIterval" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div className="carousel-inner" id="contenedor">
                        <div className="carousel-item active" data-bs-interval="8000">
                            <div className="mb-3" >
                                <div className="row g-0">
                                    <div className="col-md-4" id="img-size">
                                        <img src={FirstImage} className="img-fluid rounded-start" alt="como funciona entereza, paso 1" />
                                    </div>
                                    <div class="col-md-8" id="text">
                                        <div class="card-body">
                                            <h5 class="card-title">Paso 1</h5>
                                            <p class="card-text">
                                                Acordamos con empresas un porcentaje de descuento <i>(el que la empresa desee)</i>,
                                                para que se destine a tu cuenta de ahorro Entereza.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="8000">
                            <div className="mb-3">
                                <div className="row g-0">
                                    <div className="col-md-4" id="img-size">
                                        <img src={SecondImage} className="img-fluid rounded-start" alt="como funciona entereza, paso 2" />
                                    </div>
                                    <div class="col-md-8" id="text">
                                        <div class="card-body">
                                            <h5 class="card-title">Paso 2</h5>
                                            <p class="card-text">
                                                Acércate a una de nuestras empresas afiliadas,
                                                identifícate como usuario de Entereza y paga el mismo
                                                precio de siempre.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="8000">
                            <div className="mb-3">
                                <div className="row g-0">
                                    <div className="col-md-4" id="img-size">
                                        <img src={ThirdImage} className="img-fluid rounded-start" alt="como funciona entereza, paso 3" />
                                    </div>
                                    <div class="col-md-8" id="text">
                                        <div class="card-body">
                                            <h5 class="card-title">Paso 3</h5>
                                            <p class="card-text">
                                                Ingresa a tu cuenta Entereza, verifica tus ahorros y retíralos cuando desees.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a type="button" href="/registro/cliente" role="button"
                        Style="width: max-content" class="btn btn-primary btn-lg d-grid mx-auto botonAuthLP-to-resgistroUser">
                        ¡Quiero una cuenta!
                    </a>
                    <br /><br />
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>

                <div>
                    <div data-aos="fade">
                        <div className="card-group" id="cards">
                            <div className="card" id="card">
                                <img src={Cell} className="card-img-top" id="icons1" alt="Digitalizacion Entereza" />
                                <div className="card-body">
                                    <br />
                                    <h4 class="card-title">100% Digital</h4><br />
                                    <p class="card-text">Nos preocupamos por tu dinero y tu futuro. Podrás ver todos tus ahorros en tu cuenta de Entereza mediante cualquier dispositivo.</p>
                                </div>
                            </div>
                            <div className="card" id="card">
                                <img src={Ahorro} className="card-img-top" id="icons" alt="Ahorro Entereza" />
                                <div className="card-body">
                                    <br />
                                    <h4 class="card-title">100% Fácil</h4><br />
                                    <p class="card-text">¡Sigue con tu Vida! Solo te creas una cuenta en nuestro sistema y nosotros nos encargaremos del resto.
                                    </p>
                                </div>
                            </div>
                            <div className="card" id="card">
                                <img src={Money} className="card-img-top" id="icons" alt="Dinero Entereza" />
                                <div className="card-body">
                                    <br />
                                    <h4 class="card-title">100% Automático</h4><br />
                                    <p class="card-text">Obtén reembolsos a tu cuenta de ahorro Entereza, a la hora de comprar cualquier producto o servicio, pagando el mismo precio que antes. </p>
                                </div>
                            </div>
                        </div>
                        <div className="d-grid gap-2 col-6" id="caja-boton">
                            <a className="btn btn-lg" href="/acerca-de/producto" id="boton" role="button">Más Sobre Entereza</a>
                        </div>
                    </div>
                </div>
            </div>


            {/*Seccion informacion sobre y para la empresa*/}
            <div id="body-ea-lp" className="bodyEmpresasAfiliadasLP ">

                <h2 className="text-center mb-4" data-aos="fade-up">Con la confianza de tus marcas favoritas</h2>
                <div className="row empresasAfiliadas_filaLP" data-aos="fade-up">
                    <div className="col" id="ImgEmpresasCol">
                        <img src={bricks} className="imgs-empresasAfiliadasLP" alt="bricks entereza" />
                    </div>
                    <div className="col" id="ImgEmpresasCol">
                        <img src={pasta} className="imgs-empresasAfiliadasLP" alt="pasta entereza" />
                    </div>
                    <div className="col" id="ImgEmpresasCol">
                        <img src={dots} className="imgs-empresasAfiliadasLP" alt="dots entereza" />
                    </div>
                    <div className="col" id="ImgEmpresasCol">
                        <img src={greekgyros} className="imgs-empresasAfiliadasLP" alt="greek gyros entereza" />
                    </div>
                    <div className="col" id="ImgEmpresasCol">
                        <img src={healthpanas} className="imgs-empresasAfiliadasLP" alt="healthpanas entereza" />
                    </div>
                    <div className="col" id="ImgEmpresasCol">
                        <img src={helen} className="imgs-empresasAfiliadasLP" alt="helen entereza" />
                    </div>
                </div>

                <div className="row align-items-stretch mx-auto mt-5" style={{width: '90%'}} >
                    <div className="col-md-6 border border-dark mb-3" data-aos="fade-right" id="caja-ea-usuario">
                        <div className="h-100 p-5 text-white">
                            <h2 className="tituloCuadroMoradoLP">Beneficia a tu empresa</h2>
                            <p className="greytextEALP">Mira lo que tu negocio podrá ganar y únete a las empresas que ya tomaron una gran decisión para retener a sus clientes, multiplica tus ventas y crece junto a Entereza.</p>
                            <a href="/acerca-de/empresas"><button className="btn btn-outline-light" type="button" data-aos="fade-up" id="boton-morado-gray">Me interesa</button></a>
                        </div>
                    </div>
                    <div class="col-md-6 border border-dark mb-3" data-aos="fade-right" id="caja-ea-empresa">
                        <div class="h-100 p-5">
                            <h2>Conoce las empresas</h2>
                            <p className="purpletextEALP">Descubre a las empresas que confían en nosotros, quienes aportarán al futuro de cada uno de los bolivianos.</p>
                            <a href="/empresas-afiliadas"><button class="btn btn-outline-dark" type="button" data-aos="fade-up" id="boton-morado-oscuro">Ver empresas</button></a>
                        </div>
                    </div>
                </div>
            </div>


            {/*Seccion de informacion sobre nosotros*/}
            <div id="allaboutteam">
                <div className="TeamLandingP">
                    <h2 className="text-center my-4" data-aos="fade-up">Un equipo, muchos talentos</h2>
                    <div className="row align-items-center" id="boxsLP">

                        <div className="acol-md-6 border border-dark" data-aos="zoom-in-up" data-aos-duration="1000" id="boxsLP-person">
                            <div className="boxsLP-name text-center">
                                <img id="boxsLP-logo" src={Logo} alt="logo blanco entereza" />
                            </div>
                            <div className="capa">
                                <p>
                                    Un equipo con una clara visión, permitirá ponernos
                                    en la piel de nuestros clientes y lograr la
                                    inclusión financiera en base a un estándar de oro.
                                </p>
                            </div>
                            <div id="containerTextsLP" className="text-cnp-3">
                                <img id="boxsLP-img" src={gaston} alt="Gaston Rocha Torrez Entereza fintech CEO" />
                                <h3 className="boxsLP-tittle">Gastón Rocha Torrez</h3>
                                <h5 className="boxsLP-text">CEO & Co-Founder of Entereza Fintech</h5>
                                <p className="boxsLP-empresa">Entereza Fintech</p>
                            </div>
                        </div>

                        <div className="acol-md-6 border border-dark" data-aos="zoom-in-up" data-aos-duration="1000" id="boxsLP-person">
                            <div className="boxsLP-name text-center">
                                <img id="boxsLP-logo" src={Logo} alt="logo blanco entereza" />
                            </div>
                            <div className="capa">
                                <p>Me fascina Entereza ya que además de ser un gran reto,
                                    pienso que las Fintech revolucionaran la banca en Bolivia
                                    donde nuestro mayor propósito es lograr la inclusión financiera.
                                </p>
                            </div>
                            <div id="containerTextsLP" className="text-cnp-3">
                                <img id="boxsLP-img" src={puggi} alt="Adrian Romero Camacho Entereza Fintech COO" />
                                <h3 className="boxsUs-tittle">Adrián Romero Camacho</h3>
                                <h5 className="boxsUs-text">COO & Co-Founder of Entereza Fintech</h5>
                                <p className="boxsUs-empresa">Entereza Fintech</p>
                            </div>
                        </div>

                        <div className="acol-md-6 border border-dark" data-aos="zoom-in-up" data-aos-duration="1000" id="boxsLP-person">
                            <div className="boxsLP-name text-center">
                                <img id="boxsLP-logo" src={Logo} alt="logo blanco entereza" />
                            </div>
                            <div className="capa">
                                <p>La tecnología y las buenas ideas tienen que ir siempre
                                    de la mano para ayudar a las personas, por eso decidí
                                    formar parte de Entereza.
                                </p>
                            </div>
                            <div id="containerTextsLP" className="text-cnp-3">
                                <img id="boxsLP-img" src={tamflikk} alt="Alejandro Tamayo Hornsby Entereza Fintech CTO" />
                                <h3 className="boxsUs-tittle">Alejandro Tamayo Hornsby</h3>
                                <h5 className="boxsUs-text">CTO & Co-Founder of Entereza Fintech</h5>
                                <p className="boxsUs-empresa">Entereza Fintech</p>
                            </div>
                        </div>

                        <div className="acol-md-6 border border-dark" data-aos="zoom-in-up" data-aos-duration="1000" id="boxsLP-person">
                            <div className="boxsLP-name text-center">
                                <img id="boxsLP-logo" src={Logo} alt="logo blanco entereza" />
                            </div>
                            <div className="capa">
                                <p>Entereza representa una puerta hacia una revolución con respecto a la experiencia
                                    del usuario en el ámbito financiero, rompiendo esquemas tradicionales, innovando para
                                    generar nuevas soluciones a problemas existentes.
                                </p>
                            </div>
                            <div id="containerTextsLP" className="text-cnp-3">
                                <img id="boxsLP-img" src={rochex} alt="Diego Rocha Orellana Entereza Fintech CIO" />
                                <h3 className="boxsUs-tittle">Diego Rocha Orellana</h3>
                                <h5 className="boxsUs-text">CIO & Co-Founder of Entereza Fintech</h5>
                                <p className="boxsUs-empresa">Entereza Fintech</p>
                            </div>
                        </div>

                        <div className="acol-md-6 border border-dark" data-aos="zoom-in-up" data-aos-duration="1000" id="boxsLP-person">
                            <div className="boxsLP-name text-center">
                                <img id="boxsLP-logo" src={Logo} alt="logo blanco entereza" />
                            </div>
                            <div className="capa">
                                <p>La tecnología avanza a pasos agigantados, así que junto a Entereza
                                    nos uniremos a esta, para crecer como una banca diferente.
                                </p>
                            </div>
                            <div id="containerTextsLP" className="text-cnp-3">
                                <img id="boxsLP-img" src={intisau} alr="Inti Rojas Saldias Entereza Fintech Dep. CTO" />
                                <h3 className="boxsUs-tittle">Inti Rojas Saldias</h3>
                                <h5 className="boxsUs-text">Deputy CTO & Co-Founder of Entereza Fintech</h5>
                                <p className="boxsUs-empresa">Entereza Fintech</p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="d-grid gap-2 col-6 mx-auto" id="teamboton" data-aos="fade-down" data-aos-duration="1000">
                    <a className="btn btn-custom" href="/acerca-de/nosotros" id="boton" role="button">Más Sobre Nosotros</a>
                </div>
            </div>

        </div>
    );
}

export default LandingPage;