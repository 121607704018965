import react, { useEffect } from "react";

//importacion de images

import explicar from '../../assets/img/explicar.png';
import slider1 from '../../assets/img/slider1.png';
import slider2 from '../../assets/img/slider2.png';
import microcredito from '../../assets/img/microcredito.png';
import ahorrogrupal from '../../assets/img/ahorrogrupal.png';
import ahorrovoluntario from '../../assets/img/ahorrovoluntario.png';
import inversiones from '../../assets/img/inversiones.png';
import "./acerca-entereza.css";

import Aos from 'aos';
import "aos/dist/aos.css";

    const AcercaEntereza = () => {
        useEffect(() => {
            Aos.init({ 
                duration: 1200,
                once: true });
        }, []);
    return (
        <div className="padding">
            <div className="first__ac-entereza">
            <h2 className="titulo-entereza py-4" data-aos="fade-down">Ahorro Involuntario</h2>
            
                <div className="row align-items-stretch" id="caja-entereza">
                    <div className="col-md-6" id="caja-entereza-usuario">
                        <div className="text-white" data-aos="fade-right" >
                            <h2 className="tituloCuadro1-entereza">¿En que <importante>consiste</importante>?</h2>
                            <p className="blacktext-entereza lead">
                                1º Aproxímate a un negocio afiliado.<br/>
                                2º Identifícate como usuario de Entereza.<br/>
                                3º Compra pagando el mismo precio que antes.<br/>
                                4º Verifica tus ahorros en tu cuenta.<br/>
                                5º Acumula dinero mientras gastas.<br />
                                6º Todos tus ahorros se encuentran en nuestro banco aliado.
                            </p>
                        </div>
                        <div className="col-md-6 my-4" id="caja-entereza-responsive">
                            <div className="ContainerexplicarEntereza my-2">
                                <img id="explicarResponsiveEntereza" src={explicar}/>
                            </div>
                        </div>
                        <div className="text-white" data-aos="fade-right" >
                            <h2 className="tituloCuadro1-entereza"><importante>Ventajas</importante></h2>
                            <p className="blacktext-entereza lead">
                                ❖ Ahorra a través de tus compras, gastando el mismo precio.<br/>
                                ❖ Crea un historial de consumo.<br/>
                                ❖ Deslígate del remordimiento de gastar mucho.<br/>
                                ❖ Construye objetivos a largo plazo para destinar tus ahorros.<br/>
                            
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6" data-aos="fade-left" id="caja-entereza-empresa">
                        <div className="ContainerexplicarEntereza  mt-5">
                            <img id="explicarEntereza" src={explicar}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="SliderAcercaProducto">
                <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner" heigth="100">
                        <div className="carousel-item active" id="ImagenSlider1">
                            <div id="BoxTextSlider" className="text-center d-flex justify-content-center align-items-center">
                                <p id="TextSlider" className="lead w-75 fs-2 text-light fw-bold">Entereza nació para solucionar de 
                                la mano de muchas empresas el problema del ahorro,
                                acomodándonos al hábito mas incesante de la vida.<br/>
                                <b>GASTAR</b></p>
                            </div>
                        </div>
                        <div className="carousel-item" id="ImagenSlider2">
                            <div id="BoxTextSlider" className="text-center d-flex justify-content-center align-items-center">
                                <p id="TextSlider" className="lead w-75 fs-2 text-light fw-bold">Sin objetivos no debe haber ahorro. 
                                Saber el qué, cómo y para qué ahorrar.
                                Plantéate metas a corto, mediano y largo plazo.<br/>
                                <b>BIENESTAR</b></p>
                            </div>
                        </div>
                    </div>
                    
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>

                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>


            <div className="second__ac-ente">
                
                <h2 className="titulo-entereza2 p-4 mb-5" data-aos="fade-up" >Estamos trabajando con entidades autorizadas para entregarte estos productos</h2>

                <div className="row align-items-center" id="box-entereza">
                    <div className="col-md-6" id="box-entereza-texto">
                        <div className="text-boxEntereza" data-aos="fade-right" data-aos-duration="1600">
                            <h2 className="tituloBox-entereza text-center mb-4">Microcréditos</h2>
                            <div className="contenedorButtonProximamente">
                            <p className="textoBox-entereza lead">
                            Podrás recibir créditos de hasta 300 $us
                            sin papeleo, ni letra chica al más bajo 
                            interés del mercado.<br/>
                            Lo sorprendente es que <colormorado>pagarás tu deuda
                            con tus gastos y aportes voluntarios</colormorado>
                            </p>
                            </div>
                            <div className="col-md-6 mb-4" id="box-entereza-responsive" data-aos-duration="1600" data-aos="fade-down">
                                <img id="ResponsiveEntereza" src={microcredito}/>
                            </div>
                            <div className="contenedorButtonProximamente" data-aos="fade-down" data-aos-duration="1000">
                                <button type="button" id="buttonProximamenteAE" className="btn btn-primary" disabled data-bs-toggle="button" autoComplete="off">Próximamente</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6" id="box-enterezaImage"data-aos="fade-right" data-aos-duration="1600">
                        <div className="BoxEnterezaImg">
                            <img id="imagenEntereza" src={microcredito}/>
                        </div>
                    </div>


                    <div className="col-md-6" id="box-enterezaImage">
                        <div className="BoxEnterezaImg" data-aos="fade-left" data-aos-duration="1600">
                            <img id="imagenEntereza" src={ahorrogrupal}/>
                        </div>
                    </div>
                    <div className="col-md-6" id="box-entereza-texto">
                        <div className="text-boxEntereza" data-aos="fade-right" data-aos-duration="1600">
                            <h2 className="tituloBox-entereza text-center mb-4">Ahorro Grupal</h2>
                            <div className="contenedorButtonProximamente">
                            <p className="textoBox-entereza lead">
                            Tendrás la opción de crear cuentas <colormorado>personales, 
                            familiares y entre amigos, </colormorado> para algún objetivo,
                            la fiesta o el viaje que tanto planearon. <br/>
                            También podrás aportar tus <colormorado>cambios a gente que lo necesite 
                            o para caridad.</colormorado>
                            </p>
                            </div>
                            <div className="col-md-6 mb-4" id="box-entereza-responsive" data-aos="fade-down" data-aos-duration="1600">
                                <img id="ResponsiveEntereza" src={ahorrogrupal}/>
                            </div>
                            <div className="contenedorButtonProximamente" data-aos="fade-down" data-aos-duration="1000">
                                <button type="button" id="buttonProximamenteAE" className="btn btn-primary" disabled data-bs-toggle="button" autoComplete="off">Próximamente</button>
                            </div>
                        </div>
                    </div>             

                    <div className="col-md-6" id="box-entereza-texto" data-aos="fade-right" data-aos-duration="1600">
                        <div className="text-boxEntereza">
                            <h2 className="tituloBox-entereza text-center mb-4">Ahorro voluntario</h2>
                            <div className="contenedorButtonProximamente">
                            <p className="textoBox-entereza lead">
                            ¡Los centavos también suman! <br/>
                            <colormorado>Te lo explicamos:</colormorado> <br/> "Si pagaste con <colormorado> Bs.40 </colormorado> 
                            un producto de <colormorado> Bs.35</colormorado>, 
                            esos <colormorado>Bs.5 de cambio</colormorado> lo puedes destinar a tu ahorro".
                            </p>
                            </div>
                            <div className="col-md-6 mb-4" id="box-entereza-responsive" data-aos="fade-down" data-aos-duration="1600">
                                <img id="ResponsiveEntereza" src={ahorrovoluntario}/>
                            </div>
                            <div className="contenedorButtonProximamente" data-aos="fade-down" data-aos-duration="1000">
                                <button type="button" id="buttonProximamenteAE" className="btn btn-primary" disabled data-bs-toggle="button" autoComplete="off">Próximamente</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6" id="box-enterezaImage" data-aos="fade-right" data-aos-duration="1600">
                        <div className="BoxEnterezaImg">
                            <img id="imagenEntereza" src={ahorrovoluntario}/>
                        </div>
                    </div>


                    <div className="col-md-6" id="box-enterezaImage" data-aos="fade-left" data-aos-duration="1600">
                        <div className="BoxEnterezaImg" id="noBottom">
                            <img id="imagenEntereza" src={inversiones}/>
                        </div>
                    </div>
                    <div className="noBottom col-md-6" id="box-entereza-texto" data-aos="fade-right" data-aos-duration="1600">
                        <div className="text-boxEntereza">
                            <h2 className="tituloBox-entereza text-center mb-4">Inversiones</h2>
                            <div className="contenedorButtonProximamente">
                            <p className="textoBox-entereza lead">
                            Te enseñaremos a invertir y a futuro podrás realizar
                            tus inversiones junto a Entereza, con mucho menor 
                            riesgo que otras plataformas y en muchas <colormorado>más opciones
                            del mercado.</colormorado> 
                            
                            </p>
                            </div>
                            <div className="col-md-6 mb-4" id="box-entereza-responsive" data-aos="fade-down" data-aos-duration="1600">
                                <img id="ResponsiveEntereza" src={inversiones}/>
                            </div>
                            <div className="contenedorButtonProximamente" data-aos="fade-down" data-aos-duration="1000">
                                <button type="button" id="buttonProximamenteAE" className="btn btn-primary" disabled data-bs-toggle="button" autoComplete="off">Próximamente</button>
                            </div>
                        </div>
                    </div>               
                </div>
            </div>

        </div>    
    );
}

export default AcercaEntereza;