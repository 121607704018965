//importaciones
import React, { Component } from "react"

//importacion libreria modal
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from "reactstrap"
import Swal from "sweetalert2";

//arrow function
class OlvideContraseñaModal extends Component {

    constructor(props) {
        super(props);
        const {cookies} = props;
        this.state = {
          modal: false,

          mail: '',
          carnet_identidad:''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this)
        this.onChangeCarnet = this.onChangeCarnet.bind(this)
    }

    onChangeCarnet(e) {
        this.setState({
          carnet_identidad: e.target.value
        });
    }

    onChangeEmail(e) {
        this.setState({
            mail: e.target.value
        });
    }  

    openModal = () => {
        this.setState({
            modal: true
        })
    }
    
    closeModal = () => {
        this.setState({
            modal: false
        })
    }

    handleSubmit (event){
        event.preventDefault();
        
        var data = {
            mail: this.state.mail,
            carnet_identidad: this.state.carnet_identidad
        }

        fetch('https://www.enterezabol.com:8443/entereza/forgot_password?email='+data.mail+'&carnet='+data.carnet_identidad, {
                method: 'GET',
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                },
            }).then((result) => {
            result.json().then((resp) => {
                console.log(resp)
                this.setState({
                    modal: false
                })
                if(resp.codeError === 'COD200'){
                    Swal.fire(
                        'Contraseña enviada correctamente',
                        'Revisa tu mail y encontráras un mensaje de Entereza con tus nuevas credenciales.',
                        'success'
                      )
                }else{
                    Swal.fire({
                        icon: 'Error',
                        title: 'Algo salio mal...',
                        text: 'Las credenciales que ingresaste no son correctas o ya solicitaste más cambios de contraseña de los permitidos.'
                    })
                }
            })
        })
    }
    
    
    render() {

        return(
            <div className="bodyOlvideContraseñaModal">
                <a href="#cambiar-contraseña" onClick={this.openModal} className="text-danger">¿Olvidaste tu contraseña?</a>

                <Modal
                    centered
                    size="lg"
                    isOpen={this.state.modal}
                    className="text-center"
                >
                    <ModalHeader className="mx-auto">
                        ¿Olvidaste tu contraseña?
                        
                    </ModalHeader>
                    <ModalBody>
                        Ingresa tu correo y carnet de identidad, te mandaremos un mail con tu nueva contraseña.
                        <div className="form-floating mb-3 mt-3">
                            <input 
                                type="email"
                                className="form-control" 
                                id="floatingInputGrid mail" 
                                placeholder="email"
                                name="mail"
                                onChange={this.onChangeEmail}
                                defaultValue={this.state.mail }
                                required
                            />
                            <label htmlFor="floatingInputGrid">Email</label>
                        </div> 

                        <div className="form-floating mb-3">
                            <input 
                                type="number" 
                                className="form-control" 
                                id="floatingInputGrid carnet_identidad" 
                                placeholder="CI"
                                name="carnet_identidad"
                                onChange={this.onChangeCarnet}
                                defaultValue={this.state.carnet_identidad }
                                required
                            />
                            <label htmlFor="floatingInputGrid">Nro. Carnet de Identidad</label>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                    <Button
                        color="primary"
                        onClick={this.handleSubmit}
                    >
                        Enviar
                    </Button>
                    {' '}
                    <Button onClick={this.closeModal}>
                        Cancel
                    </Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

//exportacion modal
export default OlvideContraseñaModal