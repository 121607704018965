import './presignup.css';

//importacion de componentes
import PreNavbar from '../../components/public/navbar-presignup';
import Footer from '../../components/public/footer';

import Uno from '../../assets/img/bankingbusiness.png';
import Dos from '../../assets/img/smartphone_credit_card.png';
import Tres from '../../assets/img/Group.png';
import Cuatro from '../../assets/img/payment.png';
import { Link } from 'react-router-dom';

function PreSignUp() {
    return (
        <>
            <header>
                <PreNavbar />
            </header>
            <div className="card postsignup-body_div">
                <div className="card-body postsignup-body_div_div">
                    <h4 className="card-title text-center mb-4 mt-4">Créate una cuenta en <br /> <b>Entereza Fintech</b></h4>
                    <div className="cajaBotones-postsignup mx-auto">
                        <Link to="/registro/cliente" className='text-decoration-none'>
                            <button type="button" id="PresignupCliente" className="btn btn-primary btn-lg mb-4 postsignup-body_div_div_a_buttom_1">
                                Crear cuenta como <br />CLIENTE
                            </button>
                        </Link>
                        <Link to="/registro/empresa" className='text-decoration-none'>
                            <button type="button" id="PresignupEmpresa" className="btn btn-primary btn-lg mb-4 postsignup-body_div_div_a_buttom_2">
                                Crear cuenta como <br />EMPRESA
                            </button>
                        </Link>
                    </div>

                    <div className="box-ventajas">
                        <p className="text-center">Únete a la nueva comunidad donde todos ganan.</p>
                    </div>

                    <hr className="postsignup-body_div_div_hr" />
                    <h6 className="card-title text-center mb-4">TODO LO QUE PUEDES<br />HACER CON ENTEREZA:</h6>
                    <div className="row">
                        <div className="mb-3 col-sm-6 box-ventajas">
                            <div className="row g-0">
                                <div className="col-md-4">
                                    <img src={Uno} id="iconPresignup" className="img-fluid rounded-start" />
                                </div>
                                <div className="col-md-8">
                                    <div className="card-body">
                                        <p className="card-text">Obtén un descuento, que se convertirá en un ahorro para tu futuro.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3 col-sm-6 box-ventajas">
                            <div className="row g-0">
                                <div className="col-md-4">
                                    <img src={Dos} id="iconPresignup" className="img-fluid rounded-start" />
                                </div>
                                <div className="col-md-8">
                                    <div className="card-body">
                                        <p className="card-text">Crea un historial de consumo para acceder a servicios financieros.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3 col-sm-6 box-ventajas">
                            <div className="row g-0">
                                <div className="col-md-4">
                                    <img src={Tres} id="iconPresignup" className="img-fluid rounded-start" />
                                </div>
                                <div className="col-md-8">
                                    <div className="card-body">
                                        <p className="card-text">Disfruta de más de 100 empresas afiliadas.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3 col-sm-6 box-ventajas">
                            <div className="row g-0">
                                <div className="col-md-4">
                                    <img src={Cuatro} id="iconPresignup" className="img-fluid rounded-start" />
                                </div>
                                <div className="col-md-8">
                                    <div className="card-body">
                                        <p className="card-text">Siga el estado de tus ahorros.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h3 className="finalText-postsignup text-center mt-4 mb-4">AHORRANDO COMPRANDO</h3>
                </div>
            </div>

            <footer>
                <Footer />
            </footer>
        </>
    );
}

export default PreSignUp;