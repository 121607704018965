import './App.css'
import React, { useEffect, useState } from 'react'

import { BrowserRouter } from "react-router-dom";

// routers dashboard  
import { PrivateRoutes } from './routers/PrivateRoutes'
import { PublicRoutes } from './routers/PublicRoutes'
import { useNavigate } from 'react-router'
import { Provider } from 'react-redux';
import { store } from './redux/store/store.js'
import { RouterApp } from './routers/RouterApp';


const App = () => {
  
    return (

      <Provider store={ store }>
        <RouterApp />
      </Provider>
  
    );
  }


export default App