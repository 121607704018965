import React, {useEffect} from "react";
import "./acerca-nosotros.css";

//importacion imagenes
import heart from '../../assets/img/heart.png';
import bullseye from '../../assets/img/bullseye.png';
import bulb from '../../assets/img/bulb.png';
import gaston from '../../assets/img/gaston.png';
import puggi from '../../assets/img/puggi.png';
import tamflikk from '../../assets/img/tamflikk.png';
import intisau from '../../assets/img/intisau.png';
import rochex from '../../assets/img/Rochex.png';
import Logo from '../../assets/img/LogoBoxUs.png';

// Importar iconos Font Awesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";


// importacion de librerías para efectos
import Aos from 'aos';
import "aos/dist/aos.css";

const AcercaNosotros = () => {
    useEffect(() => {
        Aos.init({ 
            duration: 2000,
            once: true });
    }, []);

    return (
        <div className="all">
            <div className="all__card">
                <h2 className="titulo-entereza py-4 text-center" 
                data-aos="fade-down">Porque pensamos en vos y en tu futuro</h2>

                <div className="row align-items-center" id="AcercaUs">

                    <div className="col-md-6 border border-dark" id="AcercaUs-person">
                        <div className="AcercaUs-name text-center"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1000">
                            <h5>Visión</h5>
                        </div>
                        <div className="text-cnp-3"
                            data-aos="flip-left"
                            data-aos-easing="ease-out-cubic"
                            data-aos-duration="1000">
                            <div className="AcercaUsImg">
                                <img id="AcercaUs-img" src={bullseye}/>
                            </div>
                            <p className="AcercaUs-empresa">
                                Ser la Fintech de primera opción para Latinoamérica
                                que impacte en la industria del consumo y persiga la
                                inclusión financiera.
                            </p>
                        </div>
                    </div>   

                    <div className="col-md-6 border border-dark" id="AcercaUs-person">
                        <div className="AcercaUs-name text-center"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1000">
                            <h5>Propuesta de Valor</h5>
                        </div>
                        <div className="text-cnp-3"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1000">
                            <div className="AcercaUsImg">
                                <img id="AcercaUs-img" src={heart}/>
                            </div>
                            <p className="AcercaUs-empresa">
                            Trabajamos para causar un impacto social en 
                            nuestra región y poder ayudar a millones de
                            personas con sus finanzas personales dando un
                            pequeño aporte generado por sus compras.
                            </p>
                        </div>
                    </div>   

                    <div className="col-md-6 border border-dark" id="AcercaUs-person">
                        <div className="AcercaUs-name text-center"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1000">
                            <h5>Valores</h5>
                        </div>
                        <div className="text-cnp-3"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1000">
                            <div className="AcercaUsImg">
                                <img id="AcercaUs-img" src={bulb}/>
                            </div>
                            <p className="AcercaUs-empresa text-center">
                                Compromiso<br/>
                                Innovación<br/>
                                Inclusión<br/>
                                Unidad<br/>
                                Transparencia
                            </p>
                        </div>
                    </div>   
                </div> 
            </div>        

            <div className="all__card--2 mt-5 pt-5 pb-5">
                <h2 className="titulo-entereza text-center py-4" data-aos="fade-down">Construyendo el futuro</h2>
                <div className="row align-items-center" id="boxsUs">
                    <div className="col-md-6 border border-dark" data-aos="zoom-in-up" data-aos-duration="1000" id="boxsUs-person">
                        <div className="boxsUs-name text-center">
                            <img id="boxsUs-logo" src={Logo}/>
                        </div>
                        <div className="text-cnp-3">
                            <div className="AcercaUsImg">
                                <img id="boxsUs-img" src={gaston}/>
                            </div>
                                <h3 className="boxsUs-tittle">Gastón Rocha Torrez</h3>
                                <h5 className="boxsUs-text">CEO & Co-Founder of Entereza Fintech</h5>
                                <p className="boxsUs-empresa">Entereza Fintech</p>
                                <a target="_blank" href="https://www.linkedin.com/in/gast%C3%B3n-rocha-torrez-230b361ba">
                                    <button className="btn btn-outline-dark" type="button" id="botonColorUs">
                                        <FontAwesomeIcon icon={faLinkedin} id="iconLinkedlin"/>
                                    </button>
                                </a>
                        </div>
                    </div>
                    <div className="col-md-6 border border-dark" data-aos="zoom-in-up" data-aos-duration="1000" id="boxsUs-person">
                        <div className="boxsUs-name text-center">
                            <img id="boxsUs-logo" src={Logo}/>
                        </div>
                            <div className="text-cnp-3">
                            <div className="AcercaUsImg">
                                <img id="boxsUs-img" src={puggi}/>
                            </div>
                                <h3 className="boxsUs-tittle">Adrián Romero Camacho</h3>
                                <h5 className="boxsUs-text">COO & Co-Founder of Entereza Fintech</h5>
                                <p className="boxsUs-empresa">Entereza Fintech</p>
                                <a target="_blank" href="https://www.linkedin.com/in/adri%C3%A1n-romero-8a1802213">
                                    <button className="btn btn-outline-dark" type="button" id="botonColorUs">
                                        <FontAwesomeIcon icon={faLinkedin} id="iconLinkedlin"/>
                                    </button>
                                </a>
                            </div>
                    </div>
                    <div className="col-md-6 border border-dark" data-aos="zoom-in-up" data-aos-duration="1000" id="boxsUs-person">
                        <div className="boxsUs-name text-center">
                            <img id="boxsUs-logo" src={Logo}/>
                        </div>
                            <div className="text-cnp-3">
                                <div className="AcercaUsImg">
                                    <img id="boxsUs-img" src={tamflikk}/>    
                                </div>
                                <h3 className="boxsUs-tittle">Alejandro Tamayo Hornsby</h3>
                                <h5 className="boxsUs-text">CTO & Co-Founder of Entereza Fintech</h5>
                                <p className="boxsUs-empresa">Entereza Fintech</p>
                                <a target="_blank" href="https://www.linkedin.com/in/alejandro-tamayo-hornsby">
                                    <button className="btn btn-outline-dark" type="button" id="botonColorUs">
                                        <FontAwesomeIcon icon={faLinkedin} id="iconLinkedlin"/>
                                    </button>
                                </a>
                            </div>
                    </div>
                    <div className="col-md-6 border border-dark" data-aos="zoom-in-up" data-aos-duration="1000" id="boxsUs-person">
                        <div className="boxsUs-name text-center">
                            <img id="boxsUs-logo" src={Logo}/>
                        </div>
                            <div className="text-cnp-3">
                            <div className="AcercaUsImg">
                                    <img id="boxsUs-img" src={rochex}/>
                                </div>
                                <h3 className="boxsUs-tittle">Diego Rocha Orellana</h3>
                                <h5 className="boxsUs-text">CIO & Co-Founder of Entereza Fintech</h5>
                                <p className="boxsUs-empresa">Entereza Fintech</p>
                                <a target="_blank" href="https://www.linkedin.com/in/diego-rocha-orellana-784620128">
                                    <button className="btn btn-outline-dark" type="button" id="botonColorUs">
                                        <FontAwesomeIcon icon={faLinkedin} id="iconLinkedlin"/>
                                    </button>
                                </a>
                            </div>
                    </div>
                    <div className="col-md-6 border border-dark" data-aos="zoom-in-up" data-aos-duration="1000" id="boxsUs-person">
                        <div className="boxsUs-name text-center">
                            <img id="boxsUs-logo" src={Logo}/>
                        </div>
                            <div className="text-cnp-3">
                                <div className="AcercaUsImg">
                                    <img id="boxsUs-img" src={intisau}/>
                                </div>
                                <h3 className="boxsUs-tittle">Inti Rojas Saldias</h3>
                                <h5 className="boxsUs-text">Deputy CTO & Co-Founder of Entereza Fintech</h5>
                                <p className="boxsUs-empresa">Entereza Fintech</p>
                                <a target="_blank" href="https://www.linkedin.com/in/inti-rojas-b8893b222">
                                    <button className="btn btn-outline-dark" type="button" id="botonColorUs">
                                        <FontAwesomeIcon icon={faLinkedin} id="iconLinkedlin"/>
                                    </button>
                                </a>
                            </div>
                    </div>
                    
                </div> 
            </div>

        </div>
    );
}
export default AcercaNosotros;