import './navbar.css';
import logo from '../../assets/img/logoOriginal.png';
import { Link, NavLink } from 'react-router-dom';

function NavBar(){
    return(
        <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top" id="navbar">
            <div className="container-fluid">
                <Link to='/'>
                    <img src={logo} alt="Entereza" width="46" className="Logo-NavbarPublico mx-1"/>
                </Link>
                <Link className="navbar-brand mb-0 h1 fs-2 mx-2 text-dark" id="EnterezaNavBar" to="/">
                    ENTEREZA
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink 
                                className={
                                    ({isActive}) => (
                                        `nav-link nav_div-div-ul-li-a ${ isActive? 'nav-active': 'text-dark' }`
                                    )
                                } 
                                aria-current="page" 
                                to="/"
                            >
                                Inicio
                            </NavLink>
                        </li>
                        <li className="nav-item dropdown">
                            <span className="nav-link text-dark dropdown-toggle nav_div-div-ul-li-a" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Beneficios para
                            </span>
                            <ul className="dropdown-menu" id="SubMenuAcerca" aria-labelledby="navbarDropdown">
                                <li>
                                    <NavLink 
                                        className={
                                            ({isActive}) => (
                                                `dropdown-item ${ isActive ? 'nav-active': 'text-dark' }`
                                            )
                                        } 
                                        id="ItemSubAcerca" 
                                        to="/acerca-de/producto"
                                    >
                                        Clientes
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink 
                                        className={
                                            ({isActive}) => (
                                                `dropdown-item ${ isActive ? 'nav-active': 'text-dark' }`
                                            )
                                        } 
                                        id="ItemSubAcerca" 
                                        to="/acerca-de/empresas"
                                    >
                                        Empresas
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink 
                                        className={
                                            ({isActive}) => (
                                                `dropdown-item ${ isActive ? 'nav-active': 'text-dark' }`
                                            )
                                        } 
                                        id="ItemSubAcerca" 
                                        to="/acerca-de/nosotros"
                                    >
                                        Quiénes Somos
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        {/*<li className="nav-item ">
                            <NavLink 
                                className={
                                    ({isActive}) => (
                                        `nav-link nav_div-div-ul-li-a ${ isActive? 'nav-active': 'text-dark' }`
                                    )
                                } 
                                to="/empresas-afiliadas"
                            >
                                Empresas Afiliadas
                            </NavLink>
                        </li>*/}
                        <li className="nav-item">
                            <NavLink 
                                className={
                                    ({isActive}) => (
                                        `nav-link nav_div-div-ul-li-a ${ isActive? 'nav-active': 'text-dark' }`
                                    )
                                } 
                                to="/preguntas"
                            >
                                Preguntas Frecuentes
                            </NavLink>
                        </li>
                    </ul>

                    <div>
                        <div className="btn-group boton-desplegable">
                            <button id="BtnNavBarChange" className="btn btn-outline-dark dropdown-toggle botones-auth" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Ingresar
                            </button>
                            <ul className="dropdown-menu" id="dropdownNavBar">
                                <li>
                                    <Link className="dropdown-item" id="dropdownNavItem" to="/ingreso-usuario">
                                    Ingresa como <letra>CLIENTE</letra>
                                    </Link>
                                </li>
                                <li>
                                    <a className="dropdown-item" id="dropdownNavItem" target="_blank" href='https://enterezabol.com/dashboard'>
                                    Ingresa como <letra>EMPRESA</letra>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <Link id="BtnNavBarChange" className="btn btn-outline-dark botones-auth" to="/registro">
                            Crear Cuenta
                        </Link>
                    </div>

                </div>
            </div>
        </nav>
    );
}

export default NavBar;