//importaciones react & css
import React, { useState } from "react";
import './signup.css';

//importacion imagenes
import Logo from '../../assets/img/LogoBoxUs.png';
import TerminosUso from '../../documents/terminosUso.pdf';
import PoliticasPrivacidad from '../../documents/politicasPrivacidad.pdf';

//imortacion librerias modal
import Swal from "sweetalert2";
import "react-responsive-modal/styles.css";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { __authLogin } from "../../redux/actions/authActions";

import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { codeErrors } from "../../types/codeErrors";

let initialAlert = {
  show: false,
  msg: null,
  type: null
}

const CreateAcount = () => {

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [loadingRequest, setLoadingRequest] = useState(true)

  //Mostrar Contraseñas
  const [isPasswordShown, setIsPasswordShown] = useState(false)

  // error inputs 
  const [isInvalid, setIsInvalid] = useState( initialAlert )

  const togglePasswordVisiblity = () => {
    setIsPasswordShown(prev => !prev)
  };

  // dattos de usuario
  const [form, setForm] = useState({
    nombres: "",
    apellidos: "",
    mail: "",
    password_confirm: "",
    userAuth: {
      user_password: "",
      user_code:""
    }
  })

  const onChangeForm = ({ target }) => {
    setForm(prev => ({
      ...prev,
      [target.name]: target.value
    }))
  }

  const onChangeContraseña = (e) => {
    setForm(prev => ({
      ...prev,
      userAuth: {
        user_password: e.target.value
      }
    }))
  }

  //envio de informacion
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsInvalid(initialAlert)

    Swal.fire({
      title: "Enviando formulario de registro",
      text: "Por favor espere un momento...",
      allowOutsideClick: () => false,
      didOpen: () => {
        Swal.showLoading()
      }
    })

    var data = {
      ...form
      //fecha_nacimiento: `${form.año}-${form.mes}-${form.dia}`,
    }

    console.log(data)

    const resp = await fetch(`${process.env.REACT_APP_API_URL}/entereza/usuarios_op`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    })

    const respuestaAPI = await resp.json();

    console.log(respuestaAPI)
    Swal.close()

    //en caso de email existente
    if (respuestaAPI['codeError'] === 'COD056') {
      setIsInvalid({
        show: true,
        msg: 'El email que ingreso ya existe',
        type: 'alert-danger'
      })
    }

    //contraseñas no son iguales
    if (respuestaAPI['codeError'] === 'COD371') {
      setIsInvalid({
        show: true,
        msg: 'Las contraseñas no coinciden',
        type: 'alert-danger'
      })
    }

    //registro completado con exito
    if (respuestaAPI['codeError'] === 'COD215') {
      Swal.fire({
        icon: 'success',
        title: '¡Felicidades, tu cuenta fue creada exitosamente!',
        text: '¡Bienvenido a Entereza, la empresa que cambiará tu vida y la de muchos más!',
        didOpen: () => {
          Swal.showLoading()
        },
        timer: 3000
      }) 

      dispatch(__authLogin({
        mail: form.mail,
        password: form.userAuth.user_password,
        code: "USER"
      }, setLoadingRequest, navigate))

    }else {
      let { entereza } = respuestaAPI
      if(entereza) {
        setIsInvalid({
          show: true,
          msg: entereza.msgError,
          type: 'alert-danger'
        })
      } else {
        setIsInvalid({
          show: true,
          msg: respuestaAPI.msgError,
          type: 'alert-danger'
        })
      }
    }
  }

  const bg = {
    overlay: {
      background: "rgba(191, 200, 214, 0.7)"
    }
  };

  return (
    <div className="signup-body" id="Signup" >

      <div id="ContainerLogoSignup">
        <img id="logoSignup" alt="logo Blanco Entereza" src={Logo} width="90" />
      </div>

      <main className="form-signin">
        <form onSubmit={handleSubmit}>
          <h1 id="tituloSignup" className="h2 mb-4 mt-3 fw-bold">Registro CLIENTE</h1>

          <h1 id="textoSignup" className="h5 mb-4 mt-3 fw-normal">
            Porfavor regístrese para empezar a ahorrar con ENTEREZA
          </h1>

          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="floatingInput nombres"
              name="nombres"
              placeholder="Nombres"
              onChange={onChangeForm}
              defaultValue={form.nombres}
              autoComplete={false}
              required
            />
            <label htmlFor="floatingInputGrid">Nombres</label>
          </div>

            <div className="form-floating mb-3">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInputGrid apellidos"
                  placeholder="Apellidos"
                  name="apellidos"
                  onChange={onChangeForm}
                  defaultValue={form.apellidos}
                  required
                  autoComplete={false}
                />
                <label htmlFor="floatingInputGrid">Apellidos</label>
              </div>
            </div>

          {/*<p className="text-start"><b>Fecha de nacimiento:</b></p>

          <div className="row g-2 mb-3">
            <div className="col-sm">
              <div className="form-floating">
                <select className="form-select" id="floatingSelectGrid" aria-label="Floating label select example" name="año" onChange={onChangeForm} defaultValue={form.año}>
                  <option selected></option>
                  <option defaultValue="1">2022</option>
                  <option defaultValue="2">2021</option>
                  <option defaultValue="3">2020</option>
                  <option defaultValue="4">2019</option>
                  <option defaultValue="5">2018</option>
                  <option defaultValue="6">2017</option>
                  <option defaultValue="7">2016</option>
                  <option defaultValue="8">2015</option>
                  <option defaultValue="9">2014</option>
                  <option defaultValue="10">2013</option>
                  <option defaultValue="11">2012</option>
                  <option defaultValue="12">2011</option>
                  <option defaultValue="13">2010</option>
                  <option defaultValue="14">2009</option>
                  <option defaultValue="15">2008</option>
                  <option defaultValue="16">2007</option>
                  <option defaultValue="17">2006</option>
                  <option defaultValue="18">2005</option>
                  <option defaultValue="19">2004</option>
                  <option defaultValue="20">2003</option>
                  <option defaultValue="21">2002</option>
                  <option defaultValue="22">2001</option>
                  <option defaultValue="23">2000</option>
                  <option defaultValue="24">1999</option>
                  <option defaultValue="25">1998</option>
                  <option defaultValue="26">1997</option>
                  <option defaultValue="27">1996</option>
                  <option defaultValue="28">1995</option>
                  <option defaultValue="29">1994</option>
                  <option defaultValue="30">1993</option>
                  <option defaultValue="31">1992</option>
                  <option defaultValue="32">1991</option>
                  <option defaultValue="33">1990</option>
                  <option defaultValue="34">1989</option>
                  <option defaultValue="35">1988</option>
                  <option defaultValue="36">1987</option>
                  <option defaultValue="37">1986</option>
                  <option defaultValue="38">1985</option>
                  <option defaultValue="39">1984</option>
                  <option defaultValue="40">1983</option>
                  <option defaultValue="41">1982</option>
                  <option defaultValue="42">1981</option>
                  <option defaultValue="43">1980</option>
                  <option defaultValue="44">1979</option>
                  <option defaultValue="45">1978</option>
                  <option defaultValue="46">1977</option>
                  <option defaultValue="47">1976</option>
                  <option defaultValue="48">1975</option>
                  <option defaultValue="49">1974</option>
                  <option defaultValue="50">1973</option>
                  <option defaultValue="51">1972</option>
                  <option defaultValue="52">1971</option>
                  <option defaultValue="53">1970</option>
                </select>
                <label htmlFor="floatingSelectGrid">Año</label>
              </div>
            </div>

            <div className="col-sm">
              <div className="form-floating">
                <select className="form-select" id="floatingSelectGrid" aria-label="Floating label select example" name="mes" onChange={onChangeForm} defaultValue={form.mes}>
                  <option selected></option>
                  <option value="1">Enero</option>
                  <option value="2">Febrero</option>
                  <option value="3">Marzo</option>
                  <option value="4">Abril</option>
                  <option value="5">Mayo</option>
                  <option value="6">Junio</option>
                  <option value="7">Julio</option>
                  <option value="8">Agosto</option>
                  <option value="9">Septiembre</option>
                  <option value="10">Octubre</option>
                  <option value="11">Noviembre</option>
                  <option value="12">Diciembre</option>
                </select>
                <label htmlFor="floatingSelectGrid">Mes</label>
              </div>
            </div>

            <div className="col-sm">
              <div className="form-floating">
                <select className="form-select" id="floatingSelectGrid" aria-label="Floating label select example" name="dia" onChange={onChangeForm} defaultValue={form.dia}>
                  <option selected></option>
                  <option value="1">01</option>
                  <option value="2">02</option>
                  <option value="3">03</option>
                  <option value="4">04</option>
                  <option value="5">05</option>
                  <option value="6">06</option>
                  <option value="7">07</option>
                  <option value="8">08</option>
                  <option value="9">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                </select>
                <label htmlFor="floatingSelectGrid">Dia</label>
              </div>
            </div>
          </div>*/}

          <div className="form-floating mb-3">
            <input
              type="email"
              className="form-control"
              id="floatingInputGrid mail"
              placeholder="email"
              name="mail"
              onChange={onChangeForm}
              defaultValue={form.mail}
              required
              autoComplete="address-level1"
            />
            <label htmlFor="floatingInputGrid">Email</label>
          </div>

          <div className="form-floating mb-3">
            <input
              type={isPasswordShown ? "text" : "password"}
              className="form-control"
              id="floatingInputGrid user_password"
              placeholder="user_password"
              name="user_password"
              onChange={onChangeContraseña}
              defaultValue={form.userAuth.user_password}
              required
              autoComplete={false}
            />
            <label htmlFor="floatingInputGrid">Contraseña</label>
          </div>


          <div className="form-floating mb-3">
            <input
              type={isPasswordShown ? "text" : "password"}
              className="form-control"
              id="floatingPassword password_confirm"
              placeholder="password_confirm"
              name="password_confirm"
              onChange={onChangeForm}
              defaultValue={form.password_confirm}
              required
            />
            <label htmlFor="floatingPassword">Confirmar Contraseña</label>
          </div>

          {
            isInvalid.show
              && (
                <div className={ `bg-white border-0 mb-0 alert d-flex align-items-center ${ isInvalid.type }` } role="alert">
                  
                  <ReportProblemIcon
                    className="text-danger"
                  />
                  <div
                    className="ms-2 text-danger text-start"
                    style={{
                      fontWeight: 600
                    }}
                  >
                    {
                      isInvalid.msg
                    }
                  </div>
                </div>
              )
          }

          <div className="ContainerMostrarContraseña">
            <button
              id="boton-MostrarContraseña"
              className="btn btn-primary"
              type="button"
              onClick={togglePasswordVisiblity}
            >Mostrar Contraseñas</button>
          </div>

          <div className="checkbox mt-4 mb-4">
            <label>
              <input type="checkbox" className="checkbox-input" required /> Acepto los <a href={TerminosUso} target="_blank">Términos de Uso</a> y <a href={PoliticasPrivacidad} target="_blank">Políticas de Privacidad</a>
            </label>
          </div>

          <button
            className="w-100 btn btn-lg btn-primary botonRegistro"
            type="submit"
          >
            Regístrate
          </button>

        </form>
      </main>
    </div>
  )
}

export default (CreateAcount);