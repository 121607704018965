import React, { useEffect, useState } from "react";
import "./perfilUsuario.css";

//importacion de componentes
import { CSSTransition } from 'react-transition-group';
import Modal from 'react-modal';
import Swal from "sweetalert2";

//importacion imagenes
import Logo from '../../assets/img/logoOriginal.png';
import { PerfilUsuarioImagen } from "../../components/private/perfil/PerfilUsuarioImagen";

//importacion pdf
import TerminosUso from '../../documents/terminosUso.pdf';

//importacion redux
import { useSelector, useDispatch } from 'react-redux';
import { _authLogout, __authLogout } from '../../redux/actions/authActions';

//importacion react-router
import { Link, useNavigate } from "react-router-dom";

//importaciones mui
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import MessageIcon from '@mui/icons-material/Message';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { red, green, brown, pink, grey, blue } from '@mui/material/colors';

const PerfilUsuario = () => {

  const { user } = useSelector(state => state.auth)
  const navigate = useNavigate();
  const dispatch = useDispatch()

  //estados para modales
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1,
    },
  };
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalMensaje, setModalMensaje] = useState(false)

  //estados para almacenar data usuarios
  const [nombres, setNombres] = useState("");
  const [codigo_usuario, setcodeUser] = useState("");
  const [apellidos, setapellidos] = useState("");
  const [carnet_identidad, setcarnet_identidad] = useState("");
  const [mail, setmail] = useState("");

  //estados para cambiar password
  const [user_password, setuserpassword] = useState("");
  const [password, setPassword] = useState("");
  const [new_password, setnewpassword] = useState("");
  const [email, setemail] = useState("");

  //estados para enviar sugerencias
  const [mensaje, setMensaje] = useState('')

  //Ejecutar automaticamente la funcion getUser
  useEffect(() => {
    getUser();
  }, [])

  //obtener informacion del usuario
  const getUser = async () => {

    await fetch(`${process.env.REACT_APP_API_URL}/entereza/usuarios_list?code=` + sessionStorage.getItem("userEmail"), {
      method: 'GET',
      headers: {
        'Authorization': "Bearer " + sessionStorage.getItem("currentUser"),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((result) => {
      result.json().then((resp) => {
        setNombres(resp.lista_usuarios[0].usuarioBean.nombres)
        setcodeUser(resp.lista_usuarios[0].usuarioBean.codigo_usuario)
        setapellidos(resp.lista_usuarios[0].usuarioBean.apellidos)
        setcarnet_identidad(resp.lista_usuarios[0].usuarioBean.carnet_identidad)
        setmail(resp.lista_usuarios[0].usuarioBean.mail)
        setemail(resp.lista_usuarios[0].usuarioBean.mail)
      })
    })
  }

  //actualizar informaciom del usuario si existen cambios
  function updateUser() {

    let item = {
      nombres,
      codigo_usuario,
      apellidos,
      carnet_identidad,
      mail
    }


    fetch(`${process.env.REACT_APP_API_URL}/entereza/usuarios_op/`, {
      method: 'POST',
      headers: {
        'Authorization': "Bearer " + sessionStorage.getItem("currentUser"),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    }).then((result) => {
      result.json().then((resp) => {
      })
    })
  }


  //verificar contraseña sea correcta para actualizar informacion
  async function verify(pass) {
    let item = {
      password: pass,
      mail: sessionStorage.getItem("userEmail"),
      code: 'usuario',
      vrf: 'Y'
    }

    fetch(`${process.env.REACT_APP_API_URL}/entereza/login_user`, {
      method: 'POST',
      headers: {
        'Authorization': "Bearer " + sessionStorage.getItem("currentUser"),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    }).then((result) => {
      result.json().then((resp) => {

        if (resp.entereza.codeError === "COD200") {

          updateUser();

          Swal.fire(
            'Cambios realizados exitosamente',
            'La pagina se actualizara y podras verificar la informacion...',
            'success'
          ).then(function () {
            window.location = "/perfil";
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: '¡La contraseña es incorrecta!'
          })
        }

      })

    })
  }


  //Modal ingresar contraseña -> envia a verificacion contraseña
  async function verifyModal() {

    const { value: pass } = await Swal.fire({
      title: 'Ingresa tu contraseña para confirmar los cambios',
      input: 'password',
      inputPlaceholder: 'Password',
      inputAttributes: {
        autocapitalize: 'off',
        autocorrect: 'off'
      }
    })

    setPassword(pass);
    verify(pass);
  }

  //abrir Modal cambio de contraseña
  function openModal() {
    setIsOpen(true);
  }

  //abrir modal mensaje
  const openMensaje = () => {
    setModalMensaje(true)
  }

  //cerrar Modales
  function closeModal() {
    setIsOpen(false);
    setModalMensaje(false)
  }


  //Verificar contraseñas y actualizacion de la misma
  async function actualizarContraseña() {

    let item = {
      user_password,
      new_password,
      email
    }

    fetch(`${process.env.REACT_APP_API_URL}/entereza/user_auth_update/`, {
      method: 'POST',
      headers: {
        'Authorization': "Bearer " + sessionStorage.getItem("currentUser"),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    }).then((result) => {
      result.json().then((resp) => {

        if (resp.codeError === "COD243") {

          Swal.fire({
            title: 'Credenciales actualizadas exitosamente',
            showDenyButton: false,
            confirmButtonText: `Ok`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */

            Swal.fire('Credenciales actualizadas!', '', 'success')
            Swal.close();
            setIsOpen(false);
            setnewpassword("")
            setuserpassword("")
          });
        } else {

          Swal.fire({
            title: ' Error en las credenciales',
            text: "Código error " + resp.codeError,
            icon: "error",
            showDenyButton: false,
            confirmButtonText: `Ok`,

          }).then((result) => {

            setnewpassword("")
            setuserpassword("")
            Swal.fire('Error!', '', 'success')
            Swal.close();

          });
        }
      })

    })
  }

  //enviar sugerencia usuario
  const enviarMensaje = async (e) => {
    e.preventDefault()

    setModalMensaje(false)
    
    Swal.fire({
      title: "Enviando sugerencia",
      text: "Por favor espere un momento...",
      allowOutsideClick: () => false,
      didOpen: () => {
          Swal.showLoading()
      }
  })

  var data = {
      texto: mensaje,
      email: sessionStorage.getItem('userEmail')
  }

  await fetch(`${process.env.REACT_APP_API_URL}/entereza/sugerencias`, {
    method: 'POST',
    headers: {
        'Authorization': "Bearer " + sessionStorage.getItem("currentUser"),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
}).then((result) => {
  result.json().then((resp) => {
      console.log(resp)
      Swal.close()

      setMensaje('')

      Swal.fire({
          icon: 'success',
          title: '¡Sugerencia enviado con éxito!',
          timer: 2000,
          showConfirmButton: false,
      })
  })
})
  }

  //cerrar sesion usuario
  const handleClick = () => {

    Swal.fire({
        title: '¿Estas seguro de cerrar sesión?',
        showDenyButton: true,
        confirmButtonText: `¡No! Quiero quedarme`,
        denyButtonText: `Cerrar sesión`,
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            

        } else if (result.isDenied) {
            dispatch(__authLogout(navigate))

        }

    });
}

  return (
    <div className="pb-5 pt-2">
      <div className="bodyPerfilUsuarioPrivate mx-auto">

        <PerfilUsuarioImagen />
        <h3 className="mt-3 text-center">{user.info.nombres} {user.info.apellidos}</h3>
        <h5 className="mb-5 text-center">CI: {carnet_identidad}</h5>

        <a href={TerminosUso} target="_blank" style={{textDecoration: "none"}}>
        <div className="mb-4 pt-3 w-75 mx-auto" style={{cursor: 'pointer'}}>
            <div className=" text-center rounded-4 d-inline-block" style={{width: 50, borderRadius: 12,opacity: '50%' , background: brown[200]}}>
              <ReceiptLongOutlinedIcon sx={{fontSize: 32, margin: 1, color: brown[900]}} />
            </div>
            <p className="d-inline mx-4 fs-5"><b>Terminos y condiciones</b></p>
        </div>
        </a>
        <div className="mb-4 w-75 mx-auto" style={{cursor: 'pointer'}} onClick={openMensaje}>
            <div className=" text-center rounded-4 d-inline-block" style={{width: 50, borderRadius: 12,opacity: '50%' , background: blue[200]}}>
              <MessageIcon sx={{fontSize: 32, margin: 1, color: blue[900]}} />
            </div>
            <p className="d-inline mx-4 fs-5"><b>Sugerencias</b></p>
        </div>
        <a href="https://enterezabol.com/preguntas" target="_blank" style={{textDecoration: "none"}}>
        <div className="mb-4 w-75 mx-auto" style={{cursor: 'pointer'}}>
            <div className=" text-center rounded-4 d-inline-block" style={{width: 50, borderRadius: 12,opacity: '50%' , background: red[200]}}>
              <HelpOutlineOutlinedIcon sx={{fontSize: 32, margin: 1, color: red[900]}} />
            </div>
            <p className="d-inline mx-4 fs-5"><b>Ayuda / faqs</b></p>
        </div>
        </a>
        <div className="mb-5 w-75 mx-auto" style={{cursor: 'pointer'}} onClick={openModal}>
            <div className=" text-center rounded-4 d-inline-block" style={{width: 50, borderRadius: 12,opacity: '50%' , background: green[200]}}>
              <LockResetOutlinedIcon sx={{fontSize: 32, margin: 1, color: green[900]}} />
            </div>
            <p className="d-inline mx-4 fs-5"><b>Cambiar contraseña</b></p>
        </div>
        <div className="mb-4 w-75 mx-auto" style={{cursor: 'pointer'}} onClick={handleClick}>
            <div className=" text-center rounded-4 d-inline-block" style={{width: 50, borderRadius: 12,opacity: '50%' , background: grey[200]}}>
              <LogoutOutlinedIcon sx={{fontSize: 32, margin: 1, color: grey[900]}} />
            </div>
            <p className="d-inline mx-4 fs-5"><b>Cerrar sesion</b></p>
        </div>
      </div>

      <CSSTransition
        timeout={300}
        classNames="dialog"
      >

        <Modal
          onRequestClose={closeModal}
          isOpen={modalIsOpen}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Example Modal"
        >

          <form className="text-center">
            <img className="mb-4 mx-auto" alt="" src={Logo} width="80" height="95" />

            <h1 className="h3 mb-4 mt-3 fw-bold">Cambiar contraseña</h1>



            <div className="form-floating mb-3">
              <input
                type="password"
                className="form-control"
                id="floatingInputGrid mail"
                placeholder="password"
                name="password"
                onChange={(e) => { setuserpassword(e.target.value) }}
                defaultValue={user_password}
                required
                autoComplete="address-level1"
              />
              <label >Antigua Contraseña</label>
            </div>

            <div className="row g-2 mb-3">
              <div className="form-floating mb-1">
                <input
                  className="form-control"
                  id="floatingInputGrid password"
                  placeholder="password"
                  name="password"
                  onChange={(e) => { setnewpassword(e.target.value) }}
                  defaultValue={new_password}
                  required
                  autoComplete="address-level1"
                />
                <label >Nueva Contraseña</label>
              </div>
            </div>



            <button className="w-100 btn btn-lg btn-primary botonRegistro" type="button" onClick={actualizarContraseña}>Actualizar contraseña</button>



          </form>
        </Modal>
      </CSSTransition>

      <CSSTransition
        timeout={300}
        classNames="dialog"
      >

        <Modal
          onRequestClose={closeModal}
          isOpen={modalMensaje}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Example Modal"
        >

          <form className="text-center" onSubmit={enviarMensaje}>
            <img className="mb-4 mx-auto" alt="" src={Logo} width="80" height="95" />

            <h1 className="h3 mb-4 mt-3 fw-bold">Buzon de sugerencias</h1>



            <div className="form-floating mt-3 mb-4">
              <input
                type="text"
                className="form-control"
                id="floatingInputGrid sugerencia"
                placeholder="sugerencia"
                name="sugerencia"
                onChange={(e)=> setMensaje(e.target.value)}
                defaultValue={mensaje}
                required
                autoComplete={false}
              />
              <label >Mensaje</label>
            </div>

            <button className="w-100 mb-3 btn btn-lg btn-primary botonRegistro" type="submit">
              Enviar sugerencia
            </button>

          </form>
        </Modal>
      </CSSTransition>

    </div>

  );
}

export default PerfilUsuario;