import { types } from "../../types/types"

const initialState = {
    logged: false,
    checked: true,
    msgError: null,
    user: {}
}

export const authReducer = (state=initialState, action) => {

    switch (action.type) {
        case types.authLogin:
            return {
                ...state,
                logged: true
            }

        case types.authLogout:
            return {
                ...state,
                logged: false
            }

        case types.authSetMsgError:
            return {
                ...state,
                msgError: action.payload
            }
        
        case types.authRemoveMsgError:
            return {
                ...state,
                msgError: null
            }

        case types.authStartChecking:
            return{
                ...state,
                checked: true
            }

        case types.authEndChecking:
            return {
                ...state,
                checked: false
            }

        case types.authSetUser:
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload
                }
            }
        
        case types.authRemoveUser:
            return {
                ...state,
                user: null
            }

        case types.authUpdateImageUser:
            return {
                ...state,
                user: {
                    ...state.user,
                    img: action.payload
                }
            }
        
        case types.authDeleteImageUser:
            return {
                ...state,
                user: {
                    ...state.user,
                    img: null
                }
            }
    
        default:
            return state;
    }

}
