import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../assets/img/logoOriginal.png'
import './notFound.css'

export const NotFound = ({
  redirect,
  logged
}) => {
return (
  <div className={
      `Inventario ${  logged ? 'not-found' : 'not-found__public' }`
  }>
      <div className='contenedorMensajeAhorros text-center'>
          <h2 className="fs-1">
              404
          </h2>
          <h2 className='fs-2'>
              Pagina no encontrada
          </h2>
      </div>

      <Link to={redirect}>
          <div className='contenedorImagenAhorros text-center'>
              <img src={Logo} alt="Entereza 404" className='mt-5' width="30%"/>
          </div>
      </Link>
  </div>
)
}