//importaciones react & css
import React, { useEffect, useState } from 'react'
import './empresasafiliadas.css'

//importacion fetch helpers
import { fetchWithoutToken } from '../../helpers/fetchWithoutToken'

//importacion componente tarjetas empresas
import { EmpresasAfiliadasCard } from '../../components/private/empresas-afiliadas/EmpresasAfiliadasCard'

//importacion componente infinite scroll
import InfiniteScroll from "react-infinite-scroll-component"

//importacion skeleton loader
import { EmpresasAfiliadasCardSkeleton } from '../../components/private/empresas-afiliadas/EmpresasAfiliadasCardSkeleton'

//import modal dependencias
import { Modal } from 'react-responsive-modal'
import Swal from 'sweetalert2'

//importacion codigos error
import { codeErrors } from '../../types/codeErrors'

//importaciones MUI
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

//importacion imagenes
import setLoc from '../../assets/img/setLocation.png'


//arrow function + export
export const EmpresasAfiliadas = () => {

    //constante pagina actual
    const [page, setPage] = useState(0)

    //constante para almacenar empresas
    const [business, setBusiness] = useState([])

    //constante has more
    const [hasMore, setHasMore] = useState(true)

    //estados para rubros
    const [rubro, setRubro] = useState([])
    const [rubroName, setRubroName] = useState('')
    const [rubrosList, setRubrosList] = useState([])

    //estados de carga
    const [loadding, setLoadding] = useState(true)

    //estados modales
    const [cityModal, setCityModal] = useState(false)

    useEffect(() => {
        getEnterprises()

        if(sessionStorage.getItem('EntLocCod') === null){
            setCityModal(true)
        }
    }, [page])

    //Avanzar pagina
    const handleOnNext = () => {
        setPage(prev => prev + 1)
    }

    const handleOnSetRubro = (cod, name) => {
        console.log("cod: ", cod," name: ", name)
        setRubroName(name)
        setBusiness([])
        setLoadding(true)
        setRubro(cod)
        getActualEnterprises(cod)
        setCityModal(false)
    }

    const getEnterprises = async () => {
        try {

            const [
                resRubros
            ] = await Promise.all([
                fetchWithoutToken(`entereza/rubros`)
            ])

            const [
                bodyRubros
            ] = await Promise.all([
                resRubros.json()
            ])

            setRubrosList(bodyRubros.rubros || [])

            if (bodyRubros.rubros && bodyRubros.rubros.length > 0) {

                let currentRubro = bodyRubros.rubros[0].codigoRubro
                let currentRubroName = bodyRubros.rubros[0].nombre
                setRubroName(currentRubroName)
                setRubro(currentRubro)

                setRubrosList(bodyRubros.rubros || [])

                const [
                    resEnterprises,
                ] = await Promise.all([
                    fetchWithoutToken(`entereza/emp_links?codigoRubro=${currentRubro}&pageno=${page}&ciudad=${sessionStorage.getItem('EntLocCod')}`),
                ])

                const [
                    bodyEnterprises,
                ] = await Promise.all([
                    resEnterprises.json(),
                ])

                const {
                    empresas,
                    entereza,
                    imagenesEmpresas,
                    linksEmpresa,
                } = bodyEnterprises

                if (entereza.codeError === codeErrors.code200) {
                    setLoadding(false)

                    let prepEnterprises = empresas.map(emp => ({
                        codigoEmpresa: emp.codigoEmpresa,
                        name: emp.nombreEmpresa,
                        img: imagenesEmpresas.find(img => img.codigoEmpresa === emp.codigoEmpresa)?.imgEmpresa || '',
                        links: linksEmpresa?.links.find(link => link.codigoEmpresa === emp.codigoEmpresa) || {},
                        ciudad: emp.ciudad
                    }))

                    setBusiness(prepEnterprises)

                }

            } else {
                Swal.fire({
                    title: 'No hay rubros registrados',
                    text: 'Por favor, intente más tarde',
                    icon: 'info',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: 'var(--color-primary)'
                })
            }

        } catch (err) {
            console.log(err)
        }
    }

    const getActualEnterprises = async (actualRubro) => {

        const [
            resEnterprises,
        ] = await Promise.all([
            fetchWithoutToken(`entereza/emp_links?codigoRubro=${actualRubro}&pageno=${page}&ciudad=${sessionStorage.getItem('EntLocCod')}`),
        ])

        const [
            bodyEnterprises,
        ] = await Promise.all([
            resEnterprises.json(),
        ])

        const {
            empresas,
            entereza,
            imagenesEmpresas,
            linksEmpresa,
        } = bodyEnterprises

        if (entereza.codeError === codeErrors.code200) {
            setLoadding(false)

            let prepEnterprises = empresas.map(emp => ({
                codigoEmpresa: emp.codigoEmpresa,
                name: emp.nombreEmpresa,
                img: imagenesEmpresas.find(img => img.codigoEmpresa === emp.codigoEmpresa)?.imgEmpresa || '',
                links: linksEmpresa?.links.find(link => link.codigoEmpresa === emp.codigoEmpresa) || {},
                ciudad: emp.ciudad
            }))

            setBusiness(prepEnterprises)

        }
    }

    const handleSubmitCity = (city) => {
        sessionStorage.setItem('EntLocCod', city)

        console.log('city: ', city)

        if (city === 'CB') {
            handleOnSetRubro('COD-RUBCB-0001', 'Gastronomia')
        }
        if (city === 'LP') {
            handleOnSetRubro('COD-RUBLP-0001', 'Gastronomia')
        }
    }


    return (
        <div
            className="container-xl position-relative mt-5 mt-md-3 mt-lg-2"
        >

            <div
                className='business-filters d-none d-sm-flex col-sm-3 col-md-3 col-lg-3 bg-white flex-1 px-2 py-4 flex-column'

            >

                <div
                    className='col-12 mt-4'
                >
                    <ul
                        className='list-group list-group-flush'
                    >
                        <li
                            className='list-group-item border-0 pe-0'
                        >
                            <a class="nav-link fw-bold text-color__primary" >
                                Categorías e industrias
                            </a>
                        </li>
                    </ul>
                </div>
                <div
                    className='col-12 business-filters__rubros scroll-style'
                >
                    <ul
                        className='list-group list-group-flush'
                    >
                        <li
                            className='list-group-item border-0 pe-0'
                        >

                            <div class="ms-2 d-block">
                                <ul
                                    className='list-group list-group-flush text-secondary radio'
                                >
                                    {
                                        rubrosList.map((rbr) => (
                                            <>
                                                {rbr.ciudad === sessionStorage.getItem('EntLocCod') ?
                                                    <li
                                                        key={rbr.codigoRubro}
                                                        className='list-group-item border-0 d-flex align-items-center justify-content-between'
                                                    >
                                                        <span
                                                            className='col-10'
                                                        >
                                                            {
                                                                rbr.nombre
                                                            }
                                                        </span>
                                                        <input
                                                            class="ms-auto form-check-input ms-2 col-1"
                                                            type="radio"
                                                            name="rubro"
                                                            onChange={() => handleOnSetRubro(rbr.codigoRubro, rbr.nombre)}
                                                            checked={rubro === rbr.codigoRubro}
                                                        />
                                                    </li>
                                                    :
                                                    null
                                                }
                                            </>
                                        ))
                                    }
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                className='row col-12 col-sm-9 col-lg-9 ms-sm-auto mx-auto mx-sm-0 px-0 py-4 ps-sm-0 '
            >
                <div
                    className='col-12 d-flex flex-wrap justify-content-around mt-0 mt-sm-3 mt-md-4 px-0'
                >
                    <div
                        className='d-flex d-sm-none col-12 justify-content-center align-items-center mb-3 dropdown'
                    >
                        <button
                            className='btn-base btn-action px-4 py-3 fs-6 col-10 m-auto dropdown-toggle fw-bold d-flex align-items-center justify-content-between'
                            id="dropdownEmpresasAfiliadasFilter" data-bs-toggle="dropdown" aria-expanded="false"
                        >
                            {rubroName}
                        </button>
                        <ul
                            class="dropdown-menu col-10"
                            aria-labelledby="dropdownEmpresasAfiliadasFilter"
                        >
                            {/* <li
className='fw-bold ms-3 mb-1 text-color__primary'
>
Action
</li> */}
                            {
                                rubrosList &&
                                rubrosList.map((rbr) => (
                                    <>
                                        {rbr.ciudad === sessionStorage.getItem('EntLocCod') ?
                                            <li
                                                className='dropdown-item cursor-pointer'
                                                key={rbr.codigoRubro}
                                                onClick={() => handleOnSetRubro(rbr.codigoRubro, rbr.nombre)}
                                            >
                                                {
                                                    rbr.nombre
                                                }
                                            </li>
                                            :
                                            null
                                        }
                                    </>
                                ))
                            }
                        </ul>
                    </div>
                    <div
                        className='col-12 d-fkex flex-row flex-wrap business-card'
                        style={{
                            minHeight: '50vh'
                        }}
                    >
                        <InfiniteScroll
                            dataLength={business.length}
                            next={handleOnNext}
                            hasMore={hasMore}
                            endMessage={
                                <div
                                    className='col-12 mt-3 mb-2 text-center text-color__secondary'
                                    style={{
                                        fontWeight: 600
                                    }}
                                >
                                    Oops!!!<br />
                                    Lo has visto todo
                                </div>
                            }
                        >
                            {loadding ?
                                <>
                                    <EmpresasAfiliadasCardSkeleton />
                                    <EmpresasAfiliadasCardSkeleton />
                                    <EmpresasAfiliadasCardSkeleton />
                                    <EmpresasAfiliadasCardSkeleton />
                                    <EmpresasAfiliadasCardSkeleton />
                                    <EmpresasAfiliadasCardSkeleton />
                                    <EmpresasAfiliadasCardSkeleton />
                                    <EmpresasAfiliadasCardSkeleton />
                                </>
                                :
                                null
                            }
                            {
                                business.map(item => (
                                    <EmpresasAfiliadasCard
                                        key={item.codigoEmpresa}
                                        {
                                        ...item
                                        }
                                    />
                                ))
                            }
                        </InfiniteScroll>
                    </div>
                </div>
            </div>

            <Modal
                open={cityModal}
                showCloseIcon={false}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                blockScroll={true}
                center
            >
                    <div className="card border-0 h-100 py-4" id='containerCardModal'>
                        <div className="card-body d-flex justify-content-center align-items-center">
                            <div className='text-center'>
                                <h2 className="card-title mb-2 text-center fw-bolder">¡Elige una ciudad para ver las empresas afiliadas!</h2>
                                <img style={{width: "16rem"}} src={setLoc} alt="setUbicacionEntereza"/>
                                <div className='text-center mt-3'>

                                    <Box sx={{ '& button': { m: 1 } }}>
                                        <div>
                                            <Button variant="text" size="large" onClick={() => handleSubmitCity('CB')} className='border' sx={{color: '#5457A5'}}>
                                                Cochabamba
                                            </Button>
                                            <Button variant="text" size="large" onClick={() => handleSubmitCity('LP')} className='border' sx={{color: '#5457A5'}}>
                                                La Paz
                                            </Button>
                                        </div>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
            </Modal>
        </div>
    )
}
