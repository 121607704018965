import React, { useCallback, useState, useEffect } from 'react'

// library for crop image --> https://www.npmjs.com/package/react-easy-crop
import Cropper from 'react-easy-crop'

// library material ui --> https://www.npmjs.com/package/@material-ui/core
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@material-ui/core/Button'
import Stack from '@mui/material/Stack';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { Avatar, Slider, Typography, useMediaQuery } from '@mui/material';
import { withStyles } from '@material-ui/core/styles'

// import icons mui 
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';

// import custom styles 
import { muiModalPreviewImage, previewImage } from '../../../helpers/customStyles';

import Swal from 'sweetalert2';
import { stringAvatar } from '../../../helpers/stringColors';
import getCroppedImg from '../../../helpers/cropImages';

import { useDispatch, useSelector } from 'react-redux';
import { __authDeleteImageUser, __authUpdateImageUser } from '../../../redux/actions/authActions';

const actionsImage = [
    { icon: <ImageSearchIcon />, name: 'Editar' },
    { icon: <ImageNotSupportedIcon />, name: 'Eliminar' },
  ];

const PerfilUsuarioImagen2 = ({ classes }) => {

    // imagen de perfil 
    const [profileImage, setProfileImage] = useState(null)
    // state for crop image 
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    // state for zoom image
    const [zoom, setZoom] = useState(1)
    // state modal preview image 
    const [modal, setModal] = useState(false)
    // state resize points 
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    // state loading update image 
    const [backDrop, setBackDrop] = useState(false)

    // redux toolkits 
    const { user } = useSelector(state => state.auth)
    const dispatch = useDispatch()

    // media query
    let isMobile = useMediaQuery('(max-width:600px)')

    const handleToggleModal = () => setModal(prev => !prev)

    // function click input for change image
    const handleOnChangeImage = () => {
        document.getElementById("USR-profile__image").click();
    }

    // function change image in input
    const handleOnChangeInput = ({ target }) => {
        // console.log(target.files[0]);
        // console.log(URL.createObjectURL(target.files[0]));
        setProfileImage(URL.createObjectURL(target.files[0]));
        handleToggleModal()

    }

    // axis coords  of the crop box
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    // function for crop image
    const handleOnCropImage = useCallback(async () => {

        let operation = user.img? "B": "A" 

        try {

            const croppedImage = await getCroppedImg(
                profileImage,
                croppedAreaPixels,
            )

            dispatch( __authUpdateImageUser(croppedImage, operation, setBackDrop, handleOnCancel) )

        } catch (e) {
          console.error(e)
        }
      }, [croppedAreaPixels])

    //   cancel proccess/ close modal
    const handleOnCancel = () => {
        setProfileImage(null)
        setCroppedAreaPixels(null)
        setCrop({ x: 0, y: 0 })
        setZoom(1)
        handleToggleModal()
    }


    // delete image 
    const handleOnDeleteImage = async() => {
        try {
            Swal.fire({
                title: '¿Estás seguro?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
            }).then( (result) => {
                if (result.isConfirmed) {

                    dispatch( __authDeleteImageUser() )

                }
            } )
        } catch(err) {
            console.log(err)
            Swal.fire({
                title: 'Server error',
                icon: 'error',
                text: 'Ocurrió un error al eliminar la imagen',
            })
        }
    }

  return (
      <>

        <Modal
            open={modal}
            onClose={handleToggleModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            
            <Box sx={{ ...muiModalPreviewImage }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backDrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
                <div className={classes.cropContainer}>
                    <Cropper
                        style={{ ...previewImage }}
                        image={profileImage}
                        crop={crop}
                        aspect={4 / 4}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        zoom={zoom}
                    />
                </div>
                <div className={classes.controls}>
                    {
                        !isMobile
                            && (
                                <div className={classes.sliderContainer}>
                                    <Typography
                                        variant="overline"
                                        classes={{ root: classes.sliderLabel }}
                                    >
                                        Zoom
                                    </Typography>
                                    <Slider
                                        value={zoom}
                                        min={1}
                                        max={3}
                                        step={0.1}
                                        aria-labelledby="Zoom"
                                        classes={{ root: classes.slider }}
                                        onChange={(e, zoom) => setZoom(zoom)}
                                    />
                                </div>
                            )
                    }
                    
                    <Stack 
                        direction={ { xs: 'column', sm: 'row' } }
                        spacing={2} 
                        classes={{ root: classes.buttons }}
                    >
                        <Button
                            onClick={handleOnCropImage}
                            variant="contained"
                            color="primary"
                            classes={{ root: classes.cropButton }}
                        >
                            Guardar
                        </Button>
                        <Button 
                            variant="outlined" 
                            color="error"
                            classes={{ root: classes.cropButton }}
                            onClick={ handleOnCancel }
                        >
                            Cancelar
                        </Button>
                    </Stack>
                </div>

            </Box>
        </Modal>
        
        <div
            className='perfil-img'
        >
            <div
            className="perfil-img__container"
            // onClick={ handleOnChangeImage }
            >
                {
                    !!user && !!user.img
                        ? (
                            <img src={user.img} />
                        ): (
                            <Avatar
                                style={{
                                    width: '10rem',
                                    height: '10rem',
                                    fontSize: '3.5rem',
                                }}
                                
                                {...stringAvatar(user.info.nombres)}
                            />
                        )
                }
                
                {/* <div
                    className="perfil-img__container-speeddial"
                > */}
                    <SpeedDial
                        ariaLabel="SpeedDial basic example"
                        sx={{
                            position: 'absolute', 
                            bottom: 0, 
                            right: 0, 
                            zIndex: 1,
                        }}
                        icon={<SpeedDialIcon />}
                        direction='left'
                        FabProps={{
                            sx: {
                              bgcolor: 'var(--color-primary)',
                              '&:hover': {
                                bgcolor: 'var(--color-primary)',
                              },
                              height: 50,
                                width: 50,
                            }
                          }}

                        // classes={{ root: classes.button }}
                    >
                        {
                            actionsImage.map( action => (
                                <SpeedDialAction
                                    key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    onClick={
                                        action.name === 'Editar'? handleOnChangeImage: handleOnDeleteImage
                                    }
                                />
                            ) )
                        }
                    </SpeedDial>
                {/* </div> */}
            </div>
            <input 
            style={{ display: 'none' }} 
            id="USR-profile__image" 
            accept="image/*"
            type="file"
            // value={profileImage}
            onChange={ handleOnChangeInput }
            />
        </div>
    </>
  )
}

export const PerfilUsuarioImagen = withStyles(previewImage)(PerfilUsuarioImagen2)
