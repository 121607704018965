import React from "react";
import './faqs.css';

function Faqs() {
    return (
        <div className="bodyFaqsPage">
            <h1 className="text-center p-1">PREGUNTAS FRECUENTES</h1>
            <div className="text-center py-4 mt-4 divLinksPaginaFaqs">
                <a href="#sobreEntereza" className="div-a-LinksPaginaFaqs">• Preguntas frecuentes sobre Entereza</a><br />
                <a href="#Usuarios" className="div-a-LinksPaginaFaqs">• Preguntas frecuentes de Usuarios</a><br />
                <a href="#Empresas" className="div-a-LinksPaginaFaqs">• Preguntas frecuentes de Empresas</a><br />
            </div>

            <h3 className="subtitulosFaqsPage mt-5 mb-4 w-75 mx-auto" id="sobreEntereza">❖ Sobre Entereza:</h3>

            <div className="accordion w-75 mx-auto acordeonesFaqsPage" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <b>• ¿Qué es Entereza y cómo funciona?</b>
                        </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Entereza es una Fintech (Empresa de tecnología que brinda servicios financieros) que busca otorgar ahorros a sus usuarios a través de sus
                            gastos en nuestras diferentes empresas afiliadas y a partir de este ahorro incluir
                            financieramente a la población desatendida, todos los ahorros serán captados por nuestra entidad autorizada aliada.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <b>• Requisitos para usar Entereza</b>
                        </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            Entereza es una plataforma gratuita que está disponible para cualquier persona
                            mayor de 13 años. Solo se necesita datos certeros para que accedas a la creación
                            de tu cuenta:<br />
                            - Nombre completo<br />
                            - Carnet de Identidad<br />
                            - Fecha de Nacimiento<br />
                            - Número de Contacto<br />
                            - Email
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <b>• ¿Es seguro usar Entereza?</b>
                        </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            Entereza es un servicio totalmente seguro y transparente, basado en un sistema
                            de encriptación de datos desde el momento en que te creas tu cuenta hasta el
                            retiro de tus ahorros donde almacenamos de manera segura tu
                            información. Todos los términos y condiciones de nuestra plataforma se
                            encuentran en los documentos adjuntos.
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            <b>• ¿Qué rubros están afiliados?</b>
                        </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            Tenemos alrededor de 50 marcas afiliadas e incorporamos alrededor de 10
                            empresas por semana. Los rubros aliados son:<br />
                            - Restaurantes<br />
                            - Electrodomésticos<br />
                            - Belleza y estética<br />
                            - Academias de deporte<br />
                            - Venta y reparación de celulares<br />
                            - Productos de Limpieza y Hogar<br />
                            - Instituciones Educativas<br />
                            Y muchos más.<br />
                            <a href="/empresas-afiliadas">Ver nuestras empresas afiliadas</a>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            <b>• ¿Tienen una aplicación móvil?</b>
                        </button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            Por el momento no, pero se encuentra dentro del plan operativo lanzar nuestra
                            App Mobile para Julio de 2022.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                            <b>• ¿Son una aplicación de Delivery?</b>
                        </button>
                    </h2>
                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            No, somos una Fintech que aparte de incluirlos financieramente a nuestros
                            usuarios, les damos la comodidad de poder realizar sus pedidos mediante
                            Entereza, pero contactándote directamente con la empresa.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSeven">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                            <b>• ¿Entereza es un banco? ¿Por qué?</b>
                        </button>
                    </h2>
                    <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            No, no somos un banco ya que no realizamos ningún tipo de intermediación
                            financiera, actividad que si realizan los bancos y las regulaciones actuales de
                            nuestro País nos impiden regularnos dentro del marco regulatorio Fintech, ley
                            que estamos en el proceso de presentarla jurídicamente.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEight">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                            <b>• ¿Trabajan con algún banco?</b>
                        </button>
                    </h2>
                    <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            Trabajamos con una entidad financiera que se encargara de captar todos los
                            ahorros de nuestros usuarios y a futuro, el mismo pueda otorgar microcréditos a
                            nuestros clientes afiliados.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingNine">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                            <b>• ¿Es necesario tener una cuenta bancaria para crearme una cuenta de Entereza?</b>
                        </button>
                    </h2>
                    <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            De ninguna manera, nosotros nos encargaremos de incluirte financieramente con nuestro banco aliado de manera directa.
                        </div>
                    </div>
                </div>
            </div>

            <h3 className="subtitulosFaqsPage mt-5 mb-4 w-75 mx-auto" id="Usuarios">❖ Usuarios:</h3>

            <div className="accordion w-75 mx-auto acordeonesFaqsPage" id="accordionExample2">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading1">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                            <b>• ¿Qué beneficios tengo al ser Usuario de Entereza?</b>
                        </button>
                    </h2>
                    <div id="collapse1" className="accordion-collapse collapse" aria-labelledby="heading1" data-bs-parent="#accordionExample2">
                        <div className="accordion-body">
                            - Ahorrar a través de tus compras pagando el mismo precio que antes.<br />
                            - Comenzar a crear un historial de consumo<br />
                            - Creación de una cuenta de ahorros Entereza a partir de los 13 años de edad<br />
                            - Contactarte directamente con la empresa de tu gusto.<br />
                            - Educarte financieramente (blogs, podcast, capacitaciones)
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading2">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                            <b>• ¿Cómo pago o compro con Entereza en los negocios?</b>
                        </button>
                    </h2>
                    <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionExample2">
                        <div className="accordion-body">
                            <b>Paso 1:</b> Aproxímate a cualquier negocio afiliados a Entereza (podrás ver nuestro
                            letrero distintivo en la puerta del negocio)<br />
                            <b>Paso 2:</b> Acércate a la caja o al operador y dile que eres USUARIO DE ENTEREZA.<br />
                            <b>Paso 3:</b> El operador del negocio te pedirá el número de tu Carnet de Identidad para
                            el registro.<br />
                            <b>Paso 4:</b> Compra el producto o servicio pagando el mismo precio que antes sin ningún
                            aumento.<br />
                            <b>Paso 5:</b> Te llegará una notificación a tu correo electrónico indicándote la cantidad
                            que ahorraste por tu compra y podrás verificarlo ingresando con tu cuenta a nuestra
                            página web.<br />
                            <b>Paso 6:</b> Sigue comprando sin ningún remordimiento, planea y disfruta el destino de
                            tus ahorros.

                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading3">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                            <b>• ¿Dónde puedo consultar el estado de mis ahorros y llevar el registro de mis compras?</b>
                        </button>
                    </h2>
                    <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample2">
                        <div className="accordion-body">
                            Una vez que hayas creado tu cuenta en nuestra página Web, podrás ingresar con tu
                            correo electrónico y tu contraseña en la sección <b>”INGRESAR”</b>, posteriormente verás una
                            sección de <b>“AHORROS”</b> donde podrás ver todo el historial de tus compras y por ende, el
                            monto de tus ahorros acumulados.<br />
                            Sin embargo, cada que realices una compra con Entereza te llegará una notificación a tu
                            correo electrónico con el total ahorrado por tu compra.
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading4">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                            <b>• ¿Hasta cuánto dinero puedo ahorrar?</b>
                        </button>
                    </h2>
                    <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionExample2">
                        <div className="accordion-body">
                            Es ilimitado el dinero que puedes ahorrar con Entereza, eso lo decides vos.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading5">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                            <b>• ¿Cómo retiro mis ahorros y en cuánto tiempo?</b>
                        </button>
                    </h2>
                    <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample2">
                        <div className="accordion-body">
                            A la hora de crearte una cuenta podrás decidir el timepo en el que deseas retirar tus ahorros.
                            Paralelamente Entereza se contacta con nuestra entidad autorizada aliada y realizaremos la 
                            transferencia de tus ahorros a tu cuenta bancaria en un lapso máximo de 24 horas.<br />
                            NOTA: La cuenta bancaria tiene que tener el mismo nombre del remitente.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading6">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                            <b>• ¿Qué hacen con mis ahorros hasta que los retire?</b>
                        </button>
                    </h2>
                    <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionExample2">
                        <div className="accordion-body">
                            Tus ahorros se encontraran depositados en una cuenta en común de la entidad
                            financiera con la que trabajamos.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading7">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                            <b>• ¿Cuánto dinero de descuento recibo de las empresas?</b>
                        </button>
                    </h2>
                    <div id="collapse7" className="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordionExample2">
                        <div className="accordion-body">
                            El descuento varía en función al porcentaje de la empresa que desea otorgarte,
                            oscila entre 1% y 20% según la empresa y el rubro al que pertenece.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading8">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                            <b>• ¿Cuánto demora en llegarme el descuento a mi cuenta?</b>
                        </button>
                    </h2>
                    <div id="collapse8" className="accordion-collapse collapse" aria-labelledby="heading8" data-bs-parent="#accordionExample2">
                        <div className="accordion-body">
                            Una vez que realices la compra en cualquier empresa afiliada, te enviará
                            instantáneamente un correo con la cantidad de descuento que obtuviste y el
                            monto se cargará a tu cuenta de Entereza automáticamente en un lapso
                            máximo de 30 segundos.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading9">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                            <b>• ¿Tiene algún costo utilizar Entereza? ¿Cuánto me cobran?</b>
                        </button>
                    </h2>
                    <div id="collapse9" className="accordion-collapse collapse" aria-labelledby="heading9" data-bs-parent="#accordionExample2">
                        <div className="accordion-body">
                            Crearte cuenta en Entereza es totalmente gratuito.
                            Te cobramos 5 centavos por cada boliviano (Bs.1) AHORRADO, es decir, un 5%
                            del total que ahorraste gracias a nuestro servicio.<br />
                            Sin Entereza gastarías el mismo precio y no obtendrías ninguna retribución por
                            tu compra.
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading10">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                            <b>• ¿Hay cargos ocultos o adicionales en el servicio de Entereza?</b>
                        </button>
                    </h2>
                    <div id="collapse10" className="accordion-collapse collapse" aria-labelledby="heading10" data-bs-parent="#accordionExample2">
                        <div className="accordion-body">
                            No existe ningún cargo adicional, estamos en contra de la letra pequeña y
                            buscamos la mayor transparencia a la hora de usar nuestro servicio.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading13">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse13" aria-expanded="false" aria-controls="collapse13">
                            <b>• Le hice una compra a una empresa por la App Web y no me entregó el producto.</b>
                        </button>
                    </h2>
                    <div id="collapse13" className="accordion-collapse collapse" aria-labelledby="heading13" data-bs-parent="#accordionExample2">
                        <div className="accordion-body">
                            Nosotros a través de nuestra página web nos encargamos de contactarte con la
                            empresa, posteriormente no nos hacemos responsables en el proceso de compra,
                            delivery y/o atención al cliente de la empresa con el cliente.
                        </div>
                    </div>
                </div>
            </div>

            <h3 className="subtitulosFaqsPage mt-5 mb-4 w-75 mx-auto" id="Empresas">❖ Empresas:</h3>

            <div className="accordion mb-5 w-75 mx-auto acordeonesFaqsPage" id="accordionExample3">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading14">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse14" aria-expanded="true" aria-controls="collapse14">
                            <b>• ¿Qué beneficios tengo como empresa?</b>
                        </button>
                    </h2>
                    <div id="collapse14" class="accordion-collapse collapse" aria-labelledby="heading14" data-bs-parent="#accordionExample3">
                        <div class="accordion-body">
                            - Te otorgaremos un Dashboard con diferentes KPI's (Métricas) que pueda medir la
                            eficiencia y productividad de tu negocio. También podrás controlar tus
                            inventarios, ventas, recursos humanos, finanzas y segmentar tu marketing de
                            acuerdo a tus clientes potenciales aumentando la retención de los mismos.<br />
                            - Con nuestro Dashboard ahorrarás dinero teniendo un mayor control de tus
                            ventas, evitarás los gastos hormiga y los despilfarros. Tendrás la propiedad
                            de los datos y el control total de tu negocio en todo momento desde tu
                            dispositivo.<br />
                            - La creación de un programa de lealtad expandiendo tu red de clientes
                            potenciales.<br />
                            - A partir de nuestro sistema, te impulsaremos por medio de la digitalización
                            financiera a la inclusión financiera con la oportunidad de acceder a
                            microcréditos mucho más ágiles y sin letra pequeña para el crecimiento de tu
                            empresa.<br />
                            - Creación de una comunidad de proveedores más eficaces que también te
                            permitan ahorrar en las compras de tu materia prima e insumos.<br />
                            - Una publicación mensual exclusiva para comercios con contenidos útiles.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading15">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse15" aria-expanded="false" aria-controls="collapse15">
                            <b>• ¿Cuáles son los requisitos para registrarme como empresa?</b>
                        </button>
                    </h2>
                    <div id="collapse15" className="accordion-collapse collapse" aria-labelledby="heading15" data-bs-parent="#accordionExample3">
                        <div className="accordion-body">
                            Para la creación de tu cuenta como Empresa necesitarás los siguientes requisitos:<br />
                            - Nombre del Negocio<br />
                            - NIT<br />
                            - Categoría/s<br />
                            - Ubicación Principal y de Sucursales (si existe)<br />
                            - Número de contacto<br />
                            - Email<br /><br />
                            Una vez que llenes el registro nos comunicaremos con tu negocio en un lapso de 24 horas para
                            coordinar una reunión y nos comentes más a fondo sobre tu empresa y los KPI's que requiere.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading16">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse16" aria-expanded="false" aria-controls="collapse16">
                            <b>• ¿Cómo funciona cobrar a través de Entereza?</b>
                        </button>
                    </h2>
                    <div id="collapse16" className="accordion-collapse collapse" aria-labelledby="heading16" data-bs-parent="#accordionExample3">
                        <div className="accordion-body">
                            Estos son los siguientes pasos para realizar un cobro a un cliente de Entereza:<br />
                            <b>Paso 1:</b> Iniciar sesión con tu cuenta de empresa para ingresar al Dashboard de tu
                            negocio.<br />
                            <b>Paso 2:</b> El cliente se aproximara indicándote que es usuario de Entereza, crea
                            una nueva venta en nuestro sistema sumando todos los productos de tu
                            inventario que el cliente requiera.<br />
                            <b>Paso 3:</b> Pídele el número de su Carnet de Identidad (CI) y automáticamente el
                            sistema te arrojara el nombre del Cliente.<br />
                            <b>Paso 4:</b> Confirma el nombre del cliente.<br />
                            <b>Paso 5:</b> Haga click en “Realizar Venta” y automáticamente le llegará una
                            notificación al cliente del monto total ahorrado y podrás ver en tu sección
                            “Estadísticas” las ventas y ahorros que otorgaste con Entereza.
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading17">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse17" aria-expanded="false" aria-controls="collapse17">
                            <b>• ¿Cuánto me cobran por el servicio?</b>
                        </button>
                    </h2>
                    <div id="collapse17" className="accordion-collapse collapse" aria-labelledby="heading17" data-bs-parent="#accordionExample3">
                        <div className="accordion-body">
                            Tú decides cuanto de porcentaje de descuento deseas otorgar a los clientes.
                            Mayormente oscila entre 2% y 10 % según el rubro de tu negocio.<br /><br />
                            Y Entereza te cobra una comisión que varía entre 1% y 2% por cada venta
                            únicamente transaccionada a través de nuestro sistema.<br /><br />
                            No existe ningún pago mensual, ni anual por nuestro paquete de beneficios y
                            software, únicamente que contribuyas a los clientes fidelizándolos con un
                            descuento significativo.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading18">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse18" aria-expanded="false" aria-controls="collapse18">
                            <b>• ¿Por cuánto tiempo es el contrato del servicio?</b>
                        </button>
                    </h2>
                    <div id="collapse18" className="accordion-collapse collapse" aria-labelledby="heading18" data-bs-parent="#accordionExample3">
                        <div className="accordion-body">
                            Cada año se renovará el contrato con nuevas políticas y condiciones para el bien
                            de nuestras empresas y usuarios.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading19">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse19" aria-expanded="false" aria-controls="collapse19">
                            <b>• ¿Qué sucede si no estoy contento con el servicio?</b>
                        </button>
                    </h2>
                    <div id="collapse19" className="accordion-collapse collapse" aria-labelledby="heading19" data-bs-parent="#accordionExample3">
                        <div className="accordion-body">
                            Podrás cancelar el contrato previa justificación y en caso de no ser relevante se
                            pagará una indemnización de Bs.100 por los costos operativos, marketing,
                            distintivos, rescisión del contrato y costo de adquisición por empresa.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading20">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse20" aria-expanded="false" aria-controls="collapse20">
                            <b>• ¿Cada cuanto se realiza la rendición de cuentas y cómo me cobran?</b>
                        </button>
                    </h2>
                    <div id="collapse20" className="accordion-collapse collapse" aria-labelledby="heading20" data-bs-parent="#accordionExample3">
                        <div className="accordion-body">
                            La rendición de cuentas se realizará cuando el dinero llegue a una totalidad de
                            Bs. 30 ahorrados de los clientes y se cobrará de manera personal previo aviso de
                            48 horas.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading21">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse21" aria-expanded="false" aria-controls="collapse21">
                            <b>• Quiero ser parte de Entereza, ¿Cómo me registro?</b>
                        </button>
                    </h2>
                    <div id="collapse21" className="accordion-collapse collapse" aria-labelledby="heading21" data-bs-parent="#accordionExample3">
                        <div className="accordion-body">
                            A través de nuestra aplicación web, llenando nuestro formulario de registro y
                            acordando fecha de cita para la verificación de los mismos.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading22">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse22" aria-expanded="false" aria-controls="collapse22">
                            <b>• Si tengo un inconveniente con un cobro, ¿Cómo lo puedo solucionar?</b>
                        </button>
                    </h2>
                    <div id="collapse22" className="accordion-collapse collapse" aria-labelledby="heading22" data-bs-parent="#accordionExample3">
                        <div className="accordion-body">
                            Podrás comunicarte a nuestro Call Center rápidamente y te contestaremos de manera
                            inmediata ofreciéndote la solución más viable y efectiva.<br />
                            En caso de cualquier problema de mayor grado originado por nuestro sistema (técnico),
                            previa justificación del mismo, Entereza se hace 100% responsable de los ahorros de
                            nuestros usuarios.
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading23">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse23" aria-expanded="false" aria-controls="collapse23">
                            <b>• ¿Cuáles son los medios de pago aceptados?</b>
                        </button>
                    </h2>
                    <div id="collapse23" className="accordion-collapse collapse" aria-labelledby="heading23" data-bs-parent="#accordionExample3">
                        <div className="accordion-body">
                            Efectivo, QR y tarjetas de crédito o débito.
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Faqs;