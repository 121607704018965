//importaciones react & css
import React, { useEffect, useState } from 'react'
import './workingOnAhorros.css'

//importaciones iconos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import QrCodeScannerRoundedIcon from '@mui/icons-material/QrCodeScannerRounded';

//importacion de gifs & imgs
import Shame from '../../assets/gif/shame.gif'
import ConfusedPeople from '../../assets/img/peopleConfused.png'

//importaciones reactstrap
import {
    Table,
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap'

//importaciones redux
import { useSelector } from 'react-redux'

//Importacion modales
import Swal from "sweetalert2";
import { Modal as ModalM } from 'react-responsive-modal'

//importaciones MUI
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StoreIcon from '@mui/icons-material/Store';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SystemSecurityUpdateGoodIcon from '@mui/icons-material/SystemSecurityUpdateGood';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Button from '@mui/material/Button';
import { fetchWithToken } from '../../helpers/fetchWithToken';


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 136deg, #b2b5f3 0%, #b2b5f3 25%, #888cf3 50%, #5157f2 75%, #2a31f0 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, #b2b5f3 0%, #b2b5f3 25%, #888cf3 50%, #5157f2 75%, #2a31f0 100%)',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <StoreIcon />,
        2: <PeopleAltIcon />,
        3: <SystemSecurityUpdateGoodIcon />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
    * Whether this step is active.
    * @default false
    */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
    * Mark the step as completed. Is passed to child components.
    * @default false
    */
    completed: PropTypes.bool,
    /**
    * The label displayed in the step icon.
    */
    icon: PropTypes.node,
};

const steps = ['Aproxímate a una empresa afiliada',
    'Identificate como usuario de Entereza y dicta tu NÚMERO DE CARNET ',
    'Verifica el ahorro en tu cuenta'
];


//Arrow function
const WorkingOnAhorros = (props) => {

    const { user } = useSelector(state => state.auth)

    //variables
    //estados modales
    const [modalTickets, setModalTickets] = useState(false)
    //variables tabla compras
    const [comprasData, setComprasData] = useState('')
    const [imagenesEmpresasData, setImagenesEmpresasData] = useState([])
    //variables paginacion
    const [paginaActual, setPaginaActual] = useState(0)
    const [numeroPaginas, setNumeroPaginas] = useState(0)
    //show and hide (loading) components
    const [showBotones, setShowBotones] = useState(false)
    const [showTable, setShowTable] = useState(false)
    //variable para tickets
    const [ticket, setTicket] = useState()
    //codigo usuario para realizar compras
    const [codUnicUser, setCodUnicUser] = useState()
    //loadings
    const [loadingSkeleton, setLoadingSkeleton] = useState(false)

    //estilo modal 
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    //ejecutar funciones automaticamente
    useEffect(() => {
        getUniqueCode()
        getComprasList()
        verifyQrStatus()
    }, [])

    const verifyQrStatus = () => {
        const qrStatus = sessionStorage.getItem('codeErrorQR')

        if (qrStatus !== null) {
            if (qrStatus === '200') {
                Toast.fire({
                    icon: 'success',
                    title: 'El QR fue registrado exitosamente, sera revisado en el plazo de 1 hora.'
                }).then(
                    sessionStorage.removeItem('codeErrorQR')
                )
            } else {
                Toast.fire({
                    icon: 'error',
                    title: 'El QR ya fue registrado anteriormente.'
                }).then(
                    sessionStorage.removeItem('codeErrorQR')
                )
            }
        }
    }

    //obtener codigo usuario para compras con dinero Entereza
    const getUniqueCode = async () => {
        try {
            const resp = await fetchWithToken(`entereza/usuarios_list?code=${sessionStorage.getItem('userEmail')}`)
            const { entereza, codigo_transacciones } = await resp.json()
            
            if(entereza.codeError === 'COD526'){
                setCodUnicUser(codigo_transacciones)
            }else{
                setCodUnicUser()
            }

        } catch (error) {
            console.log(error)
        }
    }

    const showSkeletonLoading = () => {
        return (
            <Box sx={{ width: '75%', marginTop: -3 }} className="mx-auto">
                <Skeleton style={{ height: '5rem', marginTop: '1.5rem' }} />

                <Skeleton style={{ height: '7rem', marginTop: '-2.5rem' }} />
                <Skeleton style={{ height: '7rem', marginTop: '-2.8rem' }} />
                <Skeleton style={{ height: '7rem', marginTop: '-2.8rem' }} />
                <Skeleton style={{ height: '7rem', marginTop: '-2.8rem' }} />
                <Skeleton style={{ height: '7rem', marginTop: '-2.8rem' }} />
            </Box>
        )
    }

    //Traer informacion de compras usuario
    const getComprasList = async (pag) => {
        setLoadingSkeleton(true)

        var data = {
            pageNo: pag || 0,
            codigoUsuario: sessionStorage.getItem('ENT-CODUSER')
        }

        await fetch(`${process.env.REACT_APP_API_URL}/entereza/ventas_user`, {
            method: 'POST',
            headers: {
                'Authorization': "Bearer " + sessionStorage.getItem("currentUser"),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((result) => {
            result.json().then((resp) => {

                if(resp.ventaUsuario !== null){
                    setComprasData(resp.ventaUsuario)
                }
                setImagenesEmpresasData(resp.imgEmpresas)
                setNumeroPaginas(resp.noPaginas)

                setLoadingSkeleton(false)
                setShowTable(true)
                setShowBotones(true)

            })
        })
    }

    //funcion para comparar codigos de Empresa en array compras e imagenes
    const match = (codeEmp) => {
        var img

        for (var j = 0; j < imagenesEmpresasData.length; j++) {

            if (codeEmp === imagenesEmpresasData[j].codigoEmpresa) {
                img = imagenesEmpresasData[j].imgEmpresa
                break
            } else {
                continue
            }

        }

        return img
    }

    //peticion para traer el ticket de cada pedido
    const ticketPdf = async (ticketNro) => {

        Swal.fire({
            title: "Cargando ticket de compra",
            text: "Por favor espere un momento...",
            allowOutsideClick: () => false,
            didOpen: () => {
                Swal.showLoading()
            }
        })

        await fetch(`${process.env.REACT_APP_API_URL}/entereza/get_ticket?ticketCode=${ticketNro}`, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + sessionStorage.getItem("currentUser"),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((result) => {
            result.json().then((resp) => {
                Swal.close()
                console.log(resp)
                setTicket(resp.pdf)
                openModal(resp)

            })
        })
    }

    const openModal = () => {
        setModalTickets(true)
    }

    const closeModal = () => {
        setModalTickets(false)
    }

    //setear numero de pagina actual (Anterior, Siguiente, primera pagina y ultima pagina)
    //Retroceder una pagina
    const pagActualAnterior = () => {
        if (paginaActual > 0) {
            setPaginaActual(paginaActual - 1)
            var pA = paginaActual - 1
            setShowTable(false)
            setShowBotones(false)
            getComprasList(pA)
        }
    }
    //Adelantar una pagina
    const pagActualSiguiente = () => {
        if (paginaActual < numeroPaginas - 1) {
            setPaginaActual(paginaActual + 1)
            var pS = paginaActual + 1
            setShowTable(false)
            setShowBotones(false)
            getComprasList(pS)
        }
    }
    //Ir a la primera pagina
    const pagActualPrimera = () => {
        if (paginaActual > 0) {
            setPaginaActual(0)
            setShowTable(false)
            setShowBotones(false)
            getComprasList()
        }
    }
    //Ir a la ultima pagina
    const pagActualUltima = () => {
        if (paginaActual < numeroPaginas - 1) {
            setPaginaActual((numeroPaginas * 1) - 1)
            var pU = (numeroPaginas * 1) - 1
            setShowTable(false)
            setShowBotones(false)
            getComprasList(pU)
        }
    }

    //Funcion para mostrar tabla de ahorro y loading durante fetch
    const showTableAhorros = () => {
        if (comprasData.length > 0) {
            return (
                <Table

                    responsive
                    striped
                    className='mt-2 w-75 mx-auto border rounded'
                    id="TableAhorros"
                >
                    <thead className="HeadTableAhorros">
                        <tr>
                            <th className='text-center'>
                                Logo
                            </th>
                            <th className='text-center'>
                                <span id="TitulosNombreAhorro">Nombre</span>
                                <span id="TitulosNombreResponsiveAhorro">N/F</span>
                            </th>
                            <th className='text-center' id='TituloFechaAhorro'>
                                Fecha
                            </th>
                            <th className='text-center' id='TituloAhorro'>
                                Ahorro
                            </th>
                            <th className='text-center'>
                                Detalles
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {comprasData.map((compD) => (
                            <tr key={compD.userVentDetail.ticketNo}>
                                <th className='d-flex justify-content-center'>
                                    <img id="ImgEmpresas" src={match(compD.userVentDetail.codigoEmpresa)} />
                                </th>
                                <th className='text-center fw-normal'>
                                    <div id="NombreAhorro">{compD.userVentDetail.nombreEmpresa}</div>
                                    <div id="FechaResponsive" Style="color: #5457a5;">
                                        {(compD.userVentDetail.fecha).substr(0, 10)}
                                    </div>
                                </th>
                                <th className='text-center fw-light' Style="color: #5457a5;" id="FechaAhorro" >
                                    {(compD.userVentDetail.fecha).substr(0, 10)}
                                </th>
                                <th className="text-center" Style="color: #5457a5" id="MontoAhorro">
                                    Bs. {compD.userVentDetail.ahorro}
                                </th>
                                <th className='text-center'>
                                    <button onClick={() => ticketPdf(compD.userVentDetail.ticketNo)}
                                        type="button"
                                        class="btn"
                                        id="DetallesAhorros"><FontAwesomeIcon icon={faPlus} id="DetallesAhorrosIcon" />
                                        <span className='TextoAhorrosTicket'>Ver Ticket</span>
                                    </button>
                                </th>
                            </tr>
                        ))
                        }
                    </tbody>
                </Table>
            )
        } else {
            return (
                <div className='pb-5'>
                    <p className='text-center fs-4'>Aún no realizaste ninguna compra por Entereza...</p>
                    <img src={Shame} className='d-block mx-auto' />
                </div>
            )
        }
    }

    //funcion para mostrar botones adelante-atras y pagina actual
    const showAntesySiguiente = () => {
        if (showBotones === true) {
            return (
                <>

                    <div className='text-center mb-3'>
                        {paginaActual + 1} de {(numeroPaginas * 1)}
                    </div>

                    <div class="btn-toolbar pb-5 d-grid gap-2 d-flex justify-content-center" role="toolbar" aria-label="Toolbar with button groups">
                        <div class="btn-group me-2" role="group" aria-label="First group">
                            <button type="button" class="btn btn-outline-secondary" onClick={() => pagActualPrimera()}>&lt;&lt;</button>
                            <button type="button" class="btn btn-outline-secondary" onClick={() => pagActualAnterior()}>&lt;</button>
                            <button type="button" class="btn btn-outline-secondary" onClick={() => pagActualSiguiente()}>&gt;</button>
                            <button type="button" class="btn btn-outline-secondary" onClick={() => pagActualUltima()}>&gt;&gt;</button>
                        </div>
                    </div>

                </>
            )
        }
    }


    return (
        <div className='mb-5'>
            <div className='contenedorMensajeAhorros pt-5 mx-auto text-center'>
                <div
                    className="h2 fw-bold text-color__primary mb-3"
                >
                    ¡Bienvenido, {user.info.nombres}!
                </div>
                <div
                    className='TextoAhorros h4 fw-bold mb-3'
                >
                    Hasta el momento has ahorrado:
                </div>
                <div
                    className='btn btn-primary btn-sm rounded-pill mb-5 bg-color__info fw-bold fs-3 border-0 px-3 py-1'
                    style={{
                        minWidth: '135px',
                    }}
                >
                    {
                        `Bs. ${user.tiempoFaltante.ahorro > 0 ? Number(user.tiempoFaltante.ahorro).toFixed(2) : '0'}`
                    }
                </div>
                <Stack sx={{ width: '100%' }} spacing={4} className="mx-auto mb-5">
                <Stepper alternativeLabel activeStep={3} connector={<ColorlibConnector />}>
                    {steps.map((label) => (
                        <Step key={label} sx={{ fontSize: 100 }}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                </Stack>
                <div
                    className="h3 text-color__primary"
                >
                    Codigo Entereza: <h2><b>{codUnicUser}</b></h2>
                </div>               

            </div>

            <div className='tablaAhorrosUsuarioLogueado'>
                <h2 className='text-center mt-5'>
                    HISTORIAL DE AHORROS
                </h2>
                {loadingSkeleton ? showSkeletonLoading() : showTableAhorros()}
            </div>

            {comprasData.length === 0 ? '' : showAntesySiguiente()}


            <Modal
                backdrop={false}
                centered
                fullscreen
                isOpen={modalTickets}
            >
                <ModalBody
                    className="scroll-style"
                >
                    <embed className='w-100 h-100' src={`data:application/pdf;base64,${ticket}`} />
                </ModalBody>
                <ModalFooter>
                    <button
                        className="btn btn-danger btn-action btn-noaction mx-auto"
                        onClick={() => closeModal()}
                    >
                        Cerrar
                    </button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

//exportacion pagina
export default WorkingOnAhorros

