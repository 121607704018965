export const fetchWithoutToken = (path, method="GET", data={}) => {

    const baseURI = process.env.REACT_APP_API_URL;
    const url = `${baseURI}/${ path }`

    if( method === "GET" ){
        return fetch(url, {
            method,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
    }else {
        return fetch( url, {
            method,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify( data )
        } )
    }

}