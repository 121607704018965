import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router';
import Footer from '../components/public/footer';

// public components 
import NavBar from '../components/public/navbar';

// public pages 
import AcercaEmpresas from '../pages/public/acerca-empresas';
import AcercaEntereza from '../pages/public/acerca-entereza';
import AcercaNosotros from '../pages/public/acerca-nosotros';
import Faqs from '../pages/public/faqs';
import LandingPage from '../pages/public/landingPage';
import LoginUser from '../pages/public/login-user';
import PreSignUp from '../pages/public/presignup';
import CreateAcountBusiness from '../pages/public/signup-empresas';
import CreateAcountClient from '../pages/public/signup';
import { NotFound } from '../pages/public/NotFound';
import { EmpresasAfiliadas } from '../pages/public/EmpresasAfiliadas';

export const PublicRoutes = ({
    setLogged
}) => {
    const { pathname } = useLocation();
  
    useEffect(() => {
      document.documentElement.scrollTop = 0;
      sessionStorage.setItem('currentPathLanding', pathname);
    }, [pathname]);
    
  return (
    
    <div className={
        (pathname !== "/registro")? " ": "postsignup-body"
    }>
        <header>
            {
                (pathname === "/ingreso-usuario" || pathname === "/registro" || pathname === "/registro/empresa" || pathname === "/registro/cliente")
                    ? (
                        null
                    )
                    : (
                        <NavBar/>
                    )
            }
        </header>

        <div className="content-public">
            <Routes>
                <Route path='/registro/*'>
                    <Route path='cliente' element={<CreateAcountClient />} />
                    <Route path='empresa' element={<CreateAcountBusiness />} />
                </Route>
                <Route path='/ingreso-usuario' element={<LoginUser setLogged={setLogged}/>} />
                <Route path='/registro' element={<PreSignUp />} />
                <Route path='/preguntas' element={<Faqs />} />
                <Route path='/acerca-de/*'>
                    <Route path='nosotros' element={<AcercaNosotros />} />
                    <Route path='empresas' element={<AcercaEmpresas />} />
                    <Route path='producto' element={<AcercaEntereza />} />
                </Route>
                <Route path='/' element={<LandingPage />} />
                <Route path='*' element={<NotFound redirect="/"/> } />
            </Routes>
        </div>
        {
           
            <footer>
                {
                (pathname === "/ingreso-usuario" || pathname === "/registro" || pathname === "/registro/empresa" || pathname === "/registro/cliente")
                    ? (
                        null
                    )
                    : (
                        <Footer/>
                    )
            }
            </footer>
        }
    </div>

    );
};
