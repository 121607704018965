export const types = {
    
    authStartChecking: "[AUTH] Start checking",
    authEndChecking: "[AUTH] End checking",

    authLogin: "[AUTH] Login",
    authLogout: "[AUTH] Logout",

    authSetMsgError: "[AUTH] Set msg error",
    authRemoveMsgError: "[AUTH] Remove msg error",

    authSetUser: "[AUTH] Set user",    
    authRemoveUser: "[AUTH] Remove user",
    
    authGetImageUser: "[AUTH] Get image user",
    authUpdateImageUser: "[AUTH] Update image user",
    authDeleteImageUser: "[AUTH] Delete image user",
    authInsertImageUser: "[AUTH] Insert image user",
}