import react, { Component } from "react";
import './signup-empresas.css';
import Logo from '../../assets/img/LogoBoxUs.png';
import TerminosUso from '../../documents/terminosUso.pdf';
import PoliticasPrivacidad from '../../documents/politicasPrivacidad.pdf';
import Swal from "sweetalert2";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import logoOriginal from '../../assets/img/logoOriginal.png';

import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { codeErrors } from "../../types/codeErrors";

class CreateAcountBusiness extends Component {

  //Datos
  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      nombre_empresa: '',
      nit: '',
      direccion: '',
      contacto: '',
      descripcion: '',
      codigo_empresa: '',
      latitud: '',
      longitud: '',
      verificacion: '',
      numeroSucursales: '',
      ubicacionDescrita: '',
      email: '',
      categoria: '',
      open: false,
      alert: {
        show: false,
        msg: '',
        type: '',
      }
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeNombreEmpresa = this.onChangeNombreEmpresa.bind(this);
    this.onChangeNit = this.onChangeNit.bind(this);
    this.onChangeDireccion = this.onChangeDireccion.bind(this);
    this.onChangeContacto = this.onChangeContacto.bind(this);
    this.onChangeDescripcion = this.onChangeDescripcion.bind(this);
    this.onChangeNumeroSucursales = this.onChangeNumeroSucursales.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeCategoria = this.onChangeCategoria.bind(this);

  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  onChangeNombreEmpresa(e) {
    this.setState({
      nombre_empresa: e.target.value
    });
  }

  onChangeNit(e) {
    this.setState({
      nit: e.target.value
    });
  }

  onChangeDireccion(e) {
    this.setState({
      direccion: e.target.value
    });
  }

  onChangeContacto(e) {
    this.setState({
      contacto: e.target.value
    });
  }

  onChangeDescripcion(e) {
    this.setState({
      descripcion: e.target.value
    });
  }

  onChangeNumeroSucursales(e) {
    this.setState({
      numeroSucursales: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangeCategoria(e) {
    this.setState({
      categoria: e.target.value
    });
  }


  async handleSubmit(event) {
    event.preventDefault();

    this.setState({
      alert: {
        show: false,
        msg: '',
        type: ''
      }
    })

    this.onOpenModal()
    var data = {
      nombre_empresa: this.state.nombre_empresa,
      nit: this.state.nit,
      direccion: this.state.direccion,
      contacto: this.state.contacto,
      descripcion: this.state.descripcion,
      numero_sucursales: this.state.numeroSucursales,
      email: this.state.email,
      categoria: this.state.categoria
    }

    const respuestaAPI = await fetch('https://www.enterezabol.com:8443/entereza/empresas_op', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),

    }).then(function (entereza) {

      //problema del sistema
      if (!entereza.ok) {
        Swal.fire({
          icon: 'error',
          title: 'Algo salio mal...',
          text: 'Es posible que tengamos algun problema tecnico, intentalo de nuevo en unos minutos.'
        }).then(function () {
          window.location = "/registro/empresa";
        });

      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Registro enviado exitosamente, nuestro personal se encargará de verificar tu solicitud.',
          showDenyButton: false,
          confirmButtonText: `Ok`,
        }).then(() => {
          /* Read more about isConfirmed, isDenied below */
          window.location = "/";

        });
      }
      const test = entereza.json();
      return test;
    })


    //en caso de email existente
    if (respuestaAPI['codeError'] === 'COD056') {
      Swal.fire({
        title: 'El email que ingreso ya existe!',
        text: "Puede ingresar con el correo ya existente o cancelar y volverlo a intentar.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ingresar'
      }).then((result) => {
        if (result.isConfirmed) {
          window.location = "/ingreso-usuario";
        }
      })
    }

    //registro completado con exito
    if (respuestaAPI['codeError'] === 'COD215') {

      Swal.fire(
        '¡Felicidades, tu registro fue enviado exitosamente!',
        'Ya diste el primer paso, muy pronto nos pondremos en contacto contigo...',
        'success'
      ).then(function () {
        window.location = "/";
      });

    } else if (respuestaAPI['codeError'] === codeErrors.CODEERROR057) {
      this.setState({
        alert: {
          show: true,
          msg: respuestaAPI.msgError,
          type: 'alert-danger'
        }
      })
    }
    this.onCloseModal()
  }

  render() {
    const { item } = this.state;
    const { open } = this.state;
    const closeIcon = <svg fill="currentColor" viewBox="0 0 20 20" width={28} height={28}>
      <path
        fillRule="none"
      ></path>
    </svg>
    return (
      <div className="signup-body" id="Signup">

        <div id="ContainerLogoSignup">
          <img id="logoSignup" alt="" src={Logo} width="90" height="80" />
        </div>

        <main className="form-signin">
          <form onSubmit={this.handleSubmit} className="mt-4 mb-4">
            <h1 id="tituloSignup" className="h2 mb-4 mt-3 fw-bold">Registro Empresa</h1>

            <h1 id="textoSignup" className="h5 mb-4 mt-3 fw-normal">
              Porfavor llene los siguientes datos de su empresa. El equipo de ENTEREZA realizará una verificación con esta información.

            </h1>

            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingInput nombre_empresa"
                name="nombre_empesa"
                placeholder="nombre_empresa"
                onChange={this.onChangeNombreEmpresa}
                value={this.state.nombre_empresa}
                required
              />
              <label htmlFor="floatingInput" className="form-label">Nombre Empresa</label>
            </div>

              <div className="form-floating mb-3">
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingInputGrid nit"
                    placeholder="nit"
                    name="nit"
                    onChange={this.onChangeNit}
                    value={this.state.nit}
                    required
                  />
                  <label htmlFor="floatingInputGrid">NIT</label>
                </div>
              </div>

            <div className="form-floating mb-3">
              <textarea
                className="form-control"
                placeholder="Leave a comment here"
                id="floatingTextarea2 descripcion"
                name="descripcion"
                onChange={this.onChangeDescripcion}
                value={this.state.descripcion}
                required
              >
              </textarea>
              <label for="floatingTextarea2">Descripción Empresa</label>
            </div>

            <div className="form-floating mb-4">
              <div className="input-group">
                <span className="input-group-text" id="inputGroupPrepend">BO +591</span>
                <input
                  type="number"
                  className="form-control"
                  id="floatingInput contacto"
                  aria-describedby="inputGroupPrepend"
                  placeholder="Numero Contacto"
                  name="contacto"
                  onChange={this.onChangeContacto}
                  value={this.state.contacto}
                  required
                />
              </div>
            </div>

            <div className="form-floating mb-3">
              <input
                type="email"
                className="form-control"
                id="floatingInputGrid email"
                placeholder="email"
                name="email"
                onChange={this.onChangeEmail}
                value={this.state.email}
                required
              />
              <label htmlFor="floatingInputGrid">Email</label>
            </div>
            {
              this.state.alert.show
                && (
                  <div className={ `bg-white border-0 mb-0 alert d-flex align-items-center ${ this.state.alert.type }` } role="alert">
                    
                    <ReportProblemIcon
                      className="text-danger"
                    />
                    <div
                      className="ms-2 text-danger"
                      style={{
                        fontWeight: 600
                      }}
                    >
                      {
                        this.state.alert.msg
                      }
                    </div>
                  </div>
                )
            }

            <div className="checkbox mb-4 mt-4">
              <label>
                <input
                  type="checkbox"
                  className="checkbox-input"
                  required
                /> Acepto los <a href={TerminosUso} target="_blank">Términos de Uso</a> y <a href={PoliticasPrivacidad} target="_blank">Políticas de Privacidad</a>
              </label>
            </div>
            <button
              className="w-100 btn btn-lg btn-primary botonRegistro"
              type="submit">
              Enviar Registro
            </button>
          </form>
        </main>
        
        <Modal
          open={open}
          center
          classNames={{
            overlayAnimationIn: 'customEnterOverlayAnimation',
            overlayAnimationOut: 'customLeaveOverlayAnimation',
            modalAnimationIn: 'customEnterModalAnimation',
            modalAnimationOut: 'customLeaveModalAnimation'
          }}
          animationDuration={800}
          showCloseIcon={false}

        >

          <img className="modalAnimation" src={logoOriginal} alt="entereza" width="200" height="200" />
        </Modal>
      </div>
    );
  }
}

export default CreateAcountBusiness;