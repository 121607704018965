export const codeErrors = {
    code95: "COD95", // success authentication
    code086: "COD086",
    code203: "COD203",
    code200: "COD200", // op exitosa\ logout
    code105: "COD105",
    code500: "COD500",

    // user list 
    code526: "COD526", // lista usuario registro unico
    code527: "COD527", // usuario no valudo
    CODEERROR057: "CODEERROR057", // register input invalid
}
