import { decodeToken } from "react-jwt";
import Swal from "sweetalert2";
import { fetchWithoutToken } from "../../helpers/fetchWithoutToken";
import { fetchWithToken } from "../../helpers/fetchWithToken";
import { codeErrors } from "../../types/codeErrors";
import { types } from "../../types/types";



export const _authSetMsgError = (payload) => ({
    type: types.authSetMsgError,
    payload,
})

export const _authRemoveMsgError = () => ({
    type: types.authRemoveMsgError,
})

export const _authLogin = () => ({
    type: types.authLogin,
})

export const _authStartChecking = () => ({
    type: types.authStartChecking,
})

export const _authEndChecking = () => ({
    type: types.authEndChecking,
})

//login por entereza
export const __authLogin = (payload, setLoadingRequest, navigate) => {
    return async(dispatch) => {

        setLoadingRequest(true)

        try {

            console.log(payload)

            let response = await fetchWithoutToken("entereza/login_user", "POST", payload)
            let { jwt, entereza, codigoEntidad, rol, carnet  } = await response.json();

            console.log(jwt, entereza, codigoEntidad, rol, carnet)

            if(entereza !== null){

            if(entereza.codeError === codeErrors.code105){

                sessionStorage.removeItem("ENT-PATHLANDING");
                sessionStorage.setItem("currentUser", jwt);
                sessionStorage.setItem("userEmail", payload.mail);
                sessionStorage.setItem("ENT-UserRol", rol)
                sessionStorage.setItem('UserKeyID', carnet)
                sessionStorage.setItem( 'ENT-CODUSER', codigoEntidad )

                let formData = new FormData();

                formData.append("image", '');
                formData.append("codigoUsuario", codigoEntidad);
                formData.append("operacion", 'C');

                let [ resImage, resInfo ] = await Promise.all([
                    fetch(`${process.env.REACT_APP_API_URL}/entereza/user_img`, {

                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${jwt}`,
                        },
                        body: formData,
                    }),
                    fetch(`${process.env.REACT_APP_API_URL}/entereza/usuarios_list?code=${payload.mail}`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${jwt}`,
                        }
                    })
                ])

                let [ bodyImage, bodyInfo ] = await Promise.all([
                    resImage.json(),
                    resInfo.json()
                ])

                console.log(bodyImage, bodyInfo)

                if( bodyImage.entereza.codeError === codeErrors.code200 ) {
                    dispatch( _authSetUser({ img: `data:image/jpeg;base64,${bodyImage.img}` }) )
                }

                if(  bodyInfo.entereza.codeError === codeErrors.code526 ) {
                    dispatch( _authSetUser({
                        info: bodyInfo.lista_usuarios[0].usuarioBean,
                        puntos: bodyInfo.lista_usuarios[0].puntos,
                        tiempoFaltante: bodyInfo.tiempo_faltante
                    }) )
                }

                dispatch( _authRemoveMsgError() )
                dispatch( _authLogin() )
                navigate('/ahorros')
            } else {
                dispatch( _authSetMsgError(entereza.msgError) )       
            }
        }else{
            dispatch( _authSetMsgError('Credenciales incorrectas!') )
        }

        } catch (err) {
            console.log( err)
            Swal.fire({
                title: 'Server Error',
                text:"Error login",
                icon: "error"
            })
        }
        setLoadingRequest(false)

    }
}

//login por gmail
export const __authGmailLogin = (rol, jwt, codigoEntidad, mail, carnet, setLoadingRequest, navigate) => {
    return async(dispatch) => {

        setLoadingRequest(true)

                sessionStorage.removeItem("ENT-PATHLANDING");
                sessionStorage.setItem("currentUser", jwt);
                sessionStorage.setItem("userEmail", mail);
                sessionStorage.setItem("ENT-UserRol", rol)
                sessionStorage.setItem('UserKeyID', carnet)
                sessionStorage.setItem( 'ENT-CODUSER', codigoEntidad )

                let formData = new FormData();

                formData.append("image", '');
                formData.append("codigoUsuario", codigoEntidad);
                formData.append("operacion", 'C');

                let [ resImage, resInfo ] = await Promise.all([
                    fetch(`${process.env.REACT_APP_API_URL}/entereza/user_img`, {

                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${jwt}`,
                        },
                        body: formData,
                    }),
                    fetch(`${process.env.REACT_APP_API_URL}/entereza/usuarios_list?code=${mail}`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${jwt}`,
                        }
                    })
                ])

                let [ bodyImage, bodyInfo ] = await Promise.all([
                    resImage.json(),
                    resInfo.json()
                ])

                console.log(bodyImage, bodyInfo)

                if( bodyImage.entereza.codeError === codeErrors.code200 ) {
                    dispatch( _authSetUser({ img: `data:image/jpeg;base64,${bodyImage.img}` }) )
                }

                if(  bodyInfo.entereza.codeError === codeErrors.code526 ) {
                    dispatch( _authSetUser({
                        info: bodyInfo.lista_usuarios[0].usuarioBean,
                        puntos: bodyInfo.lista_usuarios[0].puntos,
                        tiempoFaltante: bodyInfo.tiempo_faltante
                    }) )
                }

                dispatch( _authRemoveMsgError() )
                dispatch( _authLogin() )
                navigate('/ahorros')
        setLoadingRequest(false)

    }
}


export const _authLogout = () => ({
    type: types.authLogout
})

export const __authLogout = (navigate) => {
    return async(dispatch) => {
        try {

            Swal.fire({
                title: "Cerrando sesión",
                text: "Por favor espere un momento...",
                allowOutsideClick: () => false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            
            let mail = sessionStorage.getItem('userEmail');
            let res = await fetchWithToken("entereza/logout", "POST", { mail })
            let { codeError } = await res.json();

            Swal.close()

            if( codeError === codeErrors.code200 ){
                sessionStorage.clear()
                dispatch( _authLogout() )
                navigate('/')
            } else {
                Swal.fire(
                    'Algo salio mal...',
                    'No se pudo cerrar sesión',
                    'error'
                )
            }
                    
        } catch (err) {
            console.log(err)
            Swal.fire({
                title: 'Server Error',
                text:"Error logout",
                icon: "error"
            })
        }

        // sessionStorage.removeItem("ENT-PATHLANDING");
        // sessionStorage.removeItem('currentUser');
        // sessionStorage.removeItem('userEmail');
        // sessionStorage.removeItem('ENT-CODUSER');
        // dispatch( _authLogout() );
        // navigate('/')
    }
}

export const __authValidToken = () => {
    return async(dispatch) => {

        dispatch( _authStartChecking() )

        try {

            let token = sessionStorage.getItem("currentUser");
            let mail = sessionStorage.getItem('userEmail');
            let userCode = sessionStorage.getItem('ENT-CODUSER')
            if( !!token ){

                //const { sub } = decodeToken(token);

                let formData = new FormData();

                formData.append("image", '');
                formData.append("codigoUsuario", userCode);
                formData.append("operacion", 'C');

                // let res = await fetch('http://enterezademo.ngrok.io/entereza/user_img', {

                let [ resImage, resInfo ] = await Promise.all([
                    fetch(`${process.env.REACT_APP_API_URL}/entereza/user_img`, {

                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                        body: formData,
                    }),
                    fetch(`${process.env.REACT_APP_API_URL}/entereza/usuarios_list?code=${mail}`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        }
                    })
                ])

                let [ bodyImage, bodyInfo ] = await Promise.all([
                    resImage.json(),
                    resInfo.json()
                ])

                if( bodyImage.entereza.codeError === codeErrors.code200 ) {
                    dispatch( _authSetUser({ img: `data:image/jpeg;base64,${bodyImage.img}` }) )
                }

                if(  bodyInfo.entereza.codeError === codeErrors.code526 ) {
                    dispatch( _authSetUser({
                        info: bodyInfo.lista_usuarios[0].usuarioBean,
                        puntos: bodyInfo.lista_usuarios[0].puntos,
                        tiempoFaltante: bodyInfo.tiempo_faltante
                    }) )
                }

                dispatch( _authLogin() )
            }else {
                dispatch( _authLogout() )
            }

        } catch (err) {
            console.log(err)
        }

        dispatch( _authEndChecking() )
    }
}


export const _authSetUser = (payload) => ({
    type: types.authSetUser,
    payload
})

// profile image user 

export const _authGetImageUser = (payload={}) => ({
    type: types.authGetImageUser,
    payload
})

export const _authInsertImageUser = (payload) => ({
    type: types.authInsertImageUser,
    payload,
})

export const _authUpdateImageUser = (payload) => ({
    type: types.authUpdateImageUser,
    payload,
})

export const _authDeleteImageUser = () => ({
    type: types.authDeleteImageUser,
})

export const __authInsertImageUser = (payload) => {
    return async(dispatch) => {
        try {
            


        } catch (err) {
            console.log(err)
            
        }
    }
}

export const __authUpdateImageUser = (payload, operation="A", setBackDrop, handleOnCancel) => {
    return async(dispatch) => {
        setBackDrop(true)
        try {
            
            let formData = new FormData()

            // get file of relative path 
            let resBlob = await fetch(payload)
            let bodyBlob = await resBlob.blob()

            let token = sessionStorage.getItem("currentUser");
            let  codUser  = sessionStorage.getItem("ENT-CODUSER")

            formData.append('codigoUsuario', codUser);
            formData.append('operacion', operation)
            formData.append('imagen', bodyBlob)

            let res = await fetch(`${process.env.REACT_APP_API_URL}/entereza/user_img`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            })

            let { entereza } = await res.json()

            console.log(entereza)

            if( entereza.codeError === codeErrors.code200 ) {

                console.log("aqui")

                // convert to base64
                var reader = new FileReader()
                reader.readAsDataURL(bodyBlob)
                reader.onloadend = function () {
                    var base64data = reader.result
                    dispatch( _authUpdateImageUser(base64data) )
                }
                handleOnCancel()

                Swal.fire({
                    title: 'Imagen actualizada',
                    text: 'La imagen se actualizó correctamente',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#5457a5'
                })

            } else {
                console.log("error")
                Swal.fire({
                    title: 'Server Error',
                    text:"Error update image",
                    icon: "error",
                    zIndex: 99999
                })
            }

        } catch (err) {
            console.log(err)
            Swal.fire({
                title: 'Server Error',
                text:"Error update image user",
                icon: "error"
            })
        }
        setBackDrop(false)
    }
}

export const __authDeleteImageUser = () => {
    return async(dispatch) => {
        try {
            
            Swal.fire({
                title: "Eliminando la imagen",
                text: "Espere un momento...",
                allowOutsideClick: () => false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })

            let token = sessionStorage.getItem('currentUser');
            let codUser = sessionStorage.getItem("ENT-CODUSER")

            let formData = new FormData()

            formData.append('codigoUsuario', codUser);
            formData.append('operacion', 'D')
            formData.append('imagen', '')

            // let res = await fetch(`http://enterezademo.ngrok.io/entereza/user_img`, {
            let res = await fetch(`${process.env.REACT_APP_API_URL}/entereza/user_img`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${ token }`
                },
                body: formData
            })
            let { entereza } = await res.json()

            Swal.close()

            if( entereza.codeError === codeErrors.code200 ) {
                Swal.fire({
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'success',
                    title: 'Imagen eliminada',
                })
                dispatch( _authDeleteImageUser() )
            }

        } catch (err) {
            console.log(err)
            Swal.fire({
                title: 'Server Error',
                text:"Error delete image user",
                icon: "error"
            })
        }
    }
}


