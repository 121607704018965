import React from 'react'

// loader icon 
import Loader from "react-js-loader";

import './wait.css'

export const WaitScreen = () => {
  return (
    <div
        className="wait"
    >
        <div className="wait-content">
            <div className={"item"}>
                <Loader type="bubble-ping" bgColor={"#5457a5"} color={'#707070'} size={125} />
            </div>
        </div>
    </div>
  )
}
