//import react and css
import React, { useState, useEffect } from "react";
import './login-user.css';

//helper fetch
import { fetchWithoutToken } from "../../helpers/fetchWithoutToken";
//import img's
import Logo from '../../assets/img/logo-original.png';

//import navigate
import { Link, useNavigate } from "react-router-dom";

//importacion modales
import OlvideContraseñaModal from "../../modals/olvideContraseña";
import { useDispatch, useSelector } from "react-redux";
import { __authLogin, __authGmailLogin } from "../../redux/actions/authActions";

//importaciones para gmail login
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';


const LoginUser = () => {

  const dispatch = useDispatch()

  const { msgError } = useSelector(state => state.auth)
  const navigate = useNavigate()

  //datos  
  const [form, setForm] = useState({
    mail: '',
    password: '',
    code: "USER",
  })

  //idGmail
  const gmailID = '511900415351-b1g7213gfgt8ur96qqfnlinf9rjardj8.apps.googleusercontent.com'

  const [isPasswordShown, setIsPasswordShown] = useState(false)
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false)
  const [loadingRequest, setLoadingRequest] = useState(false)

  //Inicializar gmailID
  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: gmailID,
        scope: ''
      });
    };
    gapi.load('client:auth2', initClient);
  });

  //gmail login success & error
  const onSuccess = async (res) => {
    console.log('success:', res);

    var data = {
      token_id: res.tokenObj.id_token,
    };

    const resp = await fetchWithoutToken("entereza/login_go", "POST", data)
    const {entereza, rol, jwt, codigoEntidad, mail, carnet} = await resp.json()

    if(entereza.codeError === 'COD200'){
      dispatch(__authGmailLogin(rol, jwt, codigoEntidad, mail, carnet, setLoadingRequest, navigate))
    }
  };
  const onFailure = (err) => {
    console.log('failed:', err);
  };

  //Mostrar Contraseñas
  const togglePasswordVisiblity = () => {
    setIsPasswordShown(prev => !prev)
  };

  // "...prev" we deconstruct the previous state and only rewrite the form
  // inside form "...form" we deconstruct the previous state of the form and only 
  // rewrite the current input
  const onChangeForm = (e) => {
    setForm(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }


  //Envio formulario
  const handleSubmit = (e) => {
    e.preventDefault();

    // setLoadingRequest(true)

    dispatch(__authLogin({ ...form }, setLoadingRequest, navigate))
    // setLoadingRequest(false)
  }

  return (
    <div className="signup-bodyUser">
      <main className="form-signin my-auto">
        <Link className="d-flex" to="/">
          <img id="logoSignupUser" alt="Entereza" src={Logo} />
        </Link>
        <form onSubmit={handleSubmit} className="w-100">
          <h1 className="form-signin__title h3 mb-4 mt-3 fw-normal">Ingresa como <span>CLIENTE</span></h1>

          <div className="form-floating mb-3">
            <input
              type="email"
              className={msgError ? "form-control is-invalid" : "form-control"}
              id="floatingInputGrid mail"
              placeholder="email"
              name="mail"
              onChange={onChangeForm}
              value={form.mail}
              disabled={loadingRequest}
              required
              autoComplete="address-level1"
            />
            <label htmlFor="floatingInputGrid">Email</label>
          </div>

          <div className="row g-2 mb-2 form-signin__password">
            <div className="form-floating col-sm-12 mb-1">
              <input
                type={isPasswordShown ? "text" : "password"}
                className={msgError ? "form-control is-invalid mb-0" : "form-control mb-0"}
                id="floatingInputGrid password"
                placeholder="password"
                name="password"
                onChange={onChangeForm}
                value={form.password}
                disabled={loadingRequest}
                required
                autoComplete="address-level1"
              />
              <label htmlFor="floatingPassword">Contraseña</label>
            </div>
            <div className="my-auto px-2 form-signin__password-show">
              <i
                className={!isPasswordShown ? "bi bi-eye" : "bi bi-eye-slash"}
                onClick={togglePasswordVisiblity}
                type="button"
              ></i>
            </div>
          </div>
          {
            msgError && (
              <div className="ms-1 text-danger text-start mb-3" role="alert" style={{ fontSize: '0.85rem' }}>
                {
                  msgError
                }
                <br />
                <OlvideContraseñaModal className="moc" />
              </div>
            )
          }

          <button
            className="w-100 btn btn-lg btn-primary button-signin mt-3"
            type="submit"
            disabled={loadingRequest}
          >
            {
              !loadingRequest
                ? (<>Ingresar</>)
                : (
                  <>
                    <span style={{ width: "1.5rem", height: "1.5rem" }} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  </>
                )
            }
          </button>
          
          <GoogleLogin
            className="mt-2"
            clientId={gmailID}
            buttonText="Ingresar con Google"
            onSuccess={onSuccess}
            onFailure={onFailure}
            cookiePolicy={'single_host_origin'}
          />

          <div className=" checkbox mb-3 mt-4 mx-auto">
            <Link className="form-signin__redirect" to="/registro/cliente">
              ¿No tienes una cuenta?
            </Link>
          </div>
        </form>
      </main>
    </div>
  );
}

export default LoginUser;