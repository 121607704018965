export const fetchWithToken = (path, method="GET", data={}) => {

    const token = sessionStorage.getItem("currentUser") || "";

    const baseURI = process.env.REACT_APP_API_URL;
    const url = `${ baseURI }/${ path }`;

    if( method ==="GET" ){
        return fetch( url, { 
            method,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        } )
    }else {
        return fetch(
            url,
            {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify( data )
            }
        )
    }

}