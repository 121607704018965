import React, { useState } from 'react';

import { useNavigate } from 'react-router';

import { QrReader } from 'react-qr-reader';

import { fetchWithToken } from '../../../helpers/fetchWithToken';

import Swal from 'sweetalert2';

const QRscanner = () => {
    const [data, setData] = useState('')

    const navigate = useNavigate()

    const verifyQR = async (result) => {

        var data = {
            link: `${result}`,
            codigoUsuario: sessionStorage.getItem('ENT-CODUSER')
        }
        
        if(result !== '' && result !== undefined){

            try {
                console.log(data)
                const res = await fetchWithToken(`entereza/link_qr`, "POST", data)
                const { codeError, msgError } = await res.json()

                console.log(codeError)
                
                if (codeError === "COD200"){
                    sessionStorage.setItem('codeErrorQR', '200')
                    navigate('/ahorros')
                }else{
                    sessionStorage.setItem('codeErrorQR', '500')
                    navigate('/ahorros')
                }

            } catch (err) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Algo salió mal, por favor inténtelo más tarde',
                })
            }
        }
    }


    return (
        <>
            <div style={{ marginTop: 120, border: 10 }}>
                <QrReader
                    onResult={(result, error) => {
                        if (!!result) {
                            setData(result?.text);
                            verifyQR(result?.text)
                        }

                        if (!!error) {
                            console.info(error);
                        }
                    }}
                    scanDelay={500}
                    constraints={{
                        facingMode: 'environment'
                    }}
                />
                <h3 className="text-center fs-1">Escanea el codigo QR de tu factura:</h3>
                <p>{data}</p>
            </div>
        </>
    );
};

export default QRscanner
