import React from 'react'
import ruleta from '../../../assets/img/wheel.png'
import './index.css';

function Ruleta (props) {

    const { puntos } = props.info_user;

    return(

        <div className="text-center">
            <h2 className="ruleta-puntos mb-4">Puntos: {puntos}</h2>
            <h2>↓</h2>
            <div>
                <img
                    id="img-ruleta"
                    src={ruleta}
                    style={{ transform: 'rotate(' + props.data_ruleta + 'deg)', WebkitTransform: 'rotate(' + props.data_ruleta + 'deg)' }}
                    alt="Ruleta Entereza"
                    onTransitionEnd={props.showRuletaResult}
                    className="img-responsive img-ruleta"
                    ref={props.ruleta}
                    width="40%"
                />
            </div>
                <button 
                    id="btnAnimar" 
                    disabled={props.animatedRuleta} 
                    onClick={props.animarEvent} 
                    className="btn botonGirarRuleta btn-lg text-center mt-5"
                >GIRA LA RULETA</button>
        </div>
    )

}

export default Ruleta