import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export const EmpresasAfiliadasCard = ({
    img,
    name,
    codigoEmpresa,
    links
}) => {

    const [view, setView] = useState(false)

    const handleClick = () => setView(!view)

  return (
    <>
        <div
            className='business-item position-relative col-3 col-sm-3 col-md-3 py-1 col-lg-2 d-flex flex-column justify-content-center align-items-center m-2 my-3 px-1 cursor-pointer'
            style={{
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                borderRadius: '13px',
                width: 150
            }}
        >
            <div
                className='col-12 px-0 businness-item__bg'
                onClick={ handleClick }
                style={{
                    borderRadius: '13px'
                }}
            >
                <img 
                    //image 
                    src={ img? img : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR52ox5Lid5dIMde3wQaIQf1E_vMcV2HOy8-6eYDTRex_0JmHZz5gnny04rTVayyuJrHto&usqp=CAU' }
                    className='businness-item__bg-img img-fluid p-0 pb-0'
                    alt={`Empresa Afiliada - ${name} | Entereza`}
                    style={{
                        width: '100%',
                        objectFit: 'cover',
                    }}
                />
                <div
                    className='col-12 text-center py-1'
                    style={{
                        fontWeight: 600,
                        fontSize: '0.85rem',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        display: 'block',
                        textOverflow: 'ellipsis'
                    }}
                >
                    {
                        name
                    }
                </div>
            </div>
            {
                view && (
                    <div
                        className='animate__animated animate__flipInY business-item__layer position-absolute w-100 h-100 bg-white d-flex flex-column justify-content-center align-items-center'
                        onClick={ handleClick }
                    >
                        <div
                            className='business-item__layer-title col-12 text-center px-1 mb-2'
                        >
                            {
                                name
                            }
                        </div>
                        <div
                            className='col-12 d-flex flex-row justify-content-center align-items-center mt-1'
                        >
                            {
                                links
                                    && (
                                        <>
                                            {
                                                links?.fb 
                                                    && (
                                                        <a
                                                            href={ links.fb  }
                                                            target='_blank'
                                                            className='text-decoration-none business-item__layer-a'
                                                        >
                                                            <div
                                                                className='mx-1 p-1 business-item__layer-social'
                                                            >
                                                                <FacebookIcon
                                                                    fontSize='small'

                                                                />
                                                            </div>
                                                        </a>
                                                    )
                                            }
                                            {
                                                links?.wpp 
                                                    && (
                                                        <a
                                                            href={ links.wpp }
                                                            target='_blank'
                                                            className='text-decoration-none business-item__layer-a'
                                                        >
                                                            <div
                                                                className='mx-1 p-1 business-item__layer-social'
                                                            >
                                                                <WhatsAppIcon
                                                                    fontSize='small'
                                                                />
                                                            </div>
                                                        </a>
                                                    )
                                            }
                                            
                                        </>
                                    )
                            }

                            
                        </div>
                        <Link
                            to={ `/empresas-afiliadas/${codigoEmpresa || 'ENT-f04d8e38'}` }
                        >
                            <div
                                className='col-10 text-center px-2 py-1 business-item__layer-anchor mt-1'
                            >
                                Ver más
                            </div>
                        </Link>
                    </div>
                )
            }
        </div>
    </>
  )
}
