//importaciones
import React, { useEffect, useState } from "react"
import "./acerca-empresas.css";

//importacion imagenes
import hands from '../../assets/img/manos-empresas.png'
import devices from '../../assets/img/devices-empresas.png'
import celtarjeta from '../../assets/img/tarjetacel-empresas.png'
import enterezaempresas from '../../assets/img/entereza-empresas.png'
import enterezaempresas2 from '../../assets/img/imagen2-empresas.png'


// Importar iconos Font Awesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faPlay } from "@fortawesome/free-solid-svg-icons";

//importacion libreria efectos
import Aos from 'aos';
import "aos/dist/aos.css";

//importacion libreria modales
import { Modal } from 'react-responsive-modal';
import "react-responsive-modal/styles.css";

//importacion videos
import VidEmp from '../../assets/video/giftEmpresas.mp4';

function AcercaEmpresas() {

    const [modal, setModal] = useState(false);

    //Funciones abrir cerrar modal
    function onOpenModal(){
        setModal(true)
    }

    function onCloseModal(){
        setModal(false)
    }

    useEffect(() => {
        Aos.init({ 
            duration: 1500,
            once: true 
        });
    }, []);

    return (
        <div className="acercaEmpresas">
            <div className="PrimerEmpresas mt-5">
                <h2 className="titulo-empresas py-4" data-aos="fade-down">Automatizamos tu empresa</h2>

                <div className="row align-items-center" data-aos="fade-right" id="box-empresas">
                    <div className="col-md-6" id="box-empresas-texto">
                        <div className="text-boxempresas">
                            <h2 className="tituloBox-empresas text-center mb-4">Controla <distinto>tu empresa<br/> en todo momento</distinto></h2>
                            <div className="BtnProximamente">
                            <p className="textoBox-empresas lead">
                                Controla tu inventario, tus ventas, tu equipo de trabajo, 
                                tus finanzas desde tu dispositivo y concéntrate
                                en brindar la mejor experiencia a los clientes.
                            </p>
                            </div>
                            <div className="col-md-6 mb-4" id="box-empresas-responsive">
                                <img id="Responsiveempresas" src={enterezaempresas}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6" id="box-empresasImage" data-aos="fade-right">
                        <div className="BoxempresasImg">
                            <img id="imagenempresas" src={enterezaempresas}/>
                        </div>
                    </div>
                    <div className="BtnProximamente mb-5" data-aos="fade-down">
                        <a href="/registro/empresa"><button type="button" id="BtnProximamenteEmpresas">Regístrate aquí</button></a>
                    </div>
        
                </div>

                <div className="row align-items-center" id="BoxEnterezaEmpresas">
                    <div className="acol-md-6 border border-dark" id="BoxEnterezaEmpresas-person">
                        <div id="containerTextsLP" data-aos="fade-up" className="text-cnp-3">
                            <div id="Container-BoxEnterezaEmpresas-img">
                                <img id="BoxEnterezaEmpresas-img" src={hands}/>
                            </div>
                            <p className="text-center">
                                A través de Entereza podrás crear un
                                programa de lealtad con todos tus clientes,
                                llegando a la nueva generación de consumistas,
                                los jóvenes, nuestro futuro motor económico.
                            </p>
                        </div>
                    </div>  

                    <div className="acol-md-6 border border-dark" id="BoxEnterezaEmpresas-person">
                        <div id="containerTextsLP" data-aos="fade-up" className="text-cnp-3">
                            <div id="Container-BoxEnterezaEmpresas-img">
                                <img id="BoxEnterezaEmpresas-img" src={devices}/>
                            </div>
                            <p className="text-center">
                                Somos la primera plataforma gratuita que permite a los
                                emprendedores y comerciantes digitalizar su negocio,
                                administrarlo y tomar decisiones inteligentes.
                            </p>
                        </div>
                    </div>  

                    <div className="acol-md-6 border border-dark" id="BoxEnterezaEmpresas-person">
                        <div id="containerTextsLP" data-aos="fade-up" className="text-cnp-3">
                            <div id="Container-BoxEnterezaEmpresas-img">
                                <img id="BoxEnterezaEmpresas-img" src={celtarjeta}/>
                            </div>
                            <p class="text-center">
                                Entereza impulsa a los pequeños negocios a la inclusión
                                financiera con la oportunidad de acceder a créditos mucho
                                más ágiles y sin letra pequeña de la mano de nuestro banco
                                aliado.
                            </p>
                        </div>
                    </div>     
                </div> 


                <div className="row align-items-center" data-aos="fade-right" id="box-empresas">
                    <div className="col-md-6" id="box-empresasImage">
                        <div className="BoxempresasImg">
                            <img id="imagenempresas" src={enterezaempresas2}/>
                        </div>
                    </div>
                    <div className="col-md-6" data-aos="fade-right" id="box-empresas-texto">
                        <div className="text-boxempresas">
                            <h2 className="tituloBox-empresas text-center mb-4">Nuestro objetivo, <distinto>incrementar tus ventas</distinto></h2>
                            <div className="BtnProximamente">
                            <p className="textoBox-empresas lead " id="TextoEmpresas2">
                                Concéntrate en tu core y ofrece la mejor experiencia a tus clientes.<br/><br/>

                                A nivel general, ahorrar más, disminuye la producción, entonces:<br/>
                                ¿Por qué no ahorrar mientras gastas e incrementar las ventas entendiendo el comportamiento de nuestros clientes?<br/><br/>

                                Olvídate de dar descuentos abismales, si con uno pequeño puedes retener más a tu clientela.
                            </p>
                            </div>
                            <div className="col-md-6 mb-4" id="box-empresas-responsive" data-aos="fade-up">
                                <img id="Responsiveempresas" src={enterezaempresas2}/>
                            </div>
                        </div>
                    </div>
                    <div className="BtnProximamente mb-5">
                        <a href="/registro/empresa"><button type="button" id="BtnProximamenteEmpresas">Regístrate aquí</button></a>
                    </div>
                </div>

                <div className='text-center position-relative pb-5 mb-5'>
                    <video autoPlay loop width="70%"  className='vidAutoEmp d-flex mx-auto'>
                        <source src={VidEmp} type="video/mp4"/>
                    </video>
                    <button 
                        className='btn btn-dark btn-lg rounded-circle position-absolute top-50 start-50' 
                        id="ButtonPlay"
                        onClick={onOpenModal}
                    >
                        <FontAwesomeIcon icon={faPlay}/>
                    </button>
                </div>
            </div>

            <Modal open={modal} onClose={onCloseModal} showCloseIcon={false} center>
                <div className='text-center'>
                <iframe 
                    id="ContenedorVideoYoutube"
                    src="https://www.youtube.com/embed/EbQowjOTZD8" 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                />
                </div>
            </Modal>

        </div>    
    );
};

export default AcercaEmpresas;