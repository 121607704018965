import './navbar-presignup.css';
import { Link } from 'react-router-dom';

//importacion de imagenes
import logo from '../../assets/img/logoOriginal.png';

function PreNavbar(){
    return(
        <nav className="navbar navbar-light navbar-postsignup">
            <div className="container-fluid">
                <Link className="navbar-brand " to="/">
                    <img src={logo} width="46" className='pt-2'/>
                </Link>
                <Link id='EnterezaNavBar' className="my-auto h1 fs-2 nav-div_a pt-2" to="/">ENTEREZA</Link>
            </div>
        </nav>
    );
}

export default PreNavbar;