import React, { useState, useEffect } from 'react'
import './InitialModals.css'

//import modal dependencies
import { Modal } from 'react-responsive-modal'

//import MUI
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const InitialModals = () => {

    //estados para abrir y cerrar modales
    const [open1, setOpen1] = useState(false)
    const [open2, setOpen2] = useState(false)

    //estados para guardar variables
    const [carnet, setCarnet] = useState('')

    //error
    const [error, setError] = useState(false);
    const [errorCI, setErrorCI] = useState(false)

    //loadings
    const [loading, setLoading] = useState(false)

    //ejecutar firstLoggin function automaticamente
    useEffect(() => {
        firstLogging(sessionStorage.getItem('UserKeyID'), sessionStorage.getItem('ENT-UserRol'))
    }, [])

    //verificar si es o no su primera vez loggeado
    const firstLogging = (carnet, rol) => {
        if (carnet !== 'true') {
            if(rol === '0'){
                setOpen1(true)
            }else{
                setOpen1(false)
                setOpen2(true)
            }
        } else {
            setOpen1(false)
            setOpen2(false)
        }
    }

    //loadin icon (circle)
    const loadingIcon = () => {
        return (
            <Stack sx={{ color: 'grey.500' }}>
                <CircularProgress color="inherit" className='mx-5' />
            </Stack>
        )
    }

    //enviar respuesta primer modal
    const handleSubmitFirst = async (llegada) => {

        setLoading(true)

        var data = {
            tiempoRetiro: 0,
            medioLlegada: llegada,
            email: sessionStorage.getItem("userEmail")
        }

        await fetch(`${process.env.REACT_APP_API_URL}/entereza/modales`, {
            method: 'POST',
            headers: {
                'Authorization': "Bearer " + sessionStorage.getItem("currentUser"),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((result) => {
            result.json().then((resp) => {

                if(resp.codeError === "COD200"){
                    setError(false)
                    setOpen1(false)
                    setOpen2(true)
                }else{
                    setError(true)
                }

                setLoading(false)

            })
        })
    }

    //enviar respuesta segundo modal
    const handleSubmitSecond = async (event) => {
        event.preventDefault()

        console.log(carnet)
        if(carnet.length >= 6 && carnet.length <=9){
            setErrorCI(false)

            var data = {
                carnet: carnet,
                correo: sessionStorage.getItem("userEmail")
            }
            console.log(data)
    
            await fetch(`${process.env.REACT_APP_API_URL}/entereza/save_carnet`, {
                method: 'POST',
                headers: {
                    'Authorization': "Bearer " + sessionStorage.getItem("currentUser"),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then((result) => {
                result.json().then((resp) => {
                    
                    console.log(resp)
                    if(resp.codeError === "COD200"){
                        sessionStorage.setItem('UserKeyID', 'true')
                        setError(false)
                        setOpen2(false)
                    }else{
                        setError(true)
                    }
                })
            })

        }else{
            setErrorCI(true)
        }
    }

    //mostrar error de servidor
    const showError = () => {
        if (error === true) {
            return (
                <Stack sx={{ width: '80%' }} className="mx-auto mt-3" spacing={2}>
                    <Alert severity="error">¡Ups! Tuvimos un problema, cierra la pestaña y regresa en 15 minutos</Alert>
                </Stack>
            )
        }
    }

    //mostrar error de escritura carnet
    const showErrorCI = () => {
        if (errorCI === true) {
            return (
                <Stack sx={{ width: '80%' }} className="mx-auto mt-3" spacing={2}>
                    <Alert severity="error">El carnet de identidad que ingresaste NO es válido </Alert>
                </Stack>
            )
        }
    }

    return (
        <div className='InitialModals'>
            <Modal
                open={open1}
                showCloseIcon={false}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                blockScroll={true}
                center
            >
                    <div className="card border-0 h-100 py-4" id='containerCardModal'>
                        <div className="card-body d-flex justify-content-center align-items-center">
                            <div>
                                <h2 className="card-title mb-2 text-center fw-bolder">Cuéntanos cómo descubriste Entereza…</h2>
                                <h4 className="card-text mb-4 text-center lead">
                                    ¡Nos ayudarás un montón!
                                </h4>
                                <div className='text-center'>

                                    <Box sx={{ '& button': { m: 1 } }}>
                                        <div>
                                            <Button variant="text" size="large" onClick={()=> handleSubmitFirst('Me lo recomendo un amigo/a')} className='border' sx={{color: '#5457A5'}}>
                                                Me lo recomendo un amigo/a
                                            </Button>
                                            <Button variant="text" size="large" onClick={()=> handleSubmitFirst('Vi el letrero en un negocio')} className='border' sx={{color: '#5457A5'}}>
                                                Vi el letrero en un negocio
                                            </Button>
                                            <Button variant="text" size="large" onClick={()=> handleSubmitFirst('Facebook')} className='border' sx={{color: '#5457A5'}}>
                                                Facebook
                                            </Button>
                                            <Button variant="text" size="large" onClick={()=> handleSubmitFirst('Instagram')} className='border' sx={{color: '#5457A5'}}>
                                                Instagram
                                            </Button>
                                            <Button variant="text" size="large" onClick={()=> handleSubmitFirst('TikTok')} className='border' sx={{color: '#5457A5'}}>
                                                TikTok
                                            </Button>
                                            <Button variant="text" size="large" onClick={()=> handleSubmitFirst('Youtube')} className='border' sx={{color: '#5457A5'}}>
                                                Youtube
                                            </Button>
                                        </div>
                                    </Box>
                                    {showError()}
                                </div>
                            </div>
                        </div>
                    </div>
            </Modal>


            <Modal
                open={open2}
                showCloseIcon={false}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                blockScroll={true}
                center
            >
                    <div className="card border-0 h-100 py-4" id='containerCardModal'>
                        <div className="card-body d-flex justify-content-center align-items-center">
                            <div>
                                <h2 className="card-title text-center fw-bolder">Ingresa tu número de carnet de identidad:</h2>
                                <div className='text-center'>
                                    <VpnKeyIcon sx={{ fontSize: 150 }} />
                                </div>
                                <h5 id='TextModalsInitial' className="card-text mx-auto text-center" style={{width: "85%"}}>
                                    <b>¿Por qué me piden mi número de carnet?</b><br /><br />
                                    Porque para realizar compras con Entereza debes dictar un número de carnet, ¡esta será tu llave única!
                                </h5>
                                <div className='text-center py-3'>

                                    <Box
                                        sx={{
                                            '& > :not(style)': { m: 1, width: '25ch' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <TextField type="number" onChange={(e)=> {setCarnet(e.target.value)}} color='success' id="filled-basic" label="Número de carnet" variant="filled" required/>
                                    </Box>

                                </div>
                            </div>
                        </div>

                        {showError()}
                        {showErrorCI()}

                        <div className='text-end mx-4 mt-3'>
                            <Button variant="text" onClick={handleSubmitSecond} className='border-end border-bottom' sx={{fontSize: 20, color: '#5457A5'}}>{loading? loadingIcon() : 'Siguiente'}</Button>
                        </div>
                    </div>
            </Modal>
        </div>
    );
}

export default InitialModals;