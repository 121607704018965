import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router';

// private navbar 
import PrivateNavBar from '../components/private/navbar';

// private pages 
import PerfilUsuario from '../pages/private/perfilUsuario';
import Ruleta from '../pages/private/ruleta';
import WorkingOnAhorros from '../pages/private/workingOnAhorros';
import Comming_soon from '../pages/private/comming_soon';
import SectionSupport from '../pages/private/soporte';
import { NotFound } from '../pages/public/NotFound';

import QRscanner from '../components/private/cam/qr-scanner';

//firstModal
import InitialModals from '../modals/InitialModals'
import { EmpresasAfiliadas } from '../pages/private/EmpresasAfiliadas';
import { EmpresaPerfil } from '../pages/private/EmpresaPerfil';

export const PrivateRoutes = ({
  setLogged
}) => {

  const { pathname } = useLocation();

  const navigate = useNavigate()

  useEffect(() => {
    sessionStorage.setItem('ENT-PATHLANDING', pathname);
    document.documentElement.scrollTop = 0;
    
    if (pathname === '/') {
      navigate('/ahorros')
    }
    
    if(pathname !== '/empresas-afiliadas'){
      sessionStorage.removeItem('EntLocCod')
    }
  }, [pathname]);

  

  return (

    <div className="bodyLogoutPage">
      <header>
        <PrivateNavBar setLogged={setLogged} />
        <InitialModals />
      </header>
      <Routes>
        <Route path="/perfil" element={<PerfilUsuario />} />
        <Route path='/ahorros' element={<WorkingOnAhorros />} />
        <Route path='/empresas-afiliadas' element={<EmpresasAfiliadas />} />
        <Route path='/empresas-afiliadas/:empresa_id' element={<EmpresaPerfil />} />
        <Route path='*' element={<NotFound redirect="/ruleta" />} />
      </Routes>
    </div>
  );
};
