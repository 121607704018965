import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'

// mui icons 
import CallIcon from '@mui/icons-material/Call';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import FacebookIcon from '@mui/icons-material/Facebook';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import StorefrontIcon from '@mui/icons-material/Storefront';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import MusicNoteIcon from '@mui/icons-material/MusicNote';

// map components 
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup
} from 'react-leaflet'

import L from 'leaflet';

// css map 
import "leaflet/dist/leaflet.css";
import './empresaperfil.css'

// utils 
import { fetchWithoutToken } from '../../helpers/fetchWithoutToken';

// images 
import WithoutSucursales from '../../assets/img/without-location.svg'

export const EmpresaPerfil = () => {

  const icon = L.icon({
    iconUrl: require("../../assets/img/locationPointer.png"),
        iconRetinaUrl: require("../../assets/img/locationPointer.png"),
        iconSize: [35, 75],
        className: "Leaflet-venue-icon"
  });

  // state sucursaesls 
  const [sucursalesLocation, setSucursalesLocation] = useState(null)

  const [empresasData, setEmpresasData] = useState(null)

  const { empresa_id } = useParams()

  const [currentLocation, setCurrentLocation] = useState(null)

  const handleOnSelectLocation = (location) => {
    setCurrentLocation(location)
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/entereza/empresa_links?codigoEmpresa=${empresa_id}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then(res => res.json()).then((result) => {
      console.log(result)
      setEmpresasData(result)
    })

    // FETCH GET SUCUSALES
    fetchWithoutToken(`entereza/sucursales_list?codigoEmpresa=${empresa_id}`)
      .then(res => res.json())
      .then((res) => {
        setSucursalesLocation(res?.sucursales || null)
        setCurrentLocation(res?.sucursales[0] || null)
      })

  }, [])

  return (
    <>
      {
        !!empresasData && (

          <div
            className='row mx-2 d-flex flex-column align-items-center mt-4 mt-md-5'
          >
            <div className='col-12  my-2 my-md-3 mt-4 p-4 business-profile__squared mx-auto'
            >

              <div className='col-12 h1 fs-2 text-center text-color__primary' >
                {empresasData.empresa?.nombre || "No tiene nombre"}
              </div>

              <div className='mx-auto my-3'
                style={{
                  width: '13rem',
                  height: '13rem',
                  minWidth: '13rem',
                }}
              >
                <img 
                  src={empresasData.imgEmpresa? empresasData.imgEmpresa?.imgEmpresa : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR52ox5Lid5dIMde3wQaIQf1E_vMcV2HOy8-6eYDTRex_0JmHZz5gnny04rTVayyuJrHto&usqp=CAU'} 
                  className='w-100 h-100 rounded-3' 
                />
              </div>
              <div className='col-12 col-sm-11 col-md-10 mx-auto text-center fs-6'>
                {empresasData.empresa?.descripcion || "Esta empresa no tiene descripción"}
              </div>
              
              <div
                className='my-2 col-12 col-sm-11 col-md-10 mx-auto text-center'
              >
                <div>
                  <a 
                    title="Facebook"
                    href={empresasData.links?.fb || "#"} 
                    className='mx-2 mb-3 business-profile__a text-decoration-none' 
                    target="_blank"
                    style={{
                      pointerEvents: empresasData.links?.fb ? 'all' : 'none',
                    }}
                  >
                    <small 
                      className={`text-wrap py-1 text-color__info  px-0 fw-600 ${!empresasData.links?.fb ? 'text-muted' : ''}`}
                    >
                      <FacebookIcon className='fs-4 ' />
                    </small>
                  </a>
                  <a 
                    href={empresasData.links?.insta || "#"} 
                    className='mx-2 mb-3 business-profile__a text-decoration-none' 
                    target="_blank"
                    style={{
                      pointerEvents: empresasData.links?.insta ? 'all' : 'none',
                    }}
                  >
                    <small 
                      className={`text-wrap py-1  text-color__info px-0 fw-600 ${!empresasData.links?.insta ? 'text-muted' : ''}`}
                    >
                      <InstagramIcon className='fs-4 ' />
                    </small>
                  </a>
                  <a 
                    title='Youtube'
                    href={empresasData.links?.youtube || "#"} 
                    className='mx-2 mb-3 business-profile__a text-decoration-none' 
                    target="_blank"
                    style={{
                      pointerEvents: empresasData.links?.youtube ? 'all' : 'none',
                    }}
                  >
                    <small 
                      className={`text-wrap py-1 text-color__info px-0 fw-600 ${!empresasData.links?.youtube ? 'text-muted' : ''}`}
                    >
                      <YouTubeIcon className='fs-4 ' />
                    </small>
                  </a>
                  <a 
                    title='Telegram'
                    href={empresasData.links?.telegram || "#"} 
                    className='mx-2 mb-3 business-profile__a text-decoration-none' 
                    target="_blank"
                    style={{
                      pointerEvents: empresasData.links?.telegram ? 'all' : 'none',
                    }}
                  >
                    <small 
                      className={`text-wrap py-1 text-color__info  px-0 fw-600 ${!empresasData.links?.telegram ? 'text-muted' : ''}`}
                    >
                      <TelegramIcon className='fs-4 ' />
                    </small>
                  </a>
                  <a 
                    title='TikTok'
                    href={empresasData.links?.tiktok || "#"} 
                    className='mx-2 mb-3 business-profile__a text-decoration-none' 
                    target="_blank"
                    style={{
                      pointerEvents: empresasData.links?.tiktok ? 'all' : 'none',
                    }}
                  >
                    <small 
                      className={`text-wrap py-1  text-color__info px-0 fw-600 ${!empresasData.links?.tiktok ? 'text-muted' : ''}`}
                    >
                      <MusicNoteIcon  className='fs-4 '/>
                    </small>
                  </a>
                  
                </div>

              </div>
              
              <div className='col-12 col-md-10 col-lg-8 mx-auto mt-3 row d-flex justify-content-evenly'
                Style="--bs-gutter-x: 0rem !important;"
              >
                <a 
                  href={empresasData.links?.wpp || "#"} 
                  className='mb-3 business-profile__a text-decoration-none' 
                  target="_blank"
                  style={{
                    pointerEvents: empresasData.links?.wpp ? 'all' : 'none',
                  }}
                >
                  <button 
                    type='button' 
                    className={`business-profile__squared-social py-1 col-3 col-md-3 text-center d-flex flex-column justify-content-center align-items-center ${!empresasData.links?.wpp ? 'border-secondary' : ''}`}
                    >
                    <small 
                      className={`text-wrap py-1 px-0 fw-600 ${!empresasData.links?.wpp ? 'text-muted' : ''}`}
                    >
                      Pedidos <br />
                      <WhatsAppIcon />
                    </small>
                  </button>
                </a>
              </div>
            </div>

            <div className='mx-auto col-12 my-md-3 my-2 mb-4 p-4 business-profile__squared '>
              <div className='col-12 text-start fw-700 text-color__primary'>
                <FmdGoodIcon /> Ubicaciones
              </div>
              <div class="dropdown">
                {
                  sucursalesLocation
                    && (
                      <>
                        <button
                          class="col-12 d-flex justify-content-between align-items-center form-control border-0 dropdown-toggle px-0"
                          type="button"
                          id="dropdownMenuLocationsProfileBusiness"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {
                            currentLocation
                              ? (
                                `${currentLocation.direccion} - ${currentLocation.ubicacionDescrita}`
                              ): (
                                "Selecciona una ubicación"
                              )
                          }
                        </button>
                        <ul class="dropdown-menu col-12 rounded-3" aria-labelledby="dropdownMenuLocationsProfileBusiness">
                          {
                            sucursalesLocation.map(location => (
                              <li
                                className='dropdown-item cursor-pointer'
                                key={location.noSucursal}
                                onClick={() => handleOnSelectLocation(location)}
                              >
                                {
                                  `${location.direccion} - ${location.ubicacionDescrita}`
                                }
                              </li>
                            ))
                          }
                        </ul>
                      </>
                    )
                }
                
              </div>
              <div
                className='col-12 map-container mt-2 overflow-hidden rounded-3'
              >
                {
                  sucursalesLocation && currentLocation
                    ? (
                        <MapContainer
                          center={[currentLocation.latitud||0, currentLocation.longitud || 0]}
                          key={`map-${currentLocation.latitud||0}-${currentLocation.longitud || 0}`}
                          zoom={14}
                          scrollWheelZoom={true}
                          zoomControl={false}
                          className="w-100 h-100"
                        >
                          <TileLayer
                            url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png"
                          />
                          <Marker
                            position={[currentLocation.latitud||0, currentLocation.longitud || 0]}
                            icon={icon}
                          >
                            <Popup
                              className='card rounded-3'
                              closeButton={false}
                              closeOnClick={true}
                              autoClose={true}
                              style={{
                                maxWidth: '125px !important',
                              }}
                              position={[currentLocation.latitud||0, currentLocation.longitud || 0]}
                              offset={[0, 45]}
                            >
                              <img
                                src={empresasData.imgEmpresa?.imgEmpresa || "https://imprentapamplona.com/modules/smartblog/images/no-single-default.jpg"} 
                                class="card-img-top"
                                alt="Entereza Fintech"
                              />
                              <div class="card-body p-2 d-flex flex-column">
                                <small
                                  class="text-color__primary fs-6"
                                  style={{
                                    fontWeight: 500
                                  }}
                                >
                                  {
                                    empresasData.empresa?.nombre || "No tiene nombre"
                                  }
                                </small>
                                <small class="card-text">
                                  {
                                    currentLocation.direccion || "No tiene dirección"
                                  }
                                </small>
                              </div>
                              <div class="card-footer overflow-hidden">
                                <a
                                  href={ `https://maps.google.com/?q=${currentLocation.latitud||0},${currentLocation.longitud || 0}` }
                                  class="text-decoration-none text-muted"
                                  target='_blank'
                                >
                                  <small class="text-muted">
                                    Abrir en Google Maps
                                  </small>
                                </a>
                              </div>
                            </Popup>
                          </Marker>
                        </MapContainer>
                    ): (
                      <div
                        className='w-100 h-100 d-flex flex-column align-items-center justify-content-center position-relative'
                      >
                        <img
                          src={ WithoutSucursales }
                          alt={`${empresasData.empresa?.nombre || 'Entereza'} - Sin sucursales`}
                          className='col-10 col-sm-8 img-fluid'
                          title={`${empresasData.empresa?.nombre || 'Entereza'} - Sin sucursales`}
                        />
                        <div
                          className='mt-3 col-7 text-center text-color__primary position-relative fw-bold fs-5'
                        >
                          Sucursales no disponibles
                        </div>
                      </div>
                    )
                }
              </div>
            </div>
            <div
              className='mx-auto col-12 my-md-3 my-2 col-md-10 text-start  text-center text-secondary'
            >
              <b>NOTA</b>: NO somos una empresa de Delivery, cualquier pedido o consulta directamente con la empresa.
            </div>
          </div>
        )
      }
    </>
  )
}
