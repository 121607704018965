import React, { Component } from 'react'
import Swal from 'sweetalert2';
import Ruleta from './Ruleta'
import './index.css';
import { Modal } from 'react-responsive-modal';
import "react-responsive-modal/styles.css";
import Countdown from './contador';

class WaitingGame extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			total_points: 0,
			data_ruleta: 0,
			animated_ruleta: false,
			open: false,
			openSecond: false,
			openEntereza: false,

			nombreCategoria: '',

			pregunta : "",
			codPregunta: "",

			respuestas: [],

			respSeleccionada: "",

			respuestaEntereza: "",

			contador: 10,
			playPause: false,

			// for info current user 
			info_user: {}
		}

		this.categorias_list = [];

		this.preguntaRespuestas = [];

		this.points_data = 0;
		this.rulets_data = 0;

		this.ruleta = React.createRef()

		this.animarEvent = this.animarEvent.bind(this)
		this.showRuletaResult = this.showRuletaResult.bind(this)
		this.onChangeRespuestaEntereza = this.onChangeRespuestaEntereza.bind(this);
		this.onCloseSecondModal = this.onCloseSecondModal.bind(this);

	}

	onChangeRespuestaEntereza(e) {
        this.setState({
          respuestaEntereza: e.target.value
        });
      }


	//obtener la lista de categorias para el juego "Ruleta"
	componentDidMount(){

		fetch(`${ process.env.REACT_APP_API_URL }/entereza/categoria_preguntas_list?email=`+ sessionStorage.getItem("userEmail"), {
			method: 'GET',
			headers: {
			  'Authorization': "Bearer "+sessionStorage.getItem("currentUser"),
			  'Accept': 'application/json',
			  'Content-Type': 'application/json'
			},
		}).then((result) => {
		  result.json().then((resp) => {
			this.setState({categorias_list: this.categorias_list})
			this.categorias_list = resp.categorias
	
		  })
		})

		// get info current user 
		fetch(`${ process.env.REACT_APP_API_URL }/entereza/usuarios_list?code=`+sessionStorage.getItem("userEmail"), {
			method: 'GET',
			headers: {
			'Authorization': "Bearer "+sessionStorage.getItem("currentUser"),
			'Accept': 'application/json',
			'Content-Type': 'application/json'
			},
		}).then((result) => {
		result.json().then((resp) => {
			this.setState(prev=>({
				...prev,
				info_user: {
					puntos: resp.lista_usuarios[0].puntos?.total_puntos || 0
				}
			}))
		})
		})

	}

	//Animar ruleta
	animarEvent = () => {
		
		var ruleta_temp = this.rulets_data;

		let grados_circulo 	=	360;
		let premio 	= 	grados_circulo / 8;
		
		var valor_aleatorio =	Math.floor(Math.random()*8);
		var ruleta_result 	= 	premio * valor_aleatorio;
		var valor_premio 	= 	(grados_circulo	* 4) + ruleta_result;
		
		this.rulets_data = 	valor_aleatorio;


		//Mostrar modales segun resultado ruleta
		setTimeout(()=> {

			//CIENCIA
			if(this.rulets_data === 0){
				//Seteo NombreCat para utilizarlo como titulo del modal
				this.setState({nombreCategoria: 'Ciencia'})


				//obtener codigo pregunta
				var data = {
					email_usuario : sessionStorage.getItem("userEmail"),
					codigo_categoria_pregunta : this.categorias_list[1].codigoCategoria
				}

				fetch(`${ process.env.REACT_APP_API_URL }/entereza/obtener_pregunta`  , {
					method: 'POST',
					headers: {
						'Authorization': "Bearer "+sessionStorage.getItem("currentUser"),
					  'Accept': 'application/json',
					  'Content-Type': 'application/json'
					},
					body: JSON.stringify(data),    
				
				  }).then((result) => {
					   result.json().then((resp) => {
						   this.preguntaRespuestas = resp.pregunta;
						   //obtener pregunta y respuestas
							if(resp.entereza.codeError === "COD200"){

								/*this.setState({pregunta: this.preguntaRespuestas.pregunta,
									codPregunta: this.preguntaRespuestas.codigo})

								this.setState({respuestas:[this.preguntaRespuestas.respuesta1,
									this.preguntaRespuestas.respuesta2,
									this.preguntaRespuestas.respuesta3,
									this.preguntaRespuestas.respuesta_correcta]
								})

								this.shuffleRespuestas();

								this.onOpenModal();*/

								Swal.fire({
									icon: 'warning',
									title: 'No hay preguntas :C',
									text: 'Por el momento no existen más preguntas para esta categoráa, prueba con otra.',
									toast: true
								  })
								
							}else{
								if(resp.entereza.codeError === "COD454"){
									Swal.fire({
										icon: 'warning',
										title: 'No hay preguntas :C',
										text: 'Por el momento no existen más preguntas para esta categoráa, prueba con otra.',
										toast: true
									  })
								}else{
								Swal.fire({
									icon: 'warning',
									title: 'Te quedaste sin intentos...',
									text: 'Tus intentos tardaran 24 horas en volverse a recargar, regresa mañana!!!',
									toast: true
								  })
								}
							}
						}
					)
				})
			}


			//TECNOLOGIA Y FINANCIERA
			if(this.rulets_data === 1){
				//Seteo NombreCat para utilizarlo como titulo del modal
				this.setState({nombreCategoria: 'Tecnologia y Financiera'})


				//obtener codigo pregunta
				var data = {
					email_usuario : sessionStorage.getItem("userEmail"),
					codigo_categoria_pregunta : this.categorias_list[6].codigoCategoria
				}

				fetch(`${ process.env.REACT_APP_API_URL }/entereza/obtener_pregunta`  , {
					method: 'POST',
					headers: {
						'Authorization': "Bearer "+ sessionStorage.getItem("currentUser"),
					  'Accept': 'application/json',
					  'Content-Type': 'application/json'
					},
					body: JSON.stringify(data),    
				
				  }).then((result) => {
					   result.json().then((resp) => {
						   this.preguntaRespuestas = resp.pregunta;

						   //obtener pregunta y respuestas
							if(resp.entereza.codeError === "COD200"){

								this.setState({pregunta: this.preguntaRespuestas.pregunta,
									codPregunta: this.preguntaRespuestas.codigo})

								this.setState({respuestas:[this.preguntaRespuestas.respuesta1,
									this.preguntaRespuestas.respuesta2,
									this.preguntaRespuestas.respuesta3,
									this.preguntaRespuestas.respuesta_correcta]
								})

								this.shuffleRespuestas();

								this.onOpenModal();
								
							}else{
								Swal.fire({
									icon: 'warning',
									title: 'Te quedaste sin intentos...',
									text: 'Tus intentos tardaran 24 horas en volverse a recargar, regresa mañana!!!',
									toast: true
								  })
							}
						}
					)
				})
			}


			//HISTORIA
			if(this.rulets_data === 2){

				//Seteo NombreCat para utilizarlo como titulo del modal
				this.setState({nombreCategoria: 'Historia'})


				//obtener codigo pregunta
				var data = {
					email_usuario : sessionStorage.getItem("userEmail"),
					codigo_categoria_pregunta : this.categorias_list[0].codigoCategoria
				}

				fetch(`${ process.env.REACT_APP_API_URL }/entereza/obtener_pregunta`  , {
					method: 'POST',
					headers: {
						'Authorization': "Bearer "+sessionStorage.getItem("currentUser"),
					  'Accept': 'application/json',
					  'Content-Type': 'application/json'
					},
					body: JSON.stringify(data),    
				
				  }).then((result) => {
					   result.json().then((resp) => {
						   this.preguntaRespuestas = resp.pregunta;

						   //obtener pregunta y respuestas
							if(resp.entereza.codeError === "COD200"){

								this.setState({pregunta: this.preguntaRespuestas.pregunta,
									codPregunta: this.preguntaRespuestas.codigo})

								this.setState({respuestas:[this.preguntaRespuestas.respuesta1,
									this.preguntaRespuestas.respuesta2,
									this.preguntaRespuestas.respuesta3,
									this.preguntaRespuestas.respuesta_correcta]
								})

								this.shuffleRespuestas();

								this.onOpenModal();
								
							}else{
								Swal.fire({
									icon: 'warning',
									title: 'Te quedaste sin intentos...',
									text: 'Tus intentos tardaran 24 horas en volverse a recargar, regresa mañana!!!',
									toast: true
								  })
							}
						}
					)
				})
			}
			

			//ENTEREZA
			if(this.rulets_data === 3){
				//Seteo NombreCat para utilizarlo como titulo del modal
				this.setState({nombreCategoria: 'Entereza'})


				//obtener codigo pregunta
				var data = {
					email_usuario : sessionStorage.getItem("userEmail"),
					codigo_categoria_pregunta : this.categorias_list[2].codigoCategoria
				}

				fetch(`${ process.env.REACT_APP_API_URL }/entereza/obtener_pregunta`  , {
					method: 'POST',
					headers: {
						'Authorization': "Bearer "+ sessionStorage.getItem("currentUser"),
					  'Accept': 'application/json',
					  'Content-Type': 'application/json'
					},
					body: JSON.stringify(data),    
				
				  }).then((result) => {
					   result.json().then((resp) => {
						   this.preguntaRespuestas = resp.pregunta;

						   //obtener pregunta y respuestas
							if(resp.entereza.codeError === "COD200"){

								this.setState({pregunta: this.preguntaRespuestas.pregunta,
									codPregunta: this.preguntaRespuestas.codigo})

								this.onOpenModalEntereza();
								
							}else{
								Swal.fire({
									icon: 'warning',
									title: 'Te quedaste sin intentos...',
									text: 'Tus intentos tardaran 24 horas en volverse a recargar, regresa mañana!!!',
									toast: true
								  })
							}
						}
					)
				})
			}


			//GEOGRAFIA
			if(this.rulets_data === 4){
				//Seteo NombreCat para utilizarlo como titulo del modal
				this.setState({nombreCategoria: 'Geografia'})


				//obtener codigo pregunta
				var data = {
					email_usuario : sessionStorage.getItem("userEmail"),
					codigo_categoria_pregunta : this.categorias_list[3].codigoCategoria
				}

				fetch(`${ process.env.REACT_APP_API_URL }/entereza/obtener_pregunta`  , {
					method: 'POST',
					headers: {
						'Authorization': "Bearer "+ sessionStorage.getItem("currentUser"),
					  'Accept': 'application/json',
					  'Content-Type': 'application/json'
					},
					body: JSON.stringify(data),    
				
				  }).then((result) => {
					   result.json().then((resp) => {
						   this.preguntaRespuestas = resp.pregunta;

						   //obtener pregunta y respuestas
							if(resp.entereza.codeError === "COD200"){

								this.setState({pregunta: this.preguntaRespuestas.pregunta,
									codPregunta: this.preguntaRespuestas.codigo})

								this.setState({respuestas:[this.preguntaRespuestas.respuesta1,
									this.preguntaRespuestas.respuesta2,
									this.preguntaRespuestas.respuesta3,
									this.preguntaRespuestas.respuesta_correcta]
								})

								this.shuffleRespuestas();

								this.onOpenModal();
								
							}else{
								Swal.fire({
									icon: 'warning',
									title: 'Te quedaste sin intentos...',
									text: 'Tus intentos tardaran 24 horas en volverse a recargar, regresa mañana!!!',
									toast: true
								  })
							}
						}
					)
				})
			}


			//ENTRETENIMIENTO
			if(this.rulets_data === 5){
				//Seteo NombreCat para utilizarlo como titulo del modal
				this.setState({nombreCategoria: 'Entretenimiento'})


				//obtener codigo pregunta
				var data = {
					email_usuario : sessionStorage.getItem("userEmail"),
					codigo_categoria_pregunta : this.categorias_list[5].codigoCategoria
				}

				fetch(`${ process.env.REACT_APP_API_URL }/entereza/obtener_pregunta`  , {
					method: 'POST',
					headers: {
						'Authorization': "Bearer "+ sessionStorage.getItem("currentUser"),
					  'Accept': 'application/json',
					  'Content-Type': 'application/json'
					},
					body: JSON.stringify(data),    
				
				  }).then((result) => {
					   result.json().then((resp) => {
						   this.preguntaRespuestas = resp.pregunta;

						   //obtener pregunta y respuestas
							if(resp.entereza.codeError === "COD200"){

								this.setState({pregunta: this.preguntaRespuestas.pregunta,
									codPregunta: this.preguntaRespuestas.codigo})

								this.setState({respuestas:[this.preguntaRespuestas.respuesta1,
									this.preguntaRespuestas.respuesta2,
									this.preguntaRespuestas.respuesta3,
									this.preguntaRespuestas.respuesta_correcta]
								})

								this.shuffleRespuestas();

								this.onOpenModal();
								
							}else{
								Swal.fire({
									icon: 'warning',
									title: 'Te quedaste sin intentos...',
									text: 'Tus intentos tardaran 24 horas en volverse a recargar, regresa mañana!!!',
									toast: true
								  })
							}
						}
					)
				})
			}


			//DEPORTE
			if(this.rulets_data === 6){
				//Seteo NombreCat para utilizarlo como titulo del modal
				this.setState({nombreCategoria: 'Deporte'})


				//obtener codigo pregunta
				var data = {
					email_usuario : sessionStorage.getItem("userEmail"),
					codigo_categoria_pregunta : this.categorias_list[4].codigoCategoria
				}

				fetch(`${ process.env.REACT_APP_API_URL }/entereza/obtener_pregunta`  , {
					method: 'POST',
					headers: {
						'Authorization': "Bearer "+ sessionStorage.getItem("currentUser"),
					  'Accept': 'application/json',
					  'Content-Type': 'application/json'
					},
					body: JSON.stringify(data),    
				
				  }).then((result) => {
					   result.json().then((resp) => {
						   this.preguntaRespuestas = resp.pregunta;

						   //obtener pregunta y respuestas
							if(resp.entereza.codeError === "COD200"){

								this.setState({pregunta: this.preguntaRespuestas.pregunta,
									codPregunta: this.preguntaRespuestas.codigo})

								this.setState({respuestas:[this.preguntaRespuestas.respuesta1,
									this.preguntaRespuestas.respuesta2,
									this.preguntaRespuestas.respuesta3,
									this.preguntaRespuestas.respuesta_correcta]
								})

								this.shuffleRespuestas();

								this.onOpenModal();
								
							}else{
								Swal.fire({
									icon: 'warning',
									title: 'Te quedaste sin intentos...',
									text: 'Tus intentos tardaran 24 horas en volverse a recargar, regresa mañana!!!',
									toast: true
								  })
							}
						}
					)
				})
			}


			//ENTEREZA
			if(this.rulets_data === 7){
				//Seteo NombreCat para utilizarlo como titulo del modal
				this.setState({nombreCategoria: 'Entereza'})


				//obtener codigo pregunta
				var data = {
					email_usuario : sessionStorage.getItem("userEmail"),
					codigo_categoria_pregunta : this.categorias_list[7].codigoCategoria
				}

				fetch(`${ process.env.REACT_APP_API_URL }/entereza/obtener_pregunta`  , {
					method: 'POST',
					headers: {
						'Authorization': "Bearer "+ sessionStorage.getItem("currentUser"),
					  'Accept': 'application/json',
					  'Content-Type': 'application/json'
					},
					body: JSON.stringify(data),    
				
				  }).then((result) => {
					   result.json().then((resp) => {
						   this.preguntaRespuestas = resp.pregunta;

						   //obtener pregunta y respuestas
							if(resp.entereza.codeError === "COD200"){

								this.setState({pregunta: this.preguntaRespuestas.pregunta,
									codPregunta: this.preguntaRespuestas.codigo})

								this.onOpenModalEntereza();
								
							}else{
								Swal.fire({
									icon: 'warning',
									title: 'Te quedaste sin intentos...',
									text: 'Tus intentos tardaran 24 horas en volverse a recargar, regresa mañana!!!',
									toast: true
								  })
							}
						}
					)
				})
			}
		},8000)


		// puntos ganados
		this.points_data = this.categorias_list[valor_aleatorio];
		
		this.setState({
			data_ruleta: ruleta_temp * premio,
			animated_ruleta: true,
		})
		
		setTimeout(() => {
			this.ruleta.current.classList.add('img-ruleta');
			this.setState({
				data_ruleta: valor_premio,
			})
		}, 200);
		
	}

	showRuletaResult(){
		
		this.ruleta.current.classList.remove("img-ruleta");

		if (this.points_data >= 0) {
			
			this.setState({
				total_points: this.state.total_points + this.points_data,
				animated_ruleta: false,
			})
		}
		else{
			this.setState({
				animated_ruleta: false,
			})
		}
	}


	//setear respuesta seleccionada
	resp0 = () => {
		this.setState({respSeleccionada: this.state.respuestas[0]});

		setTimeout(()=> {
			this.onCloseSecondModal();
		},1)
	}

	resp1 = () => {
		this.setState({respSeleccionada: this.state.respuestas[1]});

		setTimeout(()=> {
			this.onCloseSecondModal();
		},1)
	}

	resp2 = () => {
		this.setState({respSeleccionada: this.state.respuestas[2]});

		setTimeout(()=> {
			this.onCloseSecondModal();
		},1)
	}

	resp3 = () => {
		this.setState({respSeleccionada: this.state.respuestas[3]});

		setTimeout(()=> {
			this.onCloseSecondModal();
		},1)
	}

	respEntereza = () => {
		this.setState({respSeleccionada: this.state.respuestaEntereza})

		setTimeout(()=> {
			this.onCloseSecondModal();
		},1)
	}


	//Abrir Modal categoria que toco
	onOpenModal = () => {
		this.setState({ open: true });
	};

	//Cerrar Modal categoria que toco
	onCloseModal = () => {
		this.setState({ open: false });
	};

	//Abrir Modal para responder pregunta
	onOpenSecondModal = () => {
		this.setState({openSecond: true, open: false})

		//Temporizador para responder pregunta
		setTimeout(()=> {
			if(this.state.respSeleccionada === ""){
				this.onCloseSecondModal()
			}else{
				this.setState({openSecond: false})
			}
		},12000)
	}

	//Cerrar Modal y enviar respuesta usuario
	onCloseSecondModal = () => {

		this.setState({openSecond:false, openEntereza:false})

		var data = {
			user_email : sessionStorage.getItem("userEmail"),
			codigo_pregunta : this.state.codPregunta,
			respuesta: this.state.respSeleccionada
		}

		fetch(`${ process.env.REACT_APP_API_URL }/entereza/pregunta_operation`  , {
			method: 'POST',
			headers: {
				'Authorization': "Bearer "+ sessionStorage.getItem("currentUser"),
			  'Accept': 'application/json',
			  'Content-Type': 'application/json'
			},
			body: JSON.stringify(data),    
		
		  }).then((result) => {
			   result.json().then((resp) => {
				   	if(resp.codeError === "COD200"){
						this.setState(prev => ({ ...prev, info_user: {
								...this.state.info_user,
								puntos: this.state.info_user.puntos + 10
							} }))
						Swal.fire({
							icon: 'success',
							title: 'Respuesta Correcta...',
							text: 'Acabas de ganar 10 puntos, esto se reflejara en tus puntos acumulados.',
							toast: true
						}).then(function() {
							window.location = "/ruleta";
							});
				   	}else{
					    if(resp.codeError === "COD300"){
							Swal.fire({
								icon: 'success',
								title: 'Ya guardamos tu respuesta!!!',
								text: 'Gracias a tu respuesta podremos mejorar y adaptarnos a tu medida.',
								toast: true
							}).then(function() {
								window.location = "/ruleta";
							});
					    }else{
							Swal.fire({
								icon: 'error',
								title: 'Respuesta Incorrecta...',
								text: 'Todos nos equivocamos algunas vez, la siguiente pregunta lo haras genial!!!',
								toast: true
							}).then(function() {
								window.location = "/ruleta";
							});
						}
				   	}

				   this.setState({respuestas: []})
				   this.setState({respEntereza: ""})
				   
				}
			)
		})	
	}

	//abrir modal Entereza
	onOpenModalEntereza= () => {
		this.setState({openEntereza: true})
	}

	//cerrar modal Entereza
	onCloseModalEntereza = () => {
		this.setState({openEntereza: false})
	}


	//Mostrar respuestas aleatoriamente
	shuffleRespuestas(){
		for(var n = (this.state.respuestas).length; n ; n--){
			var j = Math.floor(Math.random() * n);
        	var k = this.state.respuestas[n - 1];
        	this.state.respuestas[n - 1] = this.state.respuestas[j];
        	this.state.respuestas[j] = k;
		}
	}
	
	render() {

		const bg = {
			overlay: {
			  background: "rgba(191, 200, 214, 0.7)"
			}
		  };

		  const price = this.state.price;
		  let comp,comp1,comp2,comp3;
	  
		if (this.state.respuestas[0] !== "") {
			comp = <a 
			type="button" 
			className="btn btn-outline-dark mb-3 w-100 botonesModalSeleccionRespuestasModal" 
			onClick={this.resp0}
			>{this.state.respuestas[0]}</a>
		}

		if (this.state.respuestas[1] !== "") {
			comp1 = <a 
			type="button" 
			className="btn btn-outline-dark mb-3 w-100 botonesModalSeleccionRespuestasModal" 
			onClick={this.resp1}
			>{this.state.respuestas[1]}</a>
		}

		if (this.state.respuestas[2] !== "") {
			comp2 = <a 
			type="button" 
			className="btn btn-outline-dark mb-3 w-100 botonesModalSeleccionRespuestasModal" 
			onClick={this.resp2}
			>{this.state.respuestas[2]}</a>
		}

		if (this.state.respuestas[3] !== "") {
			comp3 = <a 
			type="button" 
			className="btn btn-outline-dark mb-3 w-100 botonesModalSeleccionRespuestasModal" 
			onClick={this.resp3}
			>{this.state.respuestas[3]}</a>
		}
		  

		return (
			<div  id="main">
				<div className="container mb-4">
					{
						!!this.state.info_user 
							&& (
								<Ruleta 
									info_user={this.state.info_user}
									total_points={this.state.total_points}
									animatedRuleta={this.state.animated_ruleta} 
									data_ruleta={this.state.data_ruleta}
									showRuletaResult={this.showRuletaResult}
									animarEvent={this.animarEvent}
									ruleta={this.ruleta}
								/>
							)
					}
				</div>
			
			<Modal open={this.state.open} onClose={this.onCloseModal} showCloseIcon={false} closeOnOverlayClick={false} className="my-auto" center>
				<div className="cajita">
					<h2 className='text-center mb-3'>Te toco la categoria {this.state.nombreCategoria}</h2>
					<p>
						Preparate para responder la pregunta que te toco...
					</p>
					<a onClick={this.onOpenSecondModal} type='button' className='btn btn-outline-dark w-100 text-center botonesModalSeleccionRespuestasModal'>
						¡Estoy Listo!
					</a>
				</div>
			</Modal>

			<Modal 
				open={this.state.openSecond} 
				showCloseIcon={false} 
				closeOnEsc={false} 
				closeOnOverlayClick={false} 
				center
			>
				<div className="cajita text-center">
					<h2 className='text-center'>{this.state.pregunta}</h2><br/><br/>
					{comp}
					{comp1}
					{comp2}
					{comp3}<br/><br/>
					<div>
						<Countdown />
					</div>
				</div>
			</Modal>

			<Modal 
				open={this.state.openEntereza} 
				showCloseIcon={false} 
				closeOnEsc={false} 
				closeOnOverlayClick={false} 
				center
			>
				<div className="cajita">
					<h2 className='text-center'>{this.state.pregunta}</h2><br/><br/>
					<div className="form-floating mb-3">
                        <input 
                            type="text" 
                            className="form-control w-100" 
                            id="floatingInput respuesta" 
                            name="respuestaEntereza" 
                            placeholder="respuestaEntereza"
                            onChange={this.onChangeRespuestaEntereza}
                            value={this.state.respuestaEntereza} 
                            required
                        />
                        <label htmlFor="floatingInput" className="form-label">Escribe tu respuesta...</label>
                    </div>
					<a className='btn btn-outline-dark w-100 text-center botonesModalSeleccionRespuestasModal' type='button' onClick={this.respEntereza}>Enviar</a>
				</div>
			</Modal>

			</div>
		)

	}
}

export default WaitingGame;