export const muiModalPreviewImage = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    height: "100%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}

export const previewImage = (theme) => ({
    cropContainer: {
      position: 'relative',
      width: '100%',
      height: '80%',
      background: '#fff',
      top: '-3%',
      [theme.breakpoints.up('sm')]: {
        height: 400,
        maxWidth: 500,
        margin: '0 auto',
        top: '0'
      },
    },
    cropButton: {
      flexShrink: 0,
    //   marginLeft: 16,
    //   marginTop: 5,
    //   marginBottom: 5,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
          width: 'max-content',
      }

    },
    controls: {
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        // flexDirection: 'row',
        alignItems: 'center',
        width: '85%',
        maxWidth: 500,
      },
    },
    sliderContainer: {
      display: 'flex',
      flex: '1',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
          width: '90%',
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: '.7em',
        }
    },
    sliderLabel: {
      [theme.breakpoints.down('xs')]: {
        minWidth: 65,
      },
    },
    slider: {
      padding: '22px 0px',
      marginLeft: 32,
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'center',
        margin: '0 16px',
      },
    },
    buttons: {
        margin: 'auto',
        display: 'flex',
        flexDirection: 'row !important',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row !important',
        }
    }
  })

export const speedDialImage = (theme) => ({
    button: {
        right: '1rem !important',
        bottom: '1rem !important',
    }
})