import React from "react";
import "./footer.css";

//importacion de imagenes
import logo from '../../assets/img/LogoBoxUs.png';

//importacion de pdfs
import TerminosUso from '../../documents/terminosUso.pdf';
import PoliticasPrivacidad from '../../documents/politicasPrivacidad.pdf';

// Importar iconos Font Awesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

import InitialModals from "../../modals/InitialModals";

function Footer(){
    return(
        
        <div className="container-footer">
            <footer className="py-3">
                <div className="FooterItems-Arriba mb-3 border-bottom text-white ">
                    <div className="Logo-Name-Redes">
                        <Link to="/" className="footer-brand">
                            <img src={logo} className="Logo-Footer"/>
                            <div id="Entereza-Footer" className="mb-3">
                                <span className="text-white">ENTEREZA</span>
                            </div>
                        </Link>
                        <div id="comunicate-Footer">
                            <span className="text-light fw-lighter">Comunícate con nosotros</span>
                        </div>
                        <div className="mt-3" id="IconosFooter">
                            <a href="https://www.facebook.com/Entereza-111165347869984/" target="_blank"><FontAwesomeIcon icon={faFacebookF} id="iconRedes"/></a>
                            <a href="https://instagram.com/entereza_bol?utm_medium=copy_link" target="_blank"><FontAwesomeIcon icon={faInstagram} id="iconRedes"/></a>
                            <a href="https://wa.link/ynk64n" target="_blank"><FontAwesomeIcon icon={faWhatsapp} id="iconRedes"/></a>
                            <a href="https://www.linkedin.com/company/entereza-fintech/" target="_blank"><FontAwesomeIcon icon={faLinkedinIn} id="iconRedes"/></a>
                        </div>
                    </div>

                    <div className="SobreEntereza mb-1 text-light">                
                        <ul id="TitleFooter" className="">Sobre Entereza
                            <li className="fw-lighter" id="SubtitleFooter">
                                <Link to="/" className="text-light text-decoration-none">
                                    Inicio
                                </Link>
                            </li>
                            <li className="fw-lighter" id="SubtitleFooter">
                                <Link to="/preguntas" className="text-light text-decoration-none">
                                    Preguntas frecuentes
                                </Link>
                            </li>
                            <li className="fw-lighter" id="SubtitleFooter">
                                <Link to="/acerca-de/nosotros" className="text-light text-decoration-none">
                                Quiénes Somos
                                </Link>
                            </li>
                            <li className="fw-lighter" id="SubtitleFooter">
                                <Link to="/registro/empresa" className="text-light text-decoration-none">
                                    Trabaja con nosotros
                                </Link>
                            </li>            
                        </ul>
                    </div>
                </div>
                <div className="text-center">
                    <div className="text-light fw-lighter">
                        <a className="text-light text-decoration-none fw-lighter" href={TerminosUso} target="_blank">Términos y Condiciones </a> | <a className="text-light text-decoration-none fw-lighter" href={PoliticasPrivacidad} target="_blank">Políticas y Privacidad</a>    
                    </div>
                    <div className="mb-3 mt-2 me-0 mb-md-0 text-decoration-none lh-1">
                        <span className="text-light">Entereza S.R.L. - Todos los derechos reservados</span>
                    </div>                    
                </div>

                <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
                    <li className="ms-3"><a className="text-light" href="#"><svg width="24" height="24"><use href="#twitter"/></svg></a></li>
                    <li className="ms-3"><a className="text-light" href="#"><svg width="24" height="24"><use href="#instagram"/></svg></a></li>
                    <li className="ms-3"><a className="text-light" href="#"><svg width="24" height="24"><use href="#facebook"/></svg></a></li>
                </ul>
            </footer>
        </div>
    );
}

export default Footer;